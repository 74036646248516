/*eslint-disable*/
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import Tooltip from 'react-tooltip-lite';
import styled, { StyledComponent } from 'styled-components/macro';

import { AssistantT } from '@/Assistant/Construction/kind';
import { getAssistants } from '@/Assistant/Mechanisms/action';
import { onPremListResponseT } from '@/Flows/onPremTypes';
import { getUsersAPI } from '@/ManageUsers/op.manageUsers';
import { User } from '@/ManageUsers/types.user';
import {
  DialogIcon,
  InfoIcon,
  PriceEnterpriseIcon,
  PriceFreeIcon,
  PriceProIcon,
  PriceStandardIcon,
  SubPanelIcons,
  UserIconAnalytics,
} from '@/common/Icons/Icons';
import { Loader } from '@/common/components/Loader';
import { Modal, UpgradeModal } from '@/common/components/Modal';
import {
  FetchReducerT,
  useFetchFromApi,
} from '@/common/hooks/usefetch-from-api';
import { Button as ConfirmPopupButton } from '@/common/styled/Dialog.BotDetails.Dumb';
import { ButtonWrapper as ConfirmButtonWrapper } from '@/common/styled/Dialogue.Dumb';
import { Box, BoxWrapper } from '@/common/styled/Subscription.Xtra.Dumb';

import {
  AutomationApi,
  AutomationOnPremApi,
  agenHandoffUrl,
  automationAPIURLWithWorkspace,
  postJson,
  stripeGetApi,
  stripePost,
  wrapperGetJson,
  logEvent,
} from '@/common/utils/api-utils';
import { getSubscriptionDisplayDetails } from './CurrentPlan';
import {
  AmountContent,
  BodyWrapperV2,
  Button,
  ButtonWrapper,
  ContainerV2,
  ContentWrapper,
  HeaderContent,
  HoverUserIcon,
  ImgWrapper,
  MessageContainer,
  MessageHeader,
  MessageLeft,
  MessageRight,
  MessageWrapper,
  Paragraph,
  PlansHeaderV2,
  PriceEnterpriseWrapper,
  PricingCard,
  PricingContainer,
  PricingHeader,
  PricingHeaderLeft,
  PricingHeaderRight,
  PricingSection,
  SubscriptionDetails,
  SubscriptionPlanChange,
  UpgradeHeader,
  UpgradePlanLeft,
  UpgradePlanRight,
  UpgradePlanWrapper,
  WrapperV2,
} from './PlansAutomateStyles';
import { makeUiDomainFromResponse } from './Subscription';
import { PlanResponse } from './SubsriptionResponse';
import {
  ActionT,
  AdditionalPlans,
  DefaultPlan,
  EnterprisePlan,
  ErrorPage,
  PageStateT,
  PlanInfo,
  ResourceLimitMeta,
  PlanMetaForUI,
  PlanMetaForUIV2,
  PlansV2,
  SubscribedPlan,
  PlanResourcesLimit,
} from './kind-automate';
import { getStripe } from './util-stripe';

var qs = require('qs');

// import { type } from 'os'
// import { T } from 'ramda'
// import { CurrentPlan } from './BillingOverview-v2'
import {
  AllResourceTypes,
  InfoMeta,
  makeEnterprisePlanUIStructure,
} from './kind';
import { getProductId } from '@/common/utils/utils';

// type UnwrapArray<T> = T extends Array<infer U> ? U : T

export const YearlyPlans = [
  {
    plans: 'Basic',
    referenceName: 'BASIC_YEAR',
    type: 'Yearly',
    priority: 1,
    image: <PriceFreeIcon />,
    dollar: '$',
    price: '2000',
    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent: 'To built purposeful conversations',
      },
      {
        category: 'Workflow Builder',
        hoverContent: 'To built automations to resolve an issue',
      },
      {
        category: 'VA on Multi chat channel',
        hoverContent:
          'Deploy the chatbot on a chat channel of your choice like Slack and Microsoft Teams',
      },

      {
        bolder: '2',
        category: 'Admins',
        hoverContent: 'No of admins who can work on the application',
      },

      {
        bolder: '2000',
        category: ' Automation executions',
        hoverContent:
          'The total number of automations that can be executed over one month / 30 day period by the users.',
      },

      {
        bolder: '5000',
        category: ' Messages',
        hoverContent:
          'The total number of messages sent to the chatbot over a one month / 30 day period by the users.',
      },

      {
        bolder: '5',
        category: ' Active automations',
        hoverContent:
          ' At a time, the number of active automations available for use in the Chatbot Builder. Admin will be able to disable existing and enable new automations as per choice. ',
      },
      {
        bolder: '48 Hours',
        category: '  Response time ',
        hoverContent: 'Response time to answer/resolve the client queires.',
      },
      {
        bolder: 'Add-Ons',
        category: ' for 0.1 $',
        hoverContent:
          'Additional cost for each automation execution and message.',
      },
    ],
  },
  {
    plans: 'Standard',
    priority: 2,
    referenceName: 'STANDARD_YEAR',
    type: 'Yearly',
    image: <PriceStandardIcon />,
    dollar: '$',
    price: '4000',

    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent: 'To built purposeful conversations',
      },
      {
        category: 'Workflow Builder',
        hoverContent: 'To built automations to resolve an issue',
      },
      {
        category: 'VA on Multi chat channel',
        hoverContent:
          'Deploy the chatbot on a chat channel of your choice like Slack and Microsoft Teams',
      },

      {
        bolder: '3',
        category: ' Admins',
        hoverContent: 'No of admins who can work on the application',
      },

      {
        bolder: '5000',
        category: ' Automation executions',
        hoverContent:
          'The total number of automations that can be executed over one month / 30 day period by the users.',
      },

      {
        bolder: '10000',

        category: ' Messages',
        hoverContent:
          'The total number of messages sent to the chatbot over a one month / 30 day period by the users.',
      },

      {
        bolder: '10',
        category: ' Active automations',
        hoverContent:
          ' At a time, the number of active automations available for use in the Chatbot Builder. Admin will be able to disable existing and enable new automations as per choice. ',
      },
      {
        bolder: '24 Hours',
        category: '  Response time ',
        hoverContent: 'Response time to answer/resolve the client queires.',
      },
      {
        bolder: 'Add-Ons',
        category: ' for 0.1 $',
        hoverContent:
          'Additional cost for each automation execution and message.',
      },
    ],
  },
  {
    plans: 'professional',
    priority: 3,
    referenceName: 'PRO_YEAR',
    type: 'Yearly',
    image: <PriceProIcon />,
    dollar: '$',
    price: '6000',

    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent: 'To built purposeful conversations',
      },
      {
        category: 'Workflow Builder',
        hoverContent: 'To built automations to resolve an issue',
      },
      {
        category: 'VA on Multi chat channel',
        hoverContent:
          'Deploy the chatbot on a chat channel of your choice like Slack and Microsoft Teams',
      },

      {
        bolder: '3',
        category: ' Admins',
        hoverContent: 'No of admins who can work on the application',
      },

      {
        bolder: '5000',
        category: ' Automation executions',
        hoverContent:
          'The total number of automations that can be executed over one month / 30 day period by the users.',
      },

      {
        bolder: '10000',

        messagecategory: 'Messages',
        hoverContent:
          'The total number of messages sent to the chatbot over a one month / 30 day period by the users.',
      },

      {
        bolder: '10',
        category: ' Active automations',
        hoverContent:
          ' At a time, the number of active automations available for use in the Chatbot Builder. Admin will be able to disable existing and enable new automations as per choice. ',
      },
      {
        bolder: '24 Hours',
        category: '  Response time ',
        hoverContent: 'Response time to answer/resolve the client queires.',
      },
      {
        bolder: 'Add-Ons',
        category: ' for 0.1 $',
        hoverContent:
          'Additional cost for each automation execution and message.',
      },
    ],
  },
];
export const MonthlyPlans = [
  {
    plans: 'Free',
    referenceName: 'FREE',
    type: 'Monthly',
    priority: -1,
    image: <PriceFreeIcon />,
    dollar: '$',
    price: '0',
    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent: 'To built purposeful conversations',
      },
      {
        category: 'Workflow Builder',
        hoverContent: 'To built automations to resolve an issue',
      },
      {
        category: 'VA on Multi chat channel',
        hoverContent:
          'Deploy the chatbot on a chat channel of your choice like Slack and Microsoft Teams',
      },

      {
        bolder: '2',
        category: 'Admins',
        hoverContent: 'No of admins who can work on the application',
      },

      {
        bolder: '2000',
        category: ' Automation executions',
        hoverContent:
          'The total number of automations that can be executed over one month / 30 day period by the users.',
      },

      {
        bolder: '5000',
        messagecategory: 'Messages',
        hoverContent:
          'The total number of messages sent to the chatbot over a one month / 30 day period by the users.',
      },

      {
        bolder: '5',
        category: ' Active automations',
        hoverContent:
          ' At a time, the number of active automations available for use in the Chatbot Builder. Admin will be able to disable existing and enable new automations as per choice. ',
      },
      {
        bolder: '48 Hours',
        category: '  Response time ',
        hoverContent: 'Response time to answer/resolve the client queires.',
      },
      {
        bolder: 'Add-Ons',
        category: ' for 0.1 $',
        hoverContent:
          'Additional cost for each automation execution and message.',
      },
    ],
  },
  {
    plans: 'Basic',
    referenceName: 'BASIC',
    type: 'Monthly',
    priority: 1,
    image: <PriceFreeIcon />,
    dollar: '$',
    price: '199',
    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent: 'To built purposeful conversations',
      },
      {
        category: 'Workflow Builder',
        hoverContent: 'To built automations to resolve an issue',
      },
      {
        category: 'VA on Multi chat channel',
        hoverContent:
          'Deploy the chatbot on a chat channel of your choice like Slack and Microsoft Teams',
      },

      {
        bolder: '2',
        category: 'Admins',
        hoverContent: 'No of admins who can work on the application',
      },

      {
        bolder: '2000',
        category: ' Automation executions',
        hoverContent:
          'The total number of automations that can be executed over one month / 30 day period by the users.',
      },

      {
        bolder: '5000',
        messagecategory: 'Messages',
        hoverContent:
          'The total number of messages sent to the chatbot over a one month / 30 day period by the users.',
      },

      {
        bolder: '5',
        category: ' Active automations',
        hoverContent:
          ' At a time, the number of active automations available for use in the Chatbot Builder. Admin will be able to disable existing and enable new automations as per choice. ',
      },
      {
        bolder: '48 Hours',
        category: '  Response time ',
        hoverContent: 'Response time to answer/resolve the client queires.',
      },
      {
        bolder: 'Add-Ons',
        category: ' for 0.1 $',
        hoverContent:
          'Additional cost for each automation execution and message.',
      },
    ],
  },
  {
    plans: 'Standard',
    priority: 2,
    referenceName: 'STANDARD',
    type: 'Monthly',
    image: <PriceStandardIcon />,
    dollar: '$',
    price: '399',

    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent: 'To built purposeful conversations',
      },
      {
        category: 'Workflow Builder',
        hoverContent: 'To built automations to resolve an issue',
      },
      {
        category: 'VA on Multi chat channel',
        hoverContent:
          'Deploy the chatbot on a chat channel of your choice like Slack and Microsoft Teams',
      },

      {
        bolder: '3',
        category: ' Admins',
        hoverContent: 'No of admins who can work on the application',
      },

      {
        bolder: '5000',
        category: ' Automation executions',
        hoverContent:
          'The total number of automations that can be executed over one month / 30 day period by the users.',
      },

      {
        bolder: '10000',

        messagecategory: 'Messages',
        hoverContent:
          'The total number of messages sent to the chatbot over a one month / 30 day period by the users.',
      },

      {
        bolder: '10',
        category: ' Active automations',
        hoverContent:
          ' At a time, the number of active automations available for use in the Chatbot Builder. Admin will be able to disable existing and enable new automations as per choice. ',
      },
      {
        bolder: '24 Hours',
        category: '  Response time ',
        hoverContent: 'Response time to answer/resolve the client queires.',
      },
      {
        bolder: 'Add-Ons',
        category: ' for 0.1 $',
        hoverContent:
          'Additional cost for each automation execution and message.',
      },
    ],
  },
  {
    plans: 'Professional',
    priority: 3,
    referenceName: 'PRO',
    type: 'Monthly',
    image: <PriceProIcon />,
    dollar: '$',
    price: '699',

    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent: 'To built purposeful conversations',
      },
      {
        category: 'Workflow Builder',
        hoverContent: 'To built automations to resolve an issue',
      },
      {
        category: 'VA on Multi chat channel',
        hoverContent:
          'Deploy the chatbot on a chat channel of your choice like Slack and Microsoft Teams',
      },

      {
        bolder: '3',
        category: ' Admins',
        hoverContent: 'No of admins who can work on the application',
      },

      {
        bolder: '5000',
        category: ' Automation executions',
        hoverContent:
          'The total number of automations that can be executed over one month / 30 day period by the users.',
      },

      {
        bolder: '10000',

        messagecategory: 'Messages',
        hoverContent:
          'The total number of messages sent to the chatbot  over a one month / 30 day period by the users.',
      },

      {
        bolder: '10',
        category: ' Active automations',
        hoverContent:
          ' At a time, the number of active automations available for use in the Chatbot Builder. Admin will be able to disable existing and enable new automations as per choice. ',
      },
      {
        bolder: '24 Hours',
        category: '  Response time ',
        hoverContent: 'Response time to answer/resolve the client queires.',
      },
      {
        bolder: 'Add-Ons',
        category: ' for 0.1 $',
        hoverContent:
          'Additional cost for each automation execution and message.',
      },
    ],
  },
];

const HeaderPrice = styled.h2`
  text-align: center;
  width: 100%;
  float: left;
  font-family: 'Gordita-Medium';
  cursor: default;
  margin-right: 20px;
  color: #2e5ce6;
  font-size: 26px;
  margin-bottom: 10px;
  margin-top: 5px;
`;

const Image = styled.div`
  width: 100%;
  float: left;
`;
const ContentDiv = styled.div`
  width: 100%;
  float: left;
  text-align: left;
`;
const ImageContainer = styled.div`
  fill: #2e5ce6;
  width: 84px;
  height: 84px;
  flex-direction: column;
  justify-content: center;
  margin: 15px auto;
  display: flex;
`;
const PlanDetails = styled.div`
  width: 100%;
  float: left;
  overflow: scroll;
  height: 250px;
`;
const ContentDetials = styled.div`
  width: 100%;
  float: left;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const CircleSpan = styled.span`
  width: 6px;
  height: 6px;
  float: left;
  border-radius: 50%;
  border: 1px solid #2e5ce6;
  margin-right: 5px;
`;
const ContetntSpan = styled.div`
  max-width: 90%;
  float: left;
  margin-right: 5px;
  font-size: 14px;
  font-family: 'Gordita-Regular';
  color: #494949;
`;

const ContetntInformation = styled.div`
  width: 12px;
  height: 12px;
  float: left;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;
const HeaderLeft = styled.h4`
  text-align: center;
  width: 100%;
  float: left;
  font-family: 'Gordita-Medium';
  cursor: default;
  font-size: 18px;
  color: #657993;
  margin-bottom: 0px;
`;

const CreditMoney: StyledComponent<'div', any, any, never> = styled.div`
  width: 100%;
  float: left;
  text-align: ${(props: any) => props.CreditsAlign || ''};
  margin: ${(props: any) => props.CreditsMargin || ''};
  color: #494949;
`;

const AnchorTagContent: StyledComponent<'span', any, any, never> = styled.span`
    width: auto;
    font-size: 15px;
    color: #2e5ce6;
    margin-bottom: 0px;
    font-family: 'Gordita-Regular';
    cursor: pointer;
    margin ${(props: any) =>
      props.alignText ? '10px 0 0 0' : '10px auto 0 auto'};
    ;

    :hover {
        text-decoration: underline;
    }
`;
const PlanContent: StyledComponent<'span', any, any, never> = styled.span`
    width: auto;
    font-size: 15px;
    margin-bottom: 0px;
    font-family: 'Gordita-Regular';
    margin ${(props: any) =>
      props.alignText ? '10px 0 0 0' : '10px auto 0 auto'};

`;

const Blue: StyledComponent<'span', any, any, never> = styled.span`
  color: #0078f9;
`;
export const BoxLeft: StyledComponent<'div', any, any, never> = styled.div`
  width: ${(props: any) => props.BoxWidth || ''};
  float: left;
  border-radius: ${(props: any) => (props.Radius ? '0px' : '18px')};
  box-shadow: ${(props: any) =>
    props.Shadow ? 'none' : 'rgba(0, 0, 0, 0.16) 0 3px 6px'};
  background: #fff;
  margin-bottom: ${(props: any) => props.BoxMarginBottom || ''};
  padding: 25px;
  cursor: default;
  color: #494949;
  margin-right: ${(props: any) => (props.MarginRight ? '0px' : '30px')};
  border-right: 1px solid #ccc;
  &:first-child {
    border-top-left-radius: ${(props: any) => (props.Radius ? '6px' : '18px')};
    border-bottom-left-radius: ${(props: any) =>
      props.Radius ? '6px' : '18px'};
  }

  &:last-child {
    border-top-right-radius: ${(props: any) => (props.Radius ? '6px' : '18px')};
    border-bottom-right-radius: ${(props: any) =>
      props.Radius ? '6px' : '18px'};
    border: none;
  }

  ${AnchorTagContent} {
    text-transform: uppercase;
    text-decoration: underline;
  }
  ${PlanContent} {
    text-transform: uppercase;
  }
`;
const WrapperScroll = styled.div`
  width: 100%;
  float: left;
  overflow-y: scroll;
  height: calc(100vh - 100px);
  ${BoxLeft} {
    padding-bottom: 40px;
  }
  ${Box} {
    border: 1px solid #ccc;
    border-radius: 6px;
  }
`;
const PlansWrapper = styled.div`
  width: 100%;
  float: left;
  ${BoxWrapper} {
    padding-top: ${(props: any) => (props.paddingBox ? '0px' : '30px')};
    padding-bottom: ${(props: any) => (props.paddingBox ? '0px' : '50px')};
  }
  ${AnchorTagContent} {
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 20px;
    margin-top: 5px;
    padding: 0px;
  }
  ${PlanContent} {
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 5px;
    padding: 0px;
  }
`;
const ParagaphCenter = styled.p`
  width: 100%;
  float: left;
  text-align: center;
  margin-top: ${(props: any) => (props.marginTop ? '110px' : '0px')};
  font-size: 14px;
  font-family: 'Gordita-Regular';
  color: #494949;
`;

const PopupWrapper = styled.div`
  width: 100%;
  float: left;
`;

type UnwrapArray<T> = T extends Array<infer U> ? U : never;

export type PlanDisplay = UnwrapArray<typeof MonthlyPlans>;

type PlanType = 'Monthly' | 'Yearly' | 'Not_billed';

type PlanTypeQueryParam = 'monthly' | 'yearly';

const isPlanType = (variableToCheck: any): variableToCheck is PlanType =>
  variableToCheck == 'Monthly' ||
  variableToCheck == 'Yearly' ||
  variableToCheck == 'Not_billed';

const planTypeToPlanTypeQueryParam = (
  planType: PlanType
): PlanTypeQueryParam => {
  switch (planType) {
    case 'Monthly':
      return 'monthly';
    case 'Yearly':
      return 'yearly';
    case 'Not_billed':
      return 'monthly';
    default:
      return 'monthly';
  }
};

const stringToPlanTypeQueryParam = (planType: string): PlanTypeQueryParam => {
  // if (isPlanType(planType)) {
  //     return planTypeToPlanTypeQueryParam(planType)
  // }

  if (planType === 'monthly') {
    return 'monthly';
  }

  if (planType === 'yearly') {
    return 'yearly';
  }

  return 'monthly';
};

// const

const getPlanTypeAndPriority = (planReferenceName: string) => {
  const allPlans = [...MonthlyPlans, ...YearlyPlans];
  const filtered = allPlans.filter(
    (plan) => plan.referenceName === planReferenceName
  )[0];

  if (filtered) {
    return {
      priority: filtered.priority,
      type: filtered.type,
    };
  }

  if (planReferenceName.indexOf('ENTERPRISE') != -1 || planReferenceName.indexOf('CUSTOM'))
    return {
      priority: EnterprisePlan[0].priority,
      type: EnterprisePlan[0].type,
    };

  return {
    priority: EnterprisePlan[0].priority,
    type: EnterprisePlan[0].type,
  };
};

export enum SubscriptionAction {
  None = 'None',
  Subscribe = 'Subscribe',
  StartTrail = 'StartTrail',
  Upgrade = 'Upgrade',
  StartWithLowerPlan = 'StartWithLowerPlan',
  Loading = 'Loading',
  Error = 'Error',
}

type SubscribePopupDisplayData =
  | {
      current: number;
      allowedCount: number;
      values: ResourceMeta[];
      type: ResourceType;
      category: 'limit';
    }
  | {
      allowed: 'yes' | 'no';
      values: ResourceMeta[];
      type: ResourceType;
      category: 'permission';
    };

export type SubscriptionActions =
  | NoneT
  | SubscribeT
  | UpgradeT
  | StartWithLowerPlanT
  | LoadingT
  | ErrorT
  | StartTrialT;

export type NoneT = {
  mode: SubscriptionAction.None;
};
export type ErrorT = {
  mode: SubscriptionAction.Error;
  message: string;
};
export type LoadingT = {
  mode: SubscriptionAction.Loading;
};

export type StartTrialT = {
  mode: SubscriptionAction.StartTrail;
};

export type SubscribeT = {
  mode: SubscriptionAction.Subscribe;
  planReference: string;
  planMeta: PlanResponse & {
    cardDisplayData: PlanMetaForUI;
  };
};

export type UpgradeT = {
  mode: SubscriptionAction.Upgrade;
  fromPlan: PlanResponse & {
    cardDisplayData: PlanMetaForUI;
  };
  toPlan: PlanResponse & {
    cardDisplayData: PlanMetaForUI;
  };
};

export type LimitForResources = {
  resourceName: string;
  resourceDisplayName: string;
  resourceLimit: string;
};
type ResourceLimitForPlans = LimitForResources & {
  planName: string;
  planDisplayName: string;
};

type InfoForPlans = {
  infoName: string;
  value: string;
};
type ResourceType =
  | 'BOT'
  | 'ACTIVE_AUTOMATION'
  | 'ADMIN'
  | 'AGENT_BOT'
  | 'ONPREM_CONNECTOR'
  | 'APPROVAL';

type ResourceMeta = {
  name: string;
};

type ExistingResource = {
  type: ResourceType;
  values: ResourceMeta[];
};
export interface Agent {
  id: string;
  workspace: string;
  bot_id: string;
  client_id: string;
  client_secrect: string;
  is_active: boolean;
  is_authorized: boolean;
  tenant: string;
  teams_deploy?: TeamsDeploy;
  bot_data: BotData;
}

export interface TeamsDeploy {
  team_name: string;
  team_id: string;
  is_active: boolean;
  date_added: string;
  added_by: string;
  email_id: string;
}

export interface BotData {
  bot_name: string;
  bot_full_name: string;
  bot_short_desc: string;
  bot_full_desc: string;
  is_generated: boolean;
  is_active: boolean;
}

export interface AgentForSubscription {
  id: string;
  bot_id: string;
  is_active: boolean;
}
export type StartWithLowerPlanT = {
  mode: SubscriptionAction.StartWithLowerPlan;
  plan: PlanResponse & {
    cardDisplayData: PlanMetaForUI;
  };
  resources: SubscribePopupDisplayData[];
};

export const None: NoneT = {
  mode: SubscriptionAction.None,
};

export const PopupLoading: LoadingT = {
  mode: SubscriptionAction.Loading,
};
export interface Automation {
  id: string;
  name: string;
  description: string;
  deployed_status: string;
  active_status: boolean;
  modified_date: string;
  deleted: boolean;
  group: string;
}
function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const PricingPage = (props: {
  planBillingCycle: 'Monthly' | 'Yearly';
  plans: (PlanResponse & { cardDisplayData: PlanMetaForUIV2 })[];
  allPlans: (PlanResponse & { cardDisplayData: PlanMetaForUIV2 })[];
  subscription: SubscribedPlan;
  defaultPlan: DefaultPlan;
  gotoBilling: () => void;
  planInfo: PlanInfo;
  planLimits: PlanResourcesLimit;
}) => {
  const productId = getProductId()
  const { plans, defaultPlan, subscription, allPlans, planInfo, planLimits } =
    props;
  const subscribedPlanToDisplay = getSubscriptionDisplayDetails(
    subscription,
    defaultPlan
  );
  const currentPlanType = getPlanTypeAndPriority(
    subscribedPlanToDisplay.planReferenceName
  );

  const isEnterprise =
    subscribedPlanToDisplay.planReferenceName.startsWith('ENTERPRISE_') || subscribedPlanToDisplay.planReferenceName.startsWith('CUSTOM_');

  const currentSubscriptionPlanInfoData =
    planInfo[subscribedPlanToDisplay.planReferenceName]; // as InfoMeta[] | undefined;
  const currentSubscriptionPlanResourceData =
    planLimits[subscribedPlanToDisplay.planReferenceName]; //as ResourceLimitMeta[] | undefined;

  // console.log('This is the pricong component', subscribedPlanToDisplay);
  console.log('DOING :: plans', allPlans,subscription)

  const [action, setAction] = useState<SubscriptionActions>(None);

  const freePlan = allPlans.filter((plan) => plan.planName === 'FREE');
  const plansExceptFree = plans.filter((plan) => plan.planName !== 'FREE');

  // console.log('freeplan', freePlan, plansExceptFree)

  const loadSubscribeToLowerPlanData = async (
    plan: PlanResponse & {
      cardDisplayData: PlanMetaForUI;
    },
    referenceName: string
  ) => {
    const planReference = plan.planName;
    const resourceLimit: Promise<LimitForResources[]> = stripeGetApi(
      `${subscription.workspace}/plans/${planReference}/limits`
    ).then((response: any) => {
      if (response.type === 'success') {
        return response.output.map(
          (resource: ResourceLimitForPlans) =>
            ({
              resourceName: resource.resourceName,
              resourceDisplayName: resource.resourceDisplayName,
              resourceLimit: resource.resourceLimit,
            } as LimitForResources)
        );
      } else {
        return Promise.reject('Unable to load resource limits');
      }
    });
    const infoData: Promise<InfoForPlans[]> = stripeGetApi(
      `${subscription.workspace}/info/${planReference}/all`
    ).then((response: any) => {
      if (response.type === 'success') {
        return response.output.map(
          (info: { name: string; value: string }) =>
            ({
              infoName: info.name,
              value: info.value,
            } as InfoForPlans)
        );
      } else {
        return Promise.reject('Unable to load info values');
      }
    });
    const automations: Promise<ExistingResource[]> = wrapperGetJson(
      automationAPIURLWithWorkspace(
        subscription.workspace,
        AutomationApi.GetAll
      )
    ).then((resp: any) => {
      const response = resp.data;
      if (response.type === 'success') {
        const automations: Automation[] = response.output.automations;
        const automationsWithApproval: string[] =
          response.output.automationListWithApproval.map((x: any) => x.id);
        const resources: ResourceMeta[] = automations
          .filter((automation) => automation.active_status)
          .map((automation) => ({ name: automation.name }));

        // console.log('approval', automationsWithApproval);

        const appovalresources: ResourceMeta[] = automations
          .filter((automation) =>
            automationsWithApproval.includes(automation.id)
          )
          .map((automation) => ({ name: automation.name }));

        return [
          {
            type: 'ACTIVE_AUTOMATION',
            values: resources,
          } as ExistingResource,
          {
            type: 'APPROVAL',
            values: appovalresources,
          } as ExistingResource,
        ];
      } else {
        throw Promise.reject('Automation loading failed');
      }
    });

    const bots: Promise<ExistingResource[]> =productId == "AUTOMATE" ? Promise.resolve([]) : getAssistants(
      subscription.workspace
    ).then((assistants: AssistantT[]) => {
      const agentData = (assistantid: string) =>
        postJson(
          agenHandoffUrl(
            subscription.workspace,
            `/${assistantid}/agent/getAgent/`
          )
        )({
          bot_id: assistantid,
        })
          .then((resp) => resp.data)
          .then((agent) => {
            return { agent } as { agent: Agent };
          })
          .then((data) => {
            return {
              id: data.agent.id,
              bot_id: data.agent.bot_id,
              is_active: data.agent.teams_deploy
                ? data.agent.teams_deploy.is_active
                : false,
            } as AgentForSubscription;
          });
      const resources: ResourceMeta[] = assistants
        .filter((assistant) => assistant.published_at != null)
        .map((assistant) => ({ name: assistant.name }));

      const x = Promise.all(
        assistants.map((assistant) => agentData(assistant.id))
      ).then((agents) => {
        const botsWithAgents = agents
          .filter((agent) => agent.bot_id != undefined && agent.is_active)
          .map((x) => x.bot_id)
          .map((x) => assistants.find((a) => a.id === x))
          .filter(Boolean) as AssistantT[];

        const assistantNamesWithAgents = botsWithAgents.map((assistant) => ({
          name: assistant.name,
        }));
        return [
          {
            type: 'AGENT_BOT',
            values: assistantNamesWithAgents,
          },
          {
            type: 'BOT',
            values: resources,
          },
        ] as ExistingResource[];
      });

      return x;
    });

    const admins: Promise<ExistingResource> = getUsersAPI(
      subscription.workspace
    ).then((users: User[]) => {
      const resources: ResourceMeta[] = users.map((user) => ({
        name: `${user.first_name} ${user.last_name}`,
      }));

      return {
        type: 'ADMIN',

        values: resources,
      } as ExistingResource;
    });

    const onPremData = wrapperGetJson(
      automationAPIURLWithWorkspace(
        subscription.workspace,
        `${AutomationOnPremApi.GetAll}`
      )
    )
      .then((response: any) => response.data.output as onPremListResponseT[])
      .then((onPremAgents) => {
        const y: ResourceMeta[] = onPremAgents.map((agent) => {
          return {
            name: agent.name,
          };
        });

        return {
          type: 'ONPREM_CONNECTOR',
          values: y,
        } as ExistingResource;
      });

    const adminsStub = {
      type: 'ADMIN',
      values: [
        {
          type: 'ADMIN' as 'ADMIN',
          name: 'selva',
        } as ResourceMeta,
        {
          type: 'ADMIN' as 'ADMIN',
          name: 'john',
        } as ResourceMeta,
        {
          type: 'ADMIN' as 'ADMIN',
          name: 'mick',
        } as ResourceMeta,
      ],
    } as ExistingResource;

    const automationStub = [
      {
        type: 'ACTIVE_AUTOMATION',
        values: [],
      },
      {
        type: 'APPROVAL',
        values: [],
      },
    ] as ExistingResource[];

    const botSyub = [
      {
        type: 'BOT',
        values: [{ name: 'ITBOT' }],
      },
      {
        type: 'AGENT_BOT',
        values: [{ name: 'ITBOT' }],
      },
    ] as ExistingResource[];

    const onPremDataStub = {
      type: 'ONPREM_CONNECTOR',
      values: [
        {
          name: 'selva',
        } as ResourceMeta,
        {
          name: 'john',
        } as ResourceMeta,
        {
          name: 'mick',
        } as ResourceMeta,
      ],
    } as ExistingResource;

    const agentStub = {
      id: '55d19544-66c1-4811-973d-59031644af39',
      workspace: 'firefox',
      bot_id: '6ec2da52-d5a9-470f-8f60-2bc1d0be31c4',
      client_id: '845a03c5-2b8d-47e1-a79b-f94065b45e12',
      client_secrect: 'Hox7Q~Be0aoBJD0ktS6OyAima02b2y1yrk-Sb',
      is_active: false,
      is_authorized: true,
      tenant: 'fe8f0076-8dbf-4684-9f73-b389c33b2158',
      teams_deploy: {
        team_name: 'PP Test',
        team_id: '56d1e68c-3f63-4b04-b997-1a3d288ff133',
        is_active: true,
        date_added: '2021-12-08T09:36:58.000Z',
        added_by: 'abinaya',
        email_id: 'abinaya.k@workativ.com',
      },
      bot_data: {
        bot_name: 'AgentBot',
        bot_full_name: 'WorkativAssistant',
        bot_short_desc: 'Agent bot integration',
        bot_full_desc: 'Workativ Agent bot',
        is_generated: true,
        is_active: true,
      },
    };

    const [limit, info, ...rest] = await Promise.all([
      resourceLimit,
      infoData,
      admins,
      bots,
      automations,
      onPremData,
    ]);

    // const [limit, info, ...rest] = await Promise.all([
    //     resourceLimit,
    //     infoData,
    //     adminsStub,
    //     botSyub,
    //     automationStub,
    //     onPremDataStub
    // ])

    const agentInfo = info.find((x) => x.infoName === 'AGENT_HANDOFF_ACCESS');

    const onPremConnectorInfo = info.find(
      (x) => x.infoName === 'ON_PREM_ACCESS'
    );

    const approvalInfo = info.find((x) => x.infoName === 'APPROVAL_ACCESS');

    const agentHandoverInfoWithLimit = agentInfo
      ? limit.concat({
          resourceName: 'AGENT_BOT',
          resourceDisplayName: 'Agents',
          resourceLimit: agentInfo.value,
        })
      : limit;

    const onPremConnectorInfoWithLimit = onPremConnectorInfo
      ? agentHandoverInfoWithLimit.concat({
          resourceName: 'ONPREM_CONNECTOR',
          resourceDisplayName: 'On-prem Connectors',
          resourceLimit: onPremConnectorInfo.value,
        })
      : agentHandoverInfoWithLimit;

    const approvalInfoWithPermission = approvalInfo
      ? onPremConnectorInfoWithLimit.concat({
          resourceName: 'APPROVAL',
          resourceDisplayName: 'Approval workflows',
          resourceLimit: approvalInfo.value,
        })
      : onPremConnectorInfoWithLimit;

    const mergedResource = approvalInfoWithPermission
      .map((x: LimitForResources) => {
        const allResources = rest.reduce(
          (
            acc: ExistingResource[],
            curr: ExistingResource | ExistingResource[]
          ) => {
            if (Array.isArray(curr)) {
              return [...acc, ...curr];
            }
            return [...acc, curr];
          },
          []
        );

        console.log('all Resources ::: ', allResources);
        const filtered = allResources.find(
          (existing) => existing.type === x.resourceName
        );
        // console.log('all Resources ::: ', allResources, filtered)
        if (x.resourceName === 'AGENT_BOT' && filtered != undefined) {
          if (x.resourceLimit === 'yes') {
            return undefined;
          } else {
            return {
              allowed: 'no',
              values: filtered.values,
              type: filtered.type,
              category: 'permission',
            } as SubscribePopupDisplayData;
          }

          return undefined;
        }

        if (x.resourceName === 'APPROVAL' && filtered != undefined) {
          if (x.resourceLimit === 'yes') {
            return undefined;
          } else {
            return {
              allowed: 'no',
              values: filtered.values,
              type: filtered.type,
              category: 'permission',
            } as SubscribePopupDisplayData;
          }

          return undefined;
        }

        if (x.resourceName === 'ONPREM_CONNECTOR' && filtered != undefined) {
          if (x.resourceLimit === 'yes') {
            return undefined;
          } else {
            return {
              allowed: 'no',
              values: filtered.values,
              type: filtered.type,
              category: 'permission',
            } as SubscribePopupDisplayData;
          }

          return undefined;
        }

        if (filtered) {
          const currentCount = filtered.values.length;
          const allowedCount = x.resourceLimit;
          return currentCount > Number(allowedCount)
            ? ({
                current: currentCount,
                allowedCount: Number(allowedCount),
                values: filtered.values,
                type: filtered.type,
                category: 'limit',
              } as SubscribePopupDisplayData)
            : undefined;
        }
        return undefined;
      })
      .filter(notEmpty);

    console.log('mergedResource', mergedResource);

    const limitResources = mergedResource.filter(
      (resource) => resource.category === 'limit'
    );

    const permisionResources = mergedResource.filter((resource) => {
      return (
        resource.category === 'permission' &&
        resource.allowed == 'no' &&
        resource.values.length > 0
      );
    });

    // console.log('limit', limitResources, permisionResources, mergedResource)

    if (limitResources.length > 0 || permisionResources.length > 0) {
      setAction({
        mode: SubscriptionAction.StartWithLowerPlan,
        plan: plan,
        resources: mergedResource,
      });
    } else {
      setAction({
        mode: SubscriptionAction.Subscribe,
        planReference: referenceName,
        planMeta: plan,
      });
    }
  };

  const clickForSubscription = async (
    isTrial: boolean,
    referenceName: string,
    isLowerPlan: boolean,
    currentPlanRef: string
  ) => {
    // console.log('3:::::::clickForSubscription')
    try {
      // console.log('4:::::::', isTrial, isLowerPlan)
      if (
        isTrial ||
        subscription.subscription.status === 'canceled' ||
        currentPlanRef === 'FREE'
      ) {
        const planToSubscribe = allPlans.find(
          (x) => x.planName === referenceName
        );
        if (isLowerPlan && planToSubscribe) {
          setAction(PopupLoading);
          loadSubscribeToLowerPlanData(planToSubscribe, referenceName);
        } else if (planToSubscribe) {
          setAction({
            mode: SubscriptionAction.Subscribe,
            planReference: referenceName,
            planMeta: planToSubscribe,
          });
        }
      } else {
        // console.log('5:::::::')
        const planToUpgrade = plans.find((x) => x.planName === referenceName);
        const currentPlan = allPlans.find(
          (x) => x.planName === subscribedPlanToDisplay.planReferenceName
        );
        // console.log('1::::::', planToUpgrade, currentPlan, subscribedPlanToDisplay)
        if (planToUpgrade && currentPlan) {
          // console.log('2::::::', planToUpgrade, currentPlan)
          setAction({
            mode: SubscriptionAction.Upgrade,
            fromPlan: currentPlan,
            toPlan: planToUpgrade,
          });
        }
      }
    } catch (err) {
      setAction({
        mode: SubscriptionAction.Error,
        message: 'Unable to complete requested operation',
      });
      // console.log('error', err)
    }
  };

  const startTrial = async () => {
    if (action.mode == SubscriptionAction.StartTrail) {
      try {
        const resp = await stripePost(
          `${subscription.workspace}/free-to-trial`
        )({});
        window.location.reload();
      } catch (err) {
        setAction({
          mode: SubscriptionAction.Error,
          message: 'Unable to complete requested operation',
        });
        console.log('error', err);
      }
    }
  };

  const subcribeToPlan = async () => {
    if (action.mode == SubscriptionAction.Subscribe) {
      if (action.planReference === 'FREE') {
        try {
          const resp = await stripePost(
            `${subscription.workspace}/trial-to-free`
          )({});
          window.location.reload();
        } catch (err) {
          setAction({
            mode: SubscriptionAction.Error,
            message: 'Unable to complete requested operation',
          });
          console.log('error', err);
        }
      } else {
        try {
          const resp = await stripePost(
            `${subscription.workspace}/assistant/buy`
          )({
            plan: action.planReference,
          });
          const sessionId = resp.data.output.id;
          const stripe = await getStripe();
          if (stripe) {
            const { error } = await stripe.redirectToCheckout({
              sessionId,
            });
          }
        } catch (err) {
          setAction({
            mode: SubscriptionAction.Error,
            message: 'Unable to complete requested operation',
          });
          console.log('error', err);
        }
      }
    }
  };

  const upgradePlan = async (actionToDo: SubscriptionActions) => {
    if (actionToDo.mode == SubscriptionAction.Upgrade) {
      setAction(PopupLoading);
      try {
        const resp = await stripePost(`${subscription.workspace}/migrate`)({
          plan: actionToDo.toPlan.planName,
        });
        props.gotoBilling();
      } catch (err) {
        setAction({
          mode: SubscriptionAction.Error,
          message: 'Unable to complete requested operation',
        });
        console.log('error', err);
      }
    }
  };

  const getButtonText = (
    priority: number,
    _referenceName: string,
    isTrial: boolean,
    currentPlan: ReturnType<typeof getPlanTypeAndPriority>,
    toMigratePlanType: string
  ) => {
    // console.log('currentPlan', currentPlan, toMigratePlanType, _referenceName)

    if (currentPlan.priority === -1) {
      if (priority === currentPlan.priority) {
        return 'Current Plan';
      }
      return 'Pay Now';
    }
    if (subscription.subscription.status === 'canceled') {
      if (_referenceName === subscribedPlanToDisplay.planReferenceName) {
        return 'Pay Now';
      }
      return 'Select Plan';
    } else if (isTrial) {
      if (_referenceName === 'FREE') {
        return 'Subscribe';
      }
      if (priority === currentPlan.priority) {
        return 'Pay Now';
      } else if (priority > currentPlan.priority) {
        return 'Upgrade';
      } else if (priority < currentPlan.priority) {
        return 'Pay Now';
      }
    } else {
      if (toMigratePlanType === 'Monthly' && currentPlan.type === 'Monthly') {
        if (priority === currentPlan.priority) {
          return 'Current Plan';
        } else if (priority > currentPlan.priority) {
          return 'Upgrade';
        } else if (priority < currentPlan.priority) {
          return 'Select Plan';
        }
      } else if (
        toMigratePlanType === 'Yearly' &&
        currentPlan.type === 'Monthly'
      ) {
        if (priority === currentPlan.priority) {
          return 'Upgrade';
        } else if (priority > currentPlan.priority) {
          return 'Upgrade';
        } else if (priority < currentPlan.priority) {
          return 'Select Plan';
        }
      } else if (
        toMigratePlanType === 'Yearly' &&
        currentPlan.type === 'Yearly'
      ) {
        if (priority === currentPlan.priority) {
          return 'Current Plan';
        } else if (priority > currentPlan.priority) {
          return 'Upgrade';
        } else if (priority < currentPlan.priority) {
          return 'Select Plan';
        }
      } else if (
        toMigratePlanType === 'Monthly' &&
        currentPlan.type === 'Yearly'
      ) {
        return 'Select Plan';
      }
    }

    return 'Select Plan';
  };

  const allowPlan = (
    priority: number,
    _referenceName: string,
    isTrial: boolean,
    currentPlan: ReturnType<typeof getPlanTypeAndPriority>,
    toMigratePlanType: string
  ) => {
    // console.log('currentPlan', currentPlan, toMigratePlanType, _referenceName)
    if (subscription.subscription.status === 'canceled') {
      if (_referenceName === subscribedPlanToDisplay.planReferenceName) {
        return 'allow';
      }
      return 'dont-allow';
    } else if (isTrial) {
      if (priority === currentPlan.priority) {
        return 'allow';
      } else if (priority > currentPlan.priority) {
        return 'allow';
      } else if (priority < currentPlan.priority) {
        return 'allow';
      }
    } else {
      if (toMigratePlanType === 'Monthly' && currentPlan.type === 'Monthly') {
        if (priority === currentPlan.priority) {
          return 'dont-allow';
        } else if (priority > currentPlan.priority) {
          return 'allow';
        } else if (priority < currentPlan.priority) {
          return 'dont-allow';
        }
      } else if (
        toMigratePlanType === 'Yearly' &&
        currentPlan.type === 'Monthly'
      ) {
        if (priority === currentPlan.priority) {
          return 'allow';
        } else if (priority > currentPlan.priority) {
          return 'allow';
        } else if (priority < currentPlan.priority) {
          return 'dont-allow';
        }
      } else if (
        toMigratePlanType === 'Yearly' &&
        currentPlan.type === 'Yearly'
      ) {
        if (priority === currentPlan.priority) {
          return 'dont-allow';
        } else if (priority > currentPlan.priority) {
          return 'allow';
        } else if (priority < currentPlan.priority) {
          return 'dont-allow';
        }
      } else if (
        toMigratePlanType === 'Monthly' &&
        currentPlan.type === 'Yearly'
      ) {
        return 'dont-allow';
      }
    }

    return 'dont-allow';
  };

  return (
    <>
      {action.mode == SubscriptionAction.Loading ? (
        <Modal>
          <Loader.PopupLoader show={true} />
        </Modal>
      ) : null}
      {action.mode == SubscriptionAction.Error ? (
        <Modal>
          <h2>Error </h2>
          <p> {action.message}</p>
          <ConfirmButtonWrapper>
            <ConfirmPopupButton
              type="button"
              data-dismiss="modal"
              onClick={() => {
                setAction(None);
              }}
            >
              Ok
            </ConfirmPopupButton>
          </ConfirmButtonWrapper>
        </Modal>
      ) : null}
      {action.mode == SubscriptionAction.Subscribe ? (
        <Modal>
          <h2>Subscribe to {action.planMeta.planDisplayName} </h2>
          <p>
            {action.planMeta.planDisplayName.toLowerCase() !== 'free'
              ? 'You will be redirected to the payment portal for your credit card details'
              : 'Are you sure to subscribe to Free plan?'}
          </p>
          <ConfirmButtonWrapper>
            <ConfirmPopupButton
              primary
              type="button"
              data-dismiss="modal"
              onClick={() => {
                setAction(None);
              }}
            >
              No
            </ConfirmPopupButton>

            <ConfirmPopupButton
              type="button"
              data-dismiss="modal"
              onClick={() => {
                subcribeToPlan();
              }}
            >
              Yes
            </ConfirmPopupButton>
          </ConfirmButtonWrapper>
        </Modal>
      ) : null}

      {action.mode == SubscriptionAction.StartTrail ? (
        <Modal>
          <h2>Start trail for Pro plan </h2>
          <p> Do you want to start trial for Pro plan?</p>
          <ConfirmButtonWrapper>
            <ConfirmPopupButton
              primary
              type="button"
              data-dismiss="modal"
              onClick={() => {
                setAction(None);
              }}
            >
              No
            </ConfirmPopupButton>
            <ConfirmPopupButton
              type="button"
              data-dismiss="modal"
              onClick={() => {
                startTrial();
              }}
            >
              Yes
            </ConfirmPopupButton>
          </ConfirmButtonWrapper>
        </Modal>
      ) : null}
      {action.mode == SubscriptionAction.Upgrade ? (
        <>
          <UpgradeModal>
            <div className="popup_upgrade_wrapper">
              <h2>
                Upgrade from <br></br>
                <span>{`${action.fromPlan.planDisplayName} ${action.fromPlan.planBillingInterval} `}</span>{' '}
                to{' '}
                <span>
                  {action.toPlan.planDisplayName}{' '}
                  {action.toPlan.planBillingInterval}
                </span>
              </h2>
              <div className="plans_calc_wrapper">
                <p>
                  <span>
                    {action.toPlan.planDisplayName}{' '}
                    {action.toPlan.planBillingInterval} :
                  </span>{' '}
                  <span>
                    $
                    {action.toPlan.planBillingInterval === 'Monthly'
                      ? Number(action.toPlan.cardDisplayData.price)
                      : Number(action.toPlan.cardDisplayData.price)}
                  </span>
                </p>
                <p>
                  <span>
                    {action.fromPlan.planDisplayName}{' '}
                    {action.fromPlan.planBillingInterval} :
                  </span>
                  {'-'}
                  <span>
                    $
                    {action.fromPlan.planBillingInterval === 'Monthly'
                      ? Number(action.fromPlan.cardDisplayData.price)
                      : Number(action.fromPlan.cardDisplayData.price)}
                  </span>
                </p>
                <p>
                  {' '}
                  <span>Total Price :</span>
                  <span>
                    $
                    {Number(action.toPlan.cardDisplayData.price) -
                      Number(action.fromPlan.cardDisplayData.price)}
                  </span>
                </p>
              </div>
              <ConfirmButtonWrapper>
                <ConfirmPopupButton
                  primary
                  type="button"
                  data-dismiss="modal"
                  onClick={() => {
                    setAction(None);
                  }}
                >
                  Cancel
                </ConfirmPopupButton>
                <ConfirmPopupButton
                  type="button"
                  data-dismiss="modal"
                  onClick={() => {
                    upgradePlan(action);
                  }}
                >
                  Pay Now
                </ConfirmPopupButton>
              </ConfirmButtonWrapper>
            </div>
          </UpgradeModal>
        </>
      ) : null}
      {action.mode == SubscriptionAction.StartWithLowerPlan ? (
        <PopupBox
          plan={action.plan}
          resources={action.resources}
          close={() => setAction(None)}
        />
      ) : null}
      <PricingSection>
        <PricingContainer>
          <>
            {freePlan.map(({ cardDisplayData: data }, index: number) => {
              return (
                <PricingCard
                  isCurrent={
                    getButtonText(
                      data.priority,
                      data.referenceName,
                      subscribedPlanToDisplay.isInTrial,
                      currentPlanType,
                      data.type
                    ) === 'Current Plan'
                      ? true
                      : false
                  }
                >
                  <PricingHeader>
                    <PricingHeaderLeft>
                      <HeaderContent>{data.plans}</HeaderContent>
                      <AmountContent>
                        <sup>{data.dollar}</sup>
                        {data.price}
                        <sub>
                          {props.planBillingCycle === 'Monthly'
                            ? '/month'
                            : '/month'}
                        </sub>
                      </AmountContent>
                      {data.discount && (
                        <div className="discount_price">{data.discount}</div>
                      )}
                      <Paragraph>{data.uesrsMessage}</Paragraph>
                    </PricingHeaderLeft>
                    <PricingHeaderRight>
                      <ImgWrapper>{data.image}</ImgWrapper>
                    </PricingHeaderRight>
                  </PricingHeader>
                  <MessageWrapper>
                    {data.resources.map((data) => (
                      <MessageContainer>
                        <MessageLeft>
                          <MessageHeader>
                            <label>
                              {data.category}
                              {/* {data.isComingSoon ? (
                                                                <sup style={{ top: '-.2em', color: '#E05959' }}>*</sup>
                                                            ) : null} */}
                            </label>

                            <Tooltip
                              className="target"
                              tipContentClassName=""
                              tipContentHover
                              zIndex={5000}
                              arrowSize={5}
                              tagName="span"
                              content={data.hoverContent}
                            >
                              <span>
                                <InfoIcon />
                              </span>
                            </Tooltip>
                          </MessageHeader>
                        </MessageLeft>
                        <MessageRight>{data.bolder}</MessageRight>
                      </MessageContainer>
                    ))}
                    {subscribedPlanToDisplay.planReferenceName === 'FREE' &&
                    data.referenceName != 'FREE' &&
                    subscribedPlanToDisplay.hasHadTrial === false ? (
                      <ButtonWrapper>
                        <Button
                          onClick={() =>
                            setAction({
                              mode: SubscriptionAction.StartTrail,
                            })
                          }
                        >
                          {'Start Trial'}
                        </Button>
                      </ButtonWrapper>
                    ) : null}

                    {getButtonText(
                      data.priority,
                      data.referenceName,
                      subscribedPlanToDisplay.isInTrial,
                      currentPlanType,
                      data.type
                    ) === 'Current Plan' ? (
                      <ButtonWrapper>
                        <Button>{'Current Plan'} </Button>
                      </ButtonWrapper>
                    ) : (
                      <ButtonWrapper
                        dontAllowClick={
                          allowPlan(
                            data.priority,
                            data.referenceName,
                            subscribedPlanToDisplay.isInTrial,
                            currentPlanType,
                            data.type
                          ) === 'allow'
                            ? false
                            : true
                        }
                      >
                        <Button
                          onClick={
                            allowPlan(
                              data.priority,
                              data.referenceName,
                              subscribedPlanToDisplay.isInTrial,
                              currentPlanType,
                              data.type
                            ) == 'allow'
                              ? () =>
                                  clickForSubscription(
                                    subscribedPlanToDisplay.isInTrial,
                                    data.referenceName,
                                    data.priority < currentPlanType.priority,
                                    subscribedPlanToDisplay.planReferenceName
                                  )
                              : () => {}
                          }
                        >
                          {getButtonText(
                            data.priority,
                            data.referenceName,
                            subscribedPlanToDisplay.isInTrial,
                            currentPlanType,
                            data.type
                          )}{' '}
                        </Button>
                        {allowPlan(
                          data.priority,
                          data.referenceName,
                          subscribedPlanToDisplay.isInTrial,
                          currentPlanType,
                          data.type
                        ) === 'dont-allow' ? (
                          <HoverUserIcon>
                            <span className="info_icon">
                              <InfoIcon />
                              <span className="side_menu_hvr">
                                Downgrade not available
                              </span>
                            </span>
                          </HoverUserIcon>
                        ) : null}
                      </ButtonWrapper>
                    )}
                  </MessageWrapper>

                  <ContentWrapper
                    className={data.plans === 'Pro' ? 'two_btn_wrapper' : null}
                  >
                    <MessageWrapper>
                      {data.steps.map((steps) => (
                        <MessageContainer>
                          <MessageLeft>
                            <MessageHeader>
                              <label>
                                {steps.category}{' '}
                                {/* {steps.isComingSoon ? (
                                                                    <sup style={{ top: '-.2em', color: '#E05959' }}>
                                                                        *
                                                                    </sup>
                                                                ) : null} */}
                              </label>

                              <Tooltip
                                className="target"
                                tipContentClassName=""
                                tipContentHover
                                zIndex={5000}
                                arrowSize={5}
                                tagName="span"
                                content={steps.hoverContent}
                              >
                                <span>
                                  <InfoIcon />
                                </span>
                              </Tooltip>
                            </MessageHeader>
                          </MessageLeft>
                          <MessageRight>
                            <span>
                              {steps.isConsumption && steps.isConsumption ? (
                                <b>{steps.isConsumption}</b>
                              ) : (
                                steps.icon
                              )}
                            </span>
                          </MessageRight>
                        </MessageContainer>
                      ))}
                    </MessageWrapper>
                  </ContentWrapper>
                </PricingCard>
              );
            })}
            {plansExceptFree.map(({ cardDisplayData: data }, index: number) => {
              return (
                <PricingCard
                  isCurrent={
                    getButtonText(
                      data.priority,
                      data.referenceName,
                      subscribedPlanToDisplay.isInTrial,
                      currentPlanType,
                      data.type
                    ) === 'Current Plan'
                      ? true
                      : false
                  }
                >
                  <PricingHeader>
                    <PricingHeaderLeft>
                      <HeaderContent>{data.plans}</HeaderContent>
                      <AmountContent>
                        <sup>{data.dollar}</sup>
                        {data.price}
                        <sub>
                          {props.planBillingCycle === 'Monthly'
                            ? '/month'
                            : '/month'}
                        </sub>
                      </AmountContent>
                      {data.discount && (
                        <div className="discount_price">{data.discount}</div>
                      )}
                      <Paragraph>{data.uesrsMessage}</Paragraph>
                    </PricingHeaderLeft>
                    <PricingHeaderRight>
                      <ImgWrapper>{data.image}</ImgWrapper>
                    </PricingHeaderRight>
                  </PricingHeader>
                  <MessageWrapper>
                    {data.resources.map((data) => (
                      <MessageContainer>
                        <MessageLeft>
                          <MessageHeader>
                            <label>{data.category}</label>

                            <Tooltip
                              className="target"
                              tipContentClassName=""
                              tipContentHover
                              zIndex={5000}
                              arrowSize={5}
                              tagName="span"
                              content={data.hoverContent}
                            >
                              <span>
                                <InfoIcon />
                              </span>
                            </Tooltip>
                          </MessageHeader>
                        </MessageLeft>
                        <MessageRight>{data.bolder}</MessageRight>
                      </MessageContainer>
                    ))}
                    {subscribedPlanToDisplay.planReferenceName === 'FREE' &&
                    data.referenceName != 'FREE' &&
                    subscribedPlanToDisplay.hasHadTrial === false ? (
                      <ButtonWrapper>
                        <Button
                          onClick={() =>
                            setAction({
                              mode: SubscriptionAction.StartTrail,
                            })
                          }
                        >
                          {'Start Trial'}
                        </Button>
                      </ButtonWrapper>
                    ) : null}

                    {getButtonText(
                      data.priority,
                      data.referenceName,
                      subscribedPlanToDisplay.isInTrial,
                      currentPlanType,
                      data.type
                    ) === 'Current Plan' ? (
                      <ButtonWrapper>
                        <Button>{'Current Plan'} </Button>
                      </ButtonWrapper>
                    ) : (
                      <ButtonWrapper
                        dontAllowClick={
                          allowPlan(
                            data.priority,
                            data.referenceName,
                            subscribedPlanToDisplay.isInTrial,
                            currentPlanType,
                            data.type
                          ) === 'allow'
                            ? false
                            : true
                        }
                      >
                        <Button
                          onClick={
                            allowPlan(
                              data.priority,
                              data.referenceName,
                              subscribedPlanToDisplay.isInTrial,
                              currentPlanType,
                              data.type
                            ) == 'allow'
                              ? () =>
                                  clickForSubscription(
                                    subscribedPlanToDisplay.isInTrial,
                                    data.referenceName,
                                    data.priority < currentPlanType.priority,
                                    subscribedPlanToDisplay.planReferenceName
                                  )
                              : () => {}
                          }
                        >
                          {getButtonText(
                            data.priority,
                            data.referenceName,
                            subscribedPlanToDisplay.isInTrial,
                            currentPlanType,
                            data.type
                          )}{' '}
                        </Button>
                        {allowPlan(
                          data.priority,
                          data.referenceName,
                          subscribedPlanToDisplay.isInTrial,
                          currentPlanType,
                          data.type
                        ) === 'dont-allow' ? (
                          <HoverUserIcon>
                            <span className="info_icon">
                              <InfoIcon />
                              <span className="side_menu_hvr">
                                Downgrade not available
                              </span>
                            </span>
                          </HoverUserIcon>
                        ) : null}
                      </ButtonWrapper>
                    )}
                  </MessageWrapper>

                  <ContentWrapper
                    className={data.plans === 'Pro' ? 'two_btn_wrapper' : null}
                  >
                    <MessageWrapper>
                      {data.steps.map((steps) => (
                        <MessageContainer>
                          <MessageLeft>
                            <MessageHeader>
                              <label>{steps.category} </label>

                              <Tooltip
                                className="target"
                                tipContentClassName=""
                                tipContentHover
                                zIndex={5000}
                                arrowSize={5}
                                tagName="span"
                                content={steps.hoverContent}
                              >
                                <span>
                                  <InfoIcon />
                                </span>
                              </Tooltip>
                            </MessageHeader>
                          </MessageLeft>
                          <MessageRight>
                            <span>
                              {steps.isConsumption && steps.isConsumption ? (
                                <b>{steps.isConsumption}</b>
                              ) : (
                                steps.icon
                              )}
                            </span>
                          </MessageRight>
                        </MessageContainer>
                      ))}
                    </MessageWrapper>
                  </ContentWrapper>
                </PricingCard>
              );
            })}
           
              {isEnterprise ? (
                [
                  makeEnterprisePlanUIStructure(
                    currentSubscriptionPlanResourceData,
                    currentSubscriptionPlanInfoData,
                    subscribedPlanToDisplay.planDisplayName,
                    subscribedPlanToDisplay.planReferenceName,
                    subscribedPlanToDisplay.price,
                    subscribedPlanToDisplay.planType
                    
                    // currentSubscriptionPlanInfoData.
                  ),
                ].map((data) => (
                  <PricingCard
                    isCurrent={
                      getButtonText(
                        data.priority,
                        data.referenceName,
                        subscribedPlanToDisplay.isInTrial,
                        currentPlanType,
                        data.type
                      ) === 'Current Plan'
                        ? true
                        : false
                    }
                  >
                    <PricingHeader>
                      <PricingHeaderLeft>
                        <HeaderContent>{data.plans}</HeaderContent>
                        <AmountContent>
                          <sup>{data.dollar}</sup>
                          {/* {console.log("DOING :: PRICE", data)} */}
                          {data.price}
                          <sub>
                            {data.type === 'Monthly'
                              ? '/month'
                              : '/month'}
                          </sub>
                        </AmountContent>
                        {data.discount && (
                          <div className="discount_price">{data.discount}</div>
                        )}
                        <Paragraph>{data.uesrsMessage}</Paragraph>
                      </PricingHeaderLeft>
                      <PricingHeaderRight>
                        <ImgWrapper>{data.image}</ImgWrapper>
                      </PricingHeaderRight>
                    </PricingHeader>
                    <MessageWrapper>
                      {data.resources.map((data) => (
                        <MessageContainer>
                          <MessageLeft>
                            <MessageHeader>
                              <label>{data.category}</label>

                              <Tooltip
                                className="target"
                                tipContentClassName=""
                                tipContentHover
                                zIndex={5000}
                                arrowSize={5}
                                tagName="span"
                                content={data.hoverContent}
                              >
                                <span>
                                  <InfoIcon />
                                </span>
                              </Tooltip>
                            </MessageHeader>
                          </MessageLeft>
                          <MessageRight>{data.bolder}</MessageRight>
                        </MessageContainer>
                      ))}
                      {subscribedPlanToDisplay.planReferenceName === 'FREE' &&
                      data.referenceName != 'FREE' &&
                      subscribedPlanToDisplay.hasHadTrial === false ? (
                        <ButtonWrapper>
                          <Button
                            onClick={() =>
                              setAction({
                                mode: SubscriptionAction.StartTrail,
                              })
                            }
                          >
                            {'Start Trial'}
                          </Button>
                        </ButtonWrapper>
                      ) : null}

                      {getButtonText(
                        data.priority,
                        data.referenceName,
                        subscribedPlanToDisplay.isInTrial,
                        currentPlanType,
                        data.type
                      ) === 'Current Plan' ? (
                        <ButtonWrapper>
                          <Button>{'Current Plan'} </Button>
                        </ButtonWrapper>
                      ) : (
                        <ButtonWrapper
                          dontAllowClick={
                            allowPlan(
                              data.priority,
                              data.referenceName,
                              subscribedPlanToDisplay.isInTrial,
                              currentPlanType,
                              data.type
                            ) === 'allow'
                              ? false
                              : true
                          }
                        >
                          <Button
                            onClick={
                              allowPlan(
                                data.priority,
                                data.referenceName,
                                subscribedPlanToDisplay.isInTrial,
                                currentPlanType,
                                data.type
                              ) == 'allow'
                                ? () =>
                                    clickForSubscription(
                                      subscribedPlanToDisplay.isInTrial,
                                      data.referenceName,
                                      data.priority < currentPlanType.priority,
                                      subscribedPlanToDisplay.planReferenceName
                                    )
                                : () => {}
                            }
                          >
                            {getButtonText(
                              data.priority,
                              data.referenceName,
                              subscribedPlanToDisplay.isInTrial,
                              currentPlanType,
                              data.type
                            )}{' '}
                          </Button>
                          {allowPlan(
                            data.priority,
                            data.referenceName,
                            subscribedPlanToDisplay.isInTrial,
                            currentPlanType,
                            data.type
                          ) === 'dont-allow' ? (
                            <HoverUserIcon>
                              <span className="info_icon">
                                <InfoIcon />
                                <span className="side_menu_hvr">
                                  Downgrade not available
                                </span>
                              </span>
                            </HoverUserIcon>
                          ) : null}
                        </ButtonWrapper>
                      )}
                    </MessageWrapper>

                    <ContentWrapper
                      className={
                        data.plans === 'Pro' ? 'two_btn_wrapper' : null
                      }
                    >
                      <MessageWrapper>
                        {data.steps.map((steps: any) => (
                          <MessageContainer>
                            <MessageLeft>
                              <MessageHeader>
                                <label>{steps.category} </label>

                                <Tooltip
                                  className="target"
                                  tipContentClassName=""
                                  tipContentHover
                                  zIndex={5000}
                                  arrowSize={5}
                                  tagName="span"
                                  content={steps.hoverContent}
                                >
                                  <span>
                                    <InfoIcon />
                                  </span>
                                </Tooltip>
                              </MessageHeader>
                            </MessageLeft>
                            <MessageRight>
                              <span>
                                {steps.isConsumption && steps.isConsumption ? (
                                  <b>{steps.isConsumption}</b>
                                ) : (
                                  steps.icon
                                )}
                              </span>
                            </MessageRight>
                          </MessageContainer>
                        ))}
                      </MessageWrapper>
                    </ContentWrapper>
                  </PricingCard>
                ))
              ) : (
                <PriceEnterpriseWrapper>
                <PricingCard>
                  <PricingHeader>
                    <PricingHeaderLeft>
                      <HeaderContent>Enterprise</HeaderContent>
                      <AmountContent></AmountContent>
                    </PricingHeaderLeft>
                    <PricingHeaderRight>
                      <ImgWrapper>
                        <PriceEnterpriseIcon />
                      </ImgWrapper>
                    </PricingHeaderRight>
                  </PricingHeader>
                  <MessageWrapper className="message_first_wrapper">
                    <MessageContainer>
                      <Paragraph>
                        <b>Looking for More?</b> <br /> Enterprise plans are
                        customised. Please reach out to us at{' '}
                        <a href="mailto:sales@workativ.com">
                          sales@workativ.com
                        </a>{' '}
                        and we’d be happy to discuss.
                      </Paragraph>
                      <ButtonWrapper>
                        <a href="mailto:sales@workativ.com">
                          <button>Contact Sales</button>
                        </a>
                      </ButtonWrapper>
                    </MessageContainer>
                  </MessageWrapper>
                </PricingCard>
                </PriceEnterpriseWrapper>
              )}
          </>
        </PricingContainer>
      </PricingSection>
      {/* <ComingSoonWrapper>
                <span style={{ color: 'red' }}>* </span> Coming soon
            </ComingSoonWrapper> */}
    </>
  );
};

export const loadPlansAndSubscription = async (workspace: string) => {
  const plansApi = () => stripeGetApi(`${workspace}/plans`);
  const subscriptionApi = () =>
    stripeGetApi(`${workspace}/subscription/current`);
  const x = await Promise.all([plansApi(), subscriptionApi()]).then(
    ([a, b]) => [{ plans: a.output }, makeUiDomainFromResponse(b.output)]
  );

  const [{ plans }, subscription] = x as [
    {
      plans: {
        plans: PlanResponse[];
        defaultPlan: DefaultPlan;
        planLimits: PlanResourcesLimit;
        planInfo: PlanInfo;
      };
    },
    SubscribedPlan
  ];

  return {
    plans: plans.plans,
    subscription,
    planLimits: plans.planLimits,
    defaultPlan: plans.defaultPlan,
    planInfo: plans.planInfo,
    workspace,
  };
};

export const PlanWrapper = (props: any) => {
  const workspaceName = props.match.params.workspacename;

  const [state, setState, promiseRef] = useFetchFromApi(() =>
    loadPlansAndSubscription(workspaceName)
  );
  useEffect(() => {
    if (promiseRef.current != null) {
      const setStateDispatch = setState as React.Dispatch<any>;
      setStateDispatch(
        promiseRef.current
          ? promiseRef.current.then((response: any) => response.data)
          : null
      );
    }
  }, [promiseRef.current]);

  useEffect(() => {
    logEvent(workspaceName, {
      event: 'EVENT::BILLING::VIEW_PAGE',
      event_meta: {},
    });
  }, []);

  const retry = () => {
    const setStateDispatch = setState as React.Dispatch<any>;

    setStateDispatch(
      promiseRef.current
        ? loadPlansAndSubscription(workspaceName).then(
            (response: any) => response.data
          )
        : null
    );
  };
  return (
    <Plans state={state} retry={retry} {...props} workspace={workspaceName} />
  );
};
type Unwrap<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any) => Promise<infer U>
  ? U
  : T extends (...args: any) => infer U
  ? U
  : T;

export const Plans = (props: any) => {
  const state = props.state as FetchReducerT;
  const { workspace } = props;
  const gotoBilling = () => {
    props.history.push(`/workspace/${workspace}/billing`);
  };

  const [PageState, setPageState] = useState<PageStateT>({
    action: ActionT.Loading,
  });

  useEffect(() => {
    const nextProps = props;
    // console.log(nextProps)

    if (nextProps.state.type == 'loading') {
      setPageState({ action: ActionT.Loading });
    } else if (nextProps.state.type == 'error') {
      if (
        nextProps.state.error.response &&
        (nextProps.state.error.response.status == 401 ||
          nextProps.state.error.response.status == 403)
      ) {
        setPageState({ action: ActionT.Unauthorized });
        return;
      } else {
        setPageState(ErrorPage);
        return;
      }
    } else if (nextProps.state.type == 'success') {
      const plansAndSubscription: Unwrap<
        ReturnType<typeof loadPlansAndSubscription>
      > = nextProps.state.data;
      setPageState({ action: ActionT.View, ...plansAndSubscription });
    }
  }, [props.state]);

  if (PageState.action === ActionT.Loading)
    return <Loader.PageLoader show={true} />;

  if (PageState.action === ActionT.View) {
    const { plans, defaultPlan, subscription, planLimits, planInfo } =
      PageState;
    const params = qs.parse(props.location.search.substring(1));
    // console.log('params', params)
    return (
      <PlanComponents
        plans={plans}
        planLimits={planLimits}
        planInfo={planInfo}
        defaultPlan={defaultPlan}
        subscription={subscription}
        gotoBilling={gotoBilling}
        fromBilling={params.from != undefined}
        planType={
          params.planType
            ? stringToPlanTypeQueryParam(params.planType)
            : 'monthly'
        }
      />
    );
  }

  return null;
};

const PlanComponents = (props: {
  plans: PlanResponse[];
  defaultPlan: DefaultPlan;
  subscription: SubscribedPlan;
  gotoBilling: () => void;
  fromBilling: boolean;
  planType: PlanTypeQueryParam;
  planInfo: PlanInfo;
  planLimits: PlanResourcesLimit;
}) => {
  console.log('DOING ::: PlanComponents', props);
  type PlanSwitchType = 'Monthly' | 'Yearly';

  const { plans } = props;
  let MonthlyPlans = PlansV2.concat(AdditionalPlans)
    .filter((plan) => plan.type === 'Monthly')
    .map((plan) => plan);

  let YearlyPlans = PlansV2.concat(AdditionalPlans)
    .filter((plan) => plan.type === 'Yearly')
    .map((plan) => plan);

  const monthlyPlans = plans.filter(
    (plan) => plan.planBillingInterval === 'Monthly'
  );
  const yearlyPlans = plans.filter(
    (plan) => plan.planBillingInterval === 'Yearly'
  );

  const makePlanSwitchType = (qParam: PlanTypeQueryParam) => {
    // console.log('qparam', qParam)
    return qParam === 'monthly' ? 'Monthly' : 'Yearly';
  };

  const monthlyPlansWithDisplayData = monthlyPlans
    .map((plan) => {
      const matchedPlan = MonthlyPlans.find(
        (displayPlan) => displayPlan.referenceName === plan.planName
      );
      // console.log('matchedPlan', matchedPlan, plan.planName)
      return {
        ...plan,
        cardDisplayData: matchedPlan,
        priority: matchedPlan ? matchedPlan.priority : 0,
      } as PlanResponse & {
        cardDisplayData: PlanMetaForUIV2;
        priority: number;
      };
    })
    .filter((plans) => plans.cardDisplayData != undefined);

  const yearlyPlansWithDisplayData = yearlyPlans
    .map((plan) => {
      const matchedPlan = YearlyPlans.find(
        (displayPlan) => displayPlan.referenceName === plan.planName
      );
      return {
        ...plan,
        cardDisplayData: matchedPlan,
        priority: matchedPlan ? matchedPlan.priority : 0,
      } as PlanResponse & {
        cardDisplayData: PlanMetaForUIV2;
        priority: number;
      };
    })
    .filter((plans) => plans.cardDisplayData != undefined);
  const allPlans = monthlyPlansWithDisplayData.concat(
    yearlyPlansWithDisplayData
  );

  const [plansToDisplay, setPlansToDisplay] = useState<
    (PlanResponse & { cardDisplayData: PlanMetaForUIV2 })[]
  >(monthlyPlansWithDisplayData);
  const [planSwitch, setSwitch] = useState<PlanSwitchType>(
    makePlanSwitchType(props.planType)
  );

  type planType = typeof monthlyPlansWithDisplayData;

  useEffect(() => {
    if (planSwitch === 'Monthly') {
      const sorted = R.sortBy(R.prop('priority'))(monthlyPlansWithDisplayData);
      setPlansToDisplay(sorted);
    } else if (planSwitch === 'Yearly') {
      const sorted = R.sortBy(R.prop('priority'))(yearlyPlansWithDisplayData);
      setPlansToDisplay(sorted);
    }
  }, [planSwitch]);

  const changeSwitch = (value: 'Monthly' | 'Yearly') => {
    setSwitch(value);
  };
  // console.log('subscription', props.subscription)

  // const currentCycleStartDate = stripeUTCDate(PageState.subscription.subscription.current_period_start)
  //     const currentCycleEndDate = stripeUTCDate(PageState.subscription.subscription.current_period_end)
  // console.log('allPlans', plans, allPlans, MonthlyPlans, monthlyPlans)
  return (
    <>
      <div className="plansWrapper">
        <PlansHeaderV2
          backButton
          goBack={() => window.history.back()}
          name={'Billing'}
        />
        <BodyWrapperV2  className="plan_scrollHeight" scrollHeight>
          <WrapperV2>
            <ContainerV2>
              <SubscriptionDetails
                subscriptionDetails={getSubscriptionDisplayDetails(
                  props.subscription,
                  props.defaultPlan
                )}
                resources={props.subscription.resourceConsumption}
              />
              <UpgradePlanWrapper>
                <UpgradePlanLeft>
                  <UpgradeHeader>Select Plan</UpgradeHeader>
                </UpgradePlanLeft>
                <UpgradePlanRight>
                  <SubscriptionPlanChange
                    defaultValue={planSwitch}
                    toggleSwitch={changeSwitch}
                  ></SubscriptionPlanChange>
                </UpgradePlanRight>
              </UpgradePlanWrapper>
              <PricingPage
                planBillingCycle={planSwitch}
                plans={plansToDisplay}
                allPlans={allPlans}
                subscription={props.subscription}
                defaultPlan={props.defaultPlan}
                gotoBilling={props.gotoBilling}
                planInfo={props.planInfo}
                planLimits={props.planLimits}
              />
            </ContainerV2>
          </WrapperV2>
        </BodyWrapperV2>
      </div>
    </>
  );
};

export const plurarize = (count: number, noun: string) => {
  // da faq
  return count <= 1 ? noun : `${noun}s`;
};

function PopupBox(props: {
  plan: PlanResponse & {
    cardDisplayData: PlanMetaForUI;
  };
  resources: SubscribePopupDisplayData[];
  close: () => void;
}) {
  const { plan, resources } = props;
  const toDisplay = resources
    .map((resourceDisplay: SubscribePopupDisplayData) => {
      if (
        resourceDisplay.type === 'ACTIVE_AUTOMATION' &&
        resourceDisplay.category == 'limit'
      ) {
        return {
          Text: (
            <h6>
              This plan allows <span>{resourceDisplay.allowedCount}</span>{' '}
              active {plurarize(resourceDisplay.allowedCount, 'workflow')} only,
              need to inactive at least{' '}
              <span>
                {resourceDisplay.current - resourceDisplay.allowedCount}
              </span>{' '}
              {plurarize(
                resourceDisplay.current - resourceDisplay.allowedCount,
                'workflow'
              )}{' '}
              .
            </h6>
          ),
          itemList: resourceDisplay.values.map((resource) => (
            <div className="flow_tree">
              <div className="first_step">
                <span className="bg_tree_content">{resource.name}</span>
              </div>
            </div>
          )),
          wrapperClass: 'active_automations_wrapper',
        };
      }
      if (
        resourceDisplay.type === 'BOT' &&
        resourceDisplay.category == 'limit'
      ) {
        return {
          Text: (
            <h6>
              This plan allow <span>{resourceDisplay.allowedCount}</span>{' '}
              {plurarize(resourceDisplay.allowedCount, 'bot')} only, need to
              delete{' '}
              <span>
                {resourceDisplay.current - resourceDisplay.allowedCount}
              </span>{' '}
              {plurarize(
                resourceDisplay.current - resourceDisplay.allowedCount,
                'bot'
              )}
            </h6>
          ),
          itemList: resourceDisplay.values.map((resource) => (
            <div className="flow_tree  ">
              <div className="first_step  ">
                <span className="tree_node bg_tree_flow">
                  <DialogIcon />
                </span>
                <span className="bg_tree_content">{resource.name}</span>
              </div>
            </div>
          )),
          wrapperClass: 'active_bots_wrapper',
        };
      }
      if (
        resourceDisplay.type === 'ADMIN' &&
        resourceDisplay.category == 'limit'
      ) {
        return {
          Text: (
            <h6>
              This plan allows <span>{resourceDisplay.allowedCount}</span>{' '}
              {plurarize(resourceDisplay.allowedCount, 'admin')} only, need to
              remove{' '}
              <span>
                {resourceDisplay.current - resourceDisplay.allowedCount}
              </span>{' '}
              {plurarize(
                resourceDisplay.current - resourceDisplay.allowedCount,
                'admin'
              )}
            </h6>
          ),
          itemList: resourceDisplay.values.map((resource) => (
            <div className="flow_tree">
              <div className="first_step">
                <span className="tree_node bg_tree_flow">
                  <UserIconAnalytics />
                </span>
                <span className="bg_tree_content">{resource.name}</span>
              </div>
            </div>
          )),
          wrapperClass: 'active_admins_wrapper',
        };
      }

      if (
        resourceDisplay.type === 'AGENT_BOT' &&
        resourceDisplay.category == 'permission' &&
        resourceDisplay.values.length > 0
      ) {
        return {
          Text: (
            <h6>
              This plan does not allow agents, need to remove all agents in the
              below bots
            </h6>
          ),
          itemList: resourceDisplay.values.map((resource) => (
            <div className="flow_tree">
              <div className="first_step">
                <span className="tree_node bg_tree_flow">
                  <DialogIcon />
                </span>
                <span className="bg_tree_content">{resource.name}</span>
              </div>
            </div>
          )),
          wrapperClass: 'active_admins_wrapper',
        };
      }

      if (
        resourceDisplay.type === 'ONPREM_CONNECTOR' &&
        resourceDisplay.category == 'permission' &&
        resourceDisplay.values.length > 0
      ) {
        return {
          Text: (
            <h6>
              This plan does not allow on-prem connectors, please delete the
              connectors listed below
            </h6>
          ),
          itemList: resourceDisplay.values.map((resource) => (
            <div className="flow_tree">
              <div className="first_step">
                <span className="tree_node bg_tree_flow">
                  <SubPanelIcons.OnPremICon />
                </span>
                <span className="bg_tree_content">{resource.name}</span>
              </div>
            </div>
          )),
          wrapperClass: 'active_admins_wrapper',
        };
      }

      if (
        resourceDisplay.type === 'APPROVAL' &&
        resourceDisplay.category == 'permission' &&
        resourceDisplay.values.length > 0
      ) {
        return {
          Text: (
            <h6>
              This plan does not allow approvals, please delete the workflows
              listed below
            </h6>
          ),
          itemList: resourceDisplay.values.map((resource) => (
            <div className="flow_tree">
              <div className="first_step">
                <span className="bg_tree_content">{resource.name}</span>
              </div>
            </div>
          )),
          wrapperClass: 'active_automations_wrapper',
        };
      }
      return null;
    })
    .filter(notEmpty);
  type ToDisplay = UnwrapArray<typeof toDisplay>;
  return (
    <section className="change_plan_wrapper">
      <div data-ml-modal>
        <a className="modal-overlay" />
        <div className="modal-dialog-box">
          <div className="modal-content center">
            <div className="plan_selected_wrapper">
              <h4>You have selected</h4>
              <h2>{plan.planDisplayName}</h2>
            </div>
            <div className="plans_modify_wrapper">
              <h6>You need to modify following items</h6>
              <div className="admin_select_plans">
                {toDisplay.map((Item: ToDisplay) => (
                  <div className={Item.wrapperClass}>
                    <div className="card_active_wrapper">
                      {Item.Text}
                      <div className="flow_tree_wrapper">{Item.itemList}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="btn_change_plan">
                <Button className="btn btn-primary" onClick={props.close}>
                  Ok
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
