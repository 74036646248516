/*eslint-disable*/
import {
  ActionPanelPopup,
  Button,
  ButtonContainer,
  Header,
  HeaderLeft,
  HeaderRight,
  Input,
  InputContainer,
  InputContent,
  InputWrapper,
  Paragraph,
  PopupContainer,
  PopupWrapper,
  Wrapper,
} from '@/common/styled/Workflow.Dumb';
import { VideoLinkSimple } from '@/Videos/Videos';
import Tooltip from 'react-tooltip-lite';
import React, { useEffect, useRef, useState } from 'react';
import {
  InfoIcon,
  ArrowIcon,
  EditIcon,
  AddIcon,
  MinusIcon,
  NextDetailICon,
} from '@/common/Icons/Icons';
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import {
  ActionsResponseT,
  AuthType,
  InputFieldT,
  InputSchema,
  NodeMode,
  NodeT,
  NormalViewActionNodeLogT,
  PopupError,
  TreeT,
  dictionaryFieldT,
  outputConfig,
} from './types';
import { convertObjectToArray, generateUniqueId } from '@/common/utils/utils';
import {
  automationAPIURLWithWorkspace,
  getJson,
  loginApiURL,
  postJson,
} from '@/common/utils/api-utils';
import produce from 'immer';
import {
  configKinds,
  convertAsaSingleString,
  convertToServerFormat2,
} from './utils';
import { convertToRaw } from 'draft-js';
import { renderNodes } from '../analytics/logs';
import * as R from 'ramda';
import {
  LogsOutputTree,
  exectionStatusColor,
  titleCase,
  toObject,
} from './TestPanel';
import { useDropDown } from './Tree';
import { ActionPanelStateT } from './ActionPanel';
import { Loader } from '@/common/components/Loader';
import { Error } from '@/common/components/ErrorBoundary';
import { CommonInputs, seperateOptionalFields } from './CommonInputs';
const JSONPretty = require('react-json-pretty');

export function WorkflowOutputTest(props: {
  saveNode: (currentNode: ActionsResponseT) => void;
  workspaceName: string;
  automationId: string;
  flowtype: string;
  actionPanelState: ActionPanelStateT;
  tree: TreeT<NodeT>;
  webhookTrigger: boolean;
  eventTrigger: boolean;
  goBack: () => void;
  setActionPanelState: (object: ActionPanelStateT) => void;
  treeData: any;
  state: any;
  fromAction: boolean;
}) {
  const {
    automationId,
    saveNode,
    workspaceName,
    flowtype,
    actionPanelState,
    tree,
    goBack,
    setActionPanelState,
    eventTrigger,
  } = props;
  const [savedInputs, setSavedInputs] = React.useState<outputConfig>({
    type: '',
    output: {},
  });
  const currentNode = actionPanelState.currentNode;
  const timeoutRef = useRef<null | NodeJS.Timeout>(null);
  const intervalRef = useRef<null | NodeJS.Timeout>(null);

  const [config, setConfig] = React.useState<outputConfig>({
    type: '',
    output: {},
  });

  // const [executionData, setExecutionData] = React.useState<any>({});
  const [logs, setLogs] = useState<any>({});

  const [executionState, setExecutionState] = useState({
    loading: false,
    error: false,
  });

  const [toggleState, setToggleState] = useState(true);
  const [loading, setLoading] = useState<"main"|"log"|"">(
    flowtype == 'trigger' && !props.fromAction ? "main" : ""
    // 'log'
  );

  const [showWorkflowInputs, setShowWorkflowInputs] = useState(true);
  const [view, setView] = useState('normalView');

  const [showInputs, makeInputVisible, makeInputHidden] = useDropDown(true);

  const [showOutputs, makeOutputVisible, makeOutputHidden] = useDropDown(true);

  const mappingArray = config.output ? convertObjectToArray(config.output) : [];

  const callApi = async () => {
    getJson(
      automationAPIURLWithWorkspace(
        workspaceName,
        '/automation/' + automationId + '/config'
      )
    )
      .then((configuration) => {
        setConfig(configuration);
        setLoading("");
      })
      .catch((err) => {
        setLoading("");

        setActionPanelState({
          ...actionPanelState,
          mode: {
            ...PopupError,
            error: err.response
              ? err.response.data.error
              : 'Something went wrong!<br /> Please try again afer sometime.',
          },
        });
      });
  };

  const callTriggerApi = async () => {
    const actionInput = currentNode.actionInputsList.output.input.reduce(
      (acc, y) => {
        if (y.config.kind != 'dictionary') {
          const { inputHasValue, value, ...rest } = y;

          const convertedValue = value
            ? typeof value == 'string' || Array.isArray(value)
              ? value
              : convertAsaSingleString(
                  convertToRaw(value.getCurrentContent()).blocks
                )
            : '';
          if (!value || convertedValue.length === 0) {
            return {
              ...acc,
              [`${y.key}`]: '',
            };
          }

          const convertedBlocks =
            typeof value == 'string' || Array.isArray(value)
              ? value
              : convertToRaw(value.getCurrentContent())
                  .blocks.map((b) =>
                    convertToServerFormat2(
                      convertToRaw(value.getCurrentContent()).entityMap,
                      b
                    )
                  )
                  .reduce(
                    (acc, ele, i) =>
                      i == 0 ? (acc += ele) : (acc += '\n' + ele),
                    ''
                  );

          return {
            ...acc,
            [`${y.key}`]: convertedBlocks,
          };
        } else {
          const { inputHasValue, value, ...rest } = y;

          const fields =
            y.config.fields &&
            y.config.fields.filter((f) => {
              const convertedValue =
                typeof f.value == 'string'
                  ? f.value
                  : convertAsaSingleString(
                      convertToRaw(f.value.getCurrentContent()).blocks
                    );

              return f.key.length > 0 && typeof f.value == 'string'
                ? convertedValue.length > 0
                : convertedValue.replace(/\s/g, '').length;
            });

          return {
            ...acc,
            [`${y.key}`]:
              fields &&
              fields.reduce((acc: any, val: dictionaryFieldT) => {
                const valu = val.value;

                const convertedBlocks =
                  typeof valu == 'string'
                    ? valu
                    : convertToRaw(valu.getCurrentContent())
                        .blocks.map((b) =>
                          convertToServerFormat2(
                            convertToRaw(valu.getCurrentContent()).entityMap,
                            b
                          )
                        )
                        .reduce(
                          (acc, ele, i) =>
                            i == 0 ? (acc += ele) : (acc += '\n' + ele),
                          ''
                        );
                return {
                  ...acc,
                  [val.key]: convertedBlocks,
                };
              }, {}),
          };
        }
      },
      {}
    );

    setExecutionState({ ...executionState, loading: true });

    if (!eventTrigger) {
      postJson(
        automationAPIURLWithWorkspace(
          workspaceName,
          '/automation/instance/polling/schema/generate'
        )
      )({
        workflowId: automationId,
        action: currentNode.selectedAction.id,
        actionInput: actionInput,
        workspace: workspaceName,
        authStoreMap: currentNode.selectedConnection.id,
      })
        .then((response) => {
          if (!response.data.error && response.data.output.jobId) {
            intervalRef.current = setInterval(function () {
              callPollingApi(response.data.output.jobId);
            }, 10000);

            timeoutRef.current = setTimeout(() => {
              intervalRef.current && clearInterval(intervalRef.current);
              setExecutionState({
                loading: false,
                error: false,
              });
              setLoading("");
            }, 61000);
          } else {
            setLoading("");

            setActionPanelState({
              ...actionPanelState,
              mode: {
                ...PopupError,
                error:
                  response.data && response.data.error
                    ? response.data.error
                    : 'Something went wrong!<br /> Please try again afer sometime.',
              },
            });
          }
        })
        .catch((err) => {
          setLoading("");

          setActionPanelState({
            ...actionPanelState,
            mode: {
              ...PopupError,
              error: err.response
                ? err.response.data.error
                : 'Something went wrong!<br /> Please try again afer sometime.',
            },
          });
        });
    } else {
      // Promise.resolve({ email: 'selva.g@workativ.com' }).then((res) => {
      getJson(loginApiURL('/user_info')).then((res) => {
        const request = res.email
          ? {
              automation: automationId,
              appId: currentNode.selectedApp.id,
              actionId: currentNode.selectedAction.id,
              input: actionInput,
              authenticationId: currentNode.selectedConnection.id,
              executed_by: res.email,
            }
          : {
              automation: automationId,
              appId: currentNode.selectedApp.id,
              actionId: currentNode.selectedAction.id,
              input: actionInput,
              authenticationId: currentNode.selectedConnection.id,
            };
        postJson(
          automationAPIURLWithWorkspace(
            workspaceName,
            '/automation/trigger/event/schema/run'
          )
        )(request)
          // Promise.resolve({data:{output:{subscriptionId:'8a94546a-ee51-4170-be99-0a9d5871b729'},error:null}})
          .then((response) => {
            // console.log('response ::: 3')
            if (!response.data.error && response.data.output.subscriptionId) {
              intervalRef.current = setInterval(function () {
                callEventApi(response.data.output.subscriptionId);
              }, 10000);

              timeoutRef.current = setTimeout(() => {
                intervalRef.current && clearInterval(intervalRef.current);

                postJson(
                  automationAPIURLWithWorkspace(
                    workspaceName,
                    '/automation/trigger/event/schema/stop'
                  )
                )({
                  subscriptionId: response.data.output.subscriptionId,
                  automationId,
                })
                  .then((_) => {
                    setLoading("");

                    setExecutionState({
                      loading: false,
                      error: false,
                    });
                  })
                  .catch((err) => {
                    setLoading("");

                    setActionPanelState({
                      ...actionPanelState,
                      mode: {
                        ...PopupError,
                        error: err.response
                          ? err.response.data.error
                          : 'Something went wrong!<br /> Please try again afer sometime.',
                      },
                    });
                  });
              }, 61000);
            } else {
              setLoading("");

              setActionPanelState({
                ...actionPanelState,
                mode: {
                  ...PopupError,
                  error:
                    response.data && response.data.error
                      ? response.data.error
                      : 'Something went wrong!<br /> Please try again afer sometime.',
                },
              });
            }
          })
          .catch((err) => {
            // console.log('error ::: 4')
            setLoading("");

            setActionPanelState({
              ...actionPanelState,
              mode: {
                ...PopupError,
                error: err.response
                  ? err.response.data.error
                  : 'Something went wrong!<br /> Please try again afer sometime.',
              },
            });
          });
      });
    }
  };

  const callPollingApi = (jobId: string) => {
    getJson(
      automationAPIURLWithWorkspace(
        workspaceName,
        '/automation/trigger/polling/schema/' + jobId
      )
    )
      .then((response: any) => {
        // console.log('CALLING POLLING API', response);
        // to stop event trigger
        if (
          response.output.logs &&
          Object.keys(response.output.logs).length > 0
        ) {
          setLogs(response);
          setLoading("");

          setExecutionState({ ...executionState, loading: false });

          intervalRef.current && clearInterval(intervalRef.current);

          if (timeoutRef.current !== null) {
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
              timeoutRef.current = null;
            }
          }
        } else {
          setLogs(response);
          setExecutionState({ ...executionState, loading: true, error: false });
        }
      })
      .catch((err) => {
        setActionPanelState({
          ...actionPanelState,
          mode: {
            ...PopupError,
            error: err.response
              ? err.response.data.error
              : 'Something went wrong!<br /> Please try again afer sometime.',
          },
        });
      });
  };

  const callEventApi = (subscriptionId: string) => {
    getJson(
      automationAPIURLWithWorkspace(
        workspaceName,
        '/automation/trigger/event/schema/' + subscriptionId
      )
    )
      .then((response: any) => {
        console.log('CALLING POLLING API', response);
        // to stop event trigger
        if (
          response.output.logs &&
          Object.keys(response.output.logs).length > 0
        ) {
          setLogs(response);
          setLoading("");

          setExecutionState({ ...executionState, loading: false });

          intervalRef.current && clearInterval(intervalRef.current);

          // console.log("timeoutRef.current",timeoutRef.current)
          if (timeoutRef.current !== null) {
            postJson(
              automationAPIURLWithWorkspace(
                workspaceName,
                '/automation/trigger/event/schema/stop'
              )
            )({
              subscriptionId: subscriptionId,
              automationId,
            })
              .then((_) => {
                if (timeoutRef.current) {
                  clearTimeout(timeoutRef.current);
                  timeoutRef.current = null;
                }
              })
              .catch((err) => {
                setLoading("");

                setActionPanelState({
                  ...actionPanelState,
                  mode: {
                    ...PopupError,
                    error: err.response
                      ? err.response.data.error
                      : 'Something went wrong!<br /> Please try again afer sometime.',
                  },
                });
              });
          }
        } else {
          setLogs(response);
          setExecutionState({ ...executionState, loading: true, error: false });
        }
      })
      .catch((err) => {
        setActionPanelState({
          ...actionPanelState,
          mode: {
            ...PopupError,
            error: err.response
              ? err.response.data.error
              : 'Something went wrong!<br /> Please try again afer sometime.',
          },
        });
      });
  };

  const testDynamicOutput = async () => {
    setLoading("log")
    const actionInput = currentNode.workflowInputsListForTest.output.input.reduce(
      (acc, y) => {
        if (y.config.kind != 'dictionary') {
          const { inputHasValue, value, ...rest } = y;

          const convertedValue = value
            ? typeof value == 'string' || Array.isArray(value)
              ? value
              : convertAsaSingleString(
                  convertToRaw(value.getCurrentContent()).blocks
                )
            : '';
          if (!value || convertedValue.length === 0) {
            return {
              ...acc,
              // [`${y.key}`]: '',
            };
          }

          const convertedBlocks =
            typeof value == 'string' || Array.isArray(value)
              ? value
              : convertToRaw(value.getCurrentContent())
                  .blocks.map((b) =>
                    convertToServerFormat2(
                      convertToRaw(value.getCurrentContent()).entityMap,
                      b
                    )
                  )
                  .reduce(
                    (acc, ele, i) =>
                      i == 0 ? (acc += ele) : (acc += '\n' + ele),
                    ''
                  );

          return {
            ...acc,
            [`${y.key}`]: convertedBlocks,
          };
        } else {
          const { inputHasValue, value, ...rest } = y;

          const fields =
            y.config.fields &&
            y.config.fields.filter((f) => {
              const convertedValue =
                typeof f.value == 'string'
                  ? f.value
                  : convertAsaSingleString(
                      convertToRaw(f.value.getCurrentContent()).blocks
                    );

              return f.key.length > 0 && typeof f.value == 'string'
                ? convertedValue.length > 0
                : convertedValue.replace(/\s/g, '').length;
            });

          return {
            ...acc,
            [`${y.key}`]:
              fields &&
              fields.reduce((acc: any, val: dictionaryFieldT) => {
                const valu = val.value;

                const convertedBlocks =
                  typeof valu == 'string'
                    ? valu
                    : convertToRaw(valu.getCurrentContent())
                        .blocks.map((b) =>
                          convertToServerFormat2(
                            convertToRaw(valu.getCurrentContent()).entityMap,
                            b
                          )
                        )
                        .reduce(
                          (acc, ele, i) =>
                            i == 0 ? (acc += ele) : (acc += '\n' + ele),
                          ''
                        );
                return {
                  ...acc,
                  [val.key]: convertedBlocks,
                };
              }, {}),
          };
        }
      },
      {}
    );

    const workflowInput = mappingArray.map((x) => {
      const value = props.webhookTrigger
        ? { [`${x.value.title}`]: x.value.value }
        : { [`${x.name}`]: x.value.value };

      return value;
    });
    const req = {
      authStoreMap: currentNode.selectedConnection.id,
      workflowId: automationId,
      action: currentNode.selectedAction.id,
      actionInput: actionInput,
      workflowInput: {},
    };
    console.log(req, 'reqest');
    postJson(
      automationAPIURLWithWorkspace(
        workspaceName,
        '/automation/instance/schema/generate'
      )
    )(req)
      .then((response) => {

        setLogs(response.data);
        setShowWorkflowInputs(false);
        setSavedInputs(config);
        setLoading("");

      })
      .catch((err) => {
        setLoading("");

        setActionPanelState({
          ...actionPanelState,
          mode: {
            ...PopupError,
            error: err.response
              ? err.response.data.error
              : 'Something went wrong!<br /> Please try again afer sometime.',
          },
        });
      });
  };

  function saveOutput() {
    let id = '';
    const mode = actionPanelState.mode;
    if (mode.mode === NodeMode.Edit) {
      id = flowtype === 'trigger' ? tree.value.id : mode.nodeEditInput.id;
    } else {
      id =
        currentNode.parallelPathNode.currentParallelPathNode != null
          ? currentNode.parallelPathNode.currentParallelPathNode.id.toString()
          : currentNode.errorHandling.errorHandling
          ? currentNode.errorHandling.errorHandlingParent.id
          : generateUniqueId();
    }

    postJson(
      automationAPIURLWithWorkspace(
        workspaceName,
        '/automation/instance/schema/create'
      )
    )({
      schemaId: logs.output.schemaId,
      nodeId: id,
      workflowId: automationId,
    }).then((response) => {
      const newNode = { ...currentNode, actionId: id };

      saveNode(newNode);
    });
  }

  React.useEffect(() => {
    flowtype == 'trigger' && !props.fromAction && callTriggerApi();
    // return () => {
    //     intervalApprovalRef.current && clearInterval(intervalApprovalRef.current)
    // }
  }, []);

  const log =
    logs.output && Object.keys(logs.output.logs).length
      ? (logs.output.logs[`${view}`] as NormalViewActionNodeLogT)
      : ({} as any);

  function eventTriggerLoader() {
    const triggerName = currentNode.selectedAction.name;

    const appName = currentNode.selectedApp.name;

    return (
      <p style={{ marginTop: 10 }}>
        Checking for new <span>{triggerName}</span> in <span>{appName}</span>.
        Please wait a moment.
      </p>
    );
  }

    function disableTest() {
      function hasValue(currentValue: any) {
        // to check whether if any context has been selected for this input then enable save
        if (
          currentValue.config.kind == 'hidden' ||
          (currentValue.config.kind == 'switch' &&
            currentValue.config.id == 'allow_numbering')
        ) {
          return true;
        } else if (
          configKinds.some((value) => value === currentValue.config.kind)
        ) {
          return currentValue.value.length > 0;
        }
        return (currentValue.config.kind == 'dictionary' &&
          currentValue.config.fields.filter(
            (f: any) => f.key.length > 0 && f.value.length > 0
          ).length == currentValue.config.fields.length) ||
          (currentValue.value !== undefined && currentValue.value.length > 0)
          ? true
          : false;
    }

    if (
      currentNode.workflowInputsListForTest &&
      currentNode.workflowInputsListForTest.output
    ) {
      const AllrequiredFieldsHasValue = !seperateOptionalFields(
        currentNode.workflowInputsListForTest.output.input
          ? currentNode.workflowInputsListForTest.output.input
          : [],
        true
      ).every(hasValue);

      return AllrequiredFieldsHasValue;
    }

    return false;
  }

  if (loading=='main') {
    return (
      <Wrapper>
        <ActionPanelPopup>
          <PopupWrapper>
            <PopupContainer>
              <div
                className={
                  flowtype == 'trigger' && executionState.loading
                    ? 'popup_inside_loader connect_popup_trigger'
                    : 'popup_inside_loader'
                }
                style={
                  flowtype == 'trigger' && executionState.loading
                    ? { flexDirection: 'column' }
                    : {}
                }
              >
                <Loader.PopupLoader show={true} />
                {flowtype == 'trigger' &&
                  executionState.loading &&
                  eventTriggerLoader()}
              </div>
            </PopupContainer>
          </PopupWrapper>
        </ActionPanelPopup>
      </Wrapper>
    );
  } else if (actionPanelState.mode.mode === NodeMode.Error) {
    return (
      <Wrapper>
        <ActionPanelPopup>
          <PopupWrapper>
            <PopupContainer>
              <div className="flow_canvas_popup_error">
                <Error.AddorEdit
                  info={'actionPanelState.mode.error'}
                  onClick={() => {
                    goBack();
                  }}
                  buttonName={'Okay'}
                />
              </div>
            </PopupContainer>
          </PopupWrapper>
        </ActionPanelPopup>
      </Wrapper>
    );
  } else if (
    flowtype === 'trigger' &&
    !props.fromAction &&
    Object.keys(log).length == 0
  ) {
    const triggerName = currentNode.selectedAction.name;

    return (
      <Wrapper>
        <ActionPanelPopup>
          <PopupWrapper>
            <PopupContainer>
              <div className="automation_canvas_right_popup automate_product">
                <div className="automation_canvas_right_popup_form">
                  <div className="automation_rightside_form automation_rightside_step_two edit_action_height">
                    <div className="automation_form_header">
                      <Header>
                        <HeaderLeft>
                          <SmallButton
                            style={{ marginRight: 10 }}
                            onClick={() => goBack()}
                          >
                            <span style={{ transform: 'rotate(180deg)' }}>
                              <ArrowIcon />
                            </span>
                            <label>Back</label>
                          </SmallButton>
                          Test workflow
                          <Tooltip
                            className="target customTip"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={'test'}
                            distance={5}
                          >
                            <InfoIcon />
                          </Tooltip>
                        </HeaderLeft>
                        <HeaderRight className="header_right_popup">
                          <VideoLinkSimple
                            id={'SET_CONDITION_IN_APP_WORKFLOW'}
                            text={'How to test'}
                          />
                        </HeaderRight>
                      </Header>
                      <Paragraph
                        style={{ marginLeft: 76, padding: '0 5px 0 2px' }}
                      >
                        Test your worflow instantly.
                      </Paragraph>
                    </div>

                    <div className="popup_inside_loader">
                      <p>
                        No new <span>{triggerName}</span>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </PopupContainer>
          </PopupWrapper>
        </ActionPanelPopup>
      </Wrapper>
    );
  }

  return (
    <>
      <Wrapper>
        <ActionPanelPopup>
          <PopupWrapper className="connect_popupwrapper" style={{ overflowY: 'scroll' }}>
            <PopupContainer>
              <div className="automation_canvas_right_popup automate_product">
                <div className="automation_canvas_right_popup_form">
                  <div className="automation_rightside_form automation_rightside_step_two edit_action_height connect_popup">
                    <div className="automation_form_header">
                      <Header style={{ marginBottom: 8 }}>
                        <HeaderLeft>
                          <SmallButton
                            style={{ marginRight: 10 }}
                            onClick={() => goBack()}
                          >
                            <span style={{ transform: 'rotate(180deg)' }}>
                              <ArrowIcon />
                            </span>
                            <label>Back</label>
                          </SmallButton>
                          <img
                            className="header_img"
                            src={currentNode.selectedApp.icon}
                          />
                          {currentNode.selectedAction.name}
                          {/* <Tooltip
                            className="target customTip"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={'test'}
                            distance={5}
                          >
                            <InfoIcon />
                          </Tooltip> */}
                        </HeaderLeft>
                        <HeaderRight className="header_right_popup">
                          <VideoLinkSimple
                            id={'SET_CONDITION_IN_APP_WORKFLOW'}
                            text={'How to test'}
                          />
                        </HeaderRight>
                      </Header>
                      {/* <Paragraph
                        style={{ marginLeft: 76, padding: '0 5px 0 2px' }}
                      >
                        Test your worflow instantly.
                      </Paragraph> */}
                    </div>
                    {props.fromAction && (
                      <>
                        {!showWorkflowInputs ? (
                          <div className="connect_popup_edit analytics_popup_body_plus">
                            <h4>Action inputs</h4>
                            <div>
                              <span
                                onClick={() => setShowWorkflowInputs(true)}
                                style={{ width: 18, display: 'block' }}
                              >
                                <AddIcon />{' '}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            {/* <div className="connect_popup_header  analytics_popup_body_plus">
                            <div>
                              <h4>Action inputs</h4>
                              <p>Fill required inputs to execute this action.</p>
                            </div>
                            <div>
                              <span
                                className="connect_popup_minusIcon"
                                onClick={() => setShowWorkflowInputs(false)}
                                style={{ width:20, display:"block"}}
                              >
                                <MinusIcon />
                              </span>
                            </div>
                          </div> */}
                            <CommonInputs
                              state={actionPanelState}
                              setContextPopupValues={() => {}}
                              contextPopupValues={{}}
                              setPanelState={(state) =>
                                setActionPanelState(state as ActionPanelStateT)
                              }
                              title={'Action inputs'}
                              description="Fill required inputs to execute this action"
                              inputsList={
                                currentNode.workflowInputsListForTest &&
                                currentNode.workflowInputsListForTest.output
                                  ? currentNode.workflowInputsListForTest.output
                                      .input
                                  : []
                              }
                              workspacename={props.workspaceName}
                              updateInputValuesWithContext={() => {}}
                              forTestAction
                              // {...rest}
                              showFoldIcon
                              foldInputsView={() => {
                                setShowWorkflowInputs(false);
                              }}
                            />
                          </>
                        )}
                      </>
                    )}
                    {/* {flowtype != 'trigger' && (
                      <>
                        {!showWorkflowInputs ? (
                          <div className="connect_popup_edit">
                            <h5>Workflow inputs</h5>
                            <div>
                              <span onClick={() => setShowWorkflowInputs(true)}>
                                <AddIcon />{' '}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <InputWrapper>
                            <div className="connect_popup_header">
                              <div>
                                <h4>Workflow inputs</h4>
                                <p>Provide input to test the workflow</p>
                              </div>
                              <div>
                                <span
                                  className="connect_popup_minusIcon"
                                  onClick={() => setShowWorkflowInputs(false)}
                                >
                                  <MinusIcon />
                                </span>
                              </div>
                            </div>

                            {mappingArray.map((input, i) => (
                              <InputContainer>
                                <Input
                                  type="text"
                                  onChange={(e: any) => {
                                    const newValue = produce(
                                      config,
                                      (draftState) => {
                                        draftState.output[input.name].value =
                                          e.target.value;
                                      }
                                    );
                                    setConfig(newValue);
                                    e.stopPropagation();
                                  }}
                                  onClick={(event: any) => {
                                    event.stopPropagation();
                                  }}
                                  value={config.output[input.name].value}
                                />
                                <InputContent>{`Enter ${input.value.title}`}</InputContent>
                              </InputContainer>
                            ))}
                          </InputWrapper>
                        )}
                      </>
                    )} */}
                  </div>
                  {loading == "log" ? (
                    <div className="log_loader">
                        <Loader.PopupLoader show={true} />
                    </div>
                  ) : (
                    logs.output && (
                      <>
                        <InputWrapper className="connect_popup_status_box">
                          <div className="connect_popup_status">
                            <p>Duration</p>
                            <h6>{logs.output.logs.executionTime}</h6>
                          </div>
                          <div className="connect_popup_status">
                            <p>Status</p>
                            <h6
                              style={{
                                color: exectionStatusColor(
                                  log.node_execution_status
                                ),
                              }}
                            >
                              {titleCase(log.node_execution_status)}
                            </h6>
                          </div>
                          <div className="connect_popup_status">
                            <p>Error</p>
                            <h6>
                              {log.node_execution_status === 'failed'
                                ? '1 Error'
                                : 'No Error'}
                            </h6>
                          </div>
                        </InputWrapper>
                        <InputWrapper style={{ padding: '16px 0' }}>
                          <div className="automation_logs_radio">
                            <div className="radio">
                              <label>
                                <input
                                  type="radio"
                                  className="radio-warning"
                                  checked={view === 'normalView'}
                                  onClick={() => setView('normalView')}
                                  onChange={() => {}}
                                />
                              </label>
                              <span>Normal View</span>
                            </div>
                            <div className="radio">
                              <label>
                                <input
                                  type="radio"
                                  checked={view === 'developerView'}
                                  onClick={() => setView('developerView')}
                                  onChange={() => {}}
                                />
                              </label>
                              <span>Developer View</span>
                            </div>
                          </div>
                        </InputWrapper>
                        <div className="connect_popup_result">
                          <div className="analytics_popup automation_logs_output_popup">
                            <div className="analytics_popup_body">
                              {/* <div
                                className={'analytics_popup_body_minus'}
                                style={{ margin: 0 }}
                              >
                                <div className="analytics_popup_body_minus_header">
                                  <div className="analytics_popup_body_minus_header_container">
                                    <div className="analytics_popup_body_minus_header_content">
                                      <h4>
                                        <img
                                          src={currentNode.selectedApp.icon}
                                          style={{
                                            width: '32px',
                                            height: '32px',
                                            marginRight: '8px',
                                          }}
                                        />
                                        {currentNode.selectedAction.name}
                                      </h4>
                                    </div>
                                  </div>

                                  {/* {log.node_execution_status == 'skipped' && <p className="skipped_text">(Skipped)</p>} */}
                                  {/* <span
                                    onClick={() => {
                                      setToggleState(!toggleState);
                                    }}
                                  >
                                    {!toggleState ? <AddIcon /> : <MinusIcon />}
                                  </span>
                                </div> */}
                              {/* </div> */}
                              {toggleState && (
                                <React.Fragment>
                                  {log.node_meta &&
                                    log.node_meta.action.auth_type !=
                                      AuthType.NoAuth && (
                                      <p>
                                        Authenticated with{' '}
                                        <span>
                                          {log.node_meta.authentication.name}
                                        </span>
                                      </p>
                                    )}
                                  <div className="connect_popup_output_body">
                                    <div className="analytics_popup_body_minus_footer analytics_popup_body_minus_body">
                                      <h4
                                        onClick={() => {
                                          showInputs
                                            ? makeInputHidden()
                                            : makeInputVisible();
                                        }}
                                      >
                                        Workflow inputs:{' '}
                                        <span
                                          className={
                                            showInputs
                                              ? 'dialog_context_tree_head_right_svg'
                                              : 'dialog_context_tree_head_right_svg collapsed'
                                          }
                                          onClick={() => {
                                            showInputs
                                              ? makeInputHidden()
                                              : makeInputVisible();
                                          }}
                                        >
                                          <NextDetailICon />
                                        </span>
                                      </h4>
                                      {showInputs && log.input_schema && (
                                        <>
                                          {view === 'normalView' ? (
                                            Array.isArray(log.input_schema) ? (
                                              <>
                                                {log.input_schema.length > 0 ? (
                                                  log.input_schema.map(
                                                    (
                                                      schema: InputSchema,
                                                      i: number
                                                    ) => {
                                                      if (
                                                        schema.config.kind ===
                                                        'dictionary'
                                                      ) {
                                                        return (
                                                          <React.Fragment>
                                                            <h6 key={i}>
                                                              {/* {i + 1}. {schema.displayTitle}:{' '} */}
                                                              {
                                                                schema.displayTitle
                                                              }
                                                              :{' '}
                                                              {Object.entries(
                                                                (schema.value as any) as Object
                                                              ).map(
                                                                (
                                                                  [key, value],
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <h6
                                                                      key={i}
                                                                      style={{
                                                                        paddingTop:
                                                                          '5px',
                                                                      }}
                                                                    >
                                                                      {/* {index + 1}. {key}:{' '} */}
                                                                      {key}:{' '}
                                                                      <span>
                                                                        {value}
                                                                      </span>
                                                                    </h6>
                                                                  );
                                                                }
                                                              )}
                                                            </h6>
                                                          </React.Fragment>
                                                        );
                                                      }
                                                      return (
                                                        <h6 key={i}>
                                                          {/* {i + 1}. {schema.displayTitle}:{' '} */}
                                                          {schema.displayTitle}:{' '}
                                                          <span>
                                                            {schema.value}
                                                          </span>
                                                        </h6>
                                                      );
                                                    }
                                                  )
                                                ) : (
                                                  <h6>No input found</h6>
                                                )}
                                              </>
                                            ) : (
                                              Object.entries(
                                                log.input_schema
                                              ).map((schema: any, i) =>
                                                typeof schema[1] == 'object' &&
                                                (schema[1] != null ||
                                                  schema[1] != undefined) ? (
                                                  <>
                                                    <h4>
                                                      {i + 1}. {schema[0]}:
                                                    </h4>
                                                    {Object.entries(
                                                      schema[1]
                                                    ).map((sch: any, j) => (
                                                      <h6 key={j}>
                                                        {j + 1}. {sch[0]}:{' '}
                                                        <span>
                                                          {R.isNil(sch[1])
                                                            ? ''
                                                            : sch[i]}
                                                        </span>
                                                      </h6>
                                                    ))}
                                                  </>
                                                ) : (
                                                  <h6 key={i}>
                                                    {i + 1}. {schema[0]}:{' '}
                                                    <span>{schema[1]}</span>
                                                  </h6>
                                                )
                                              )
                                            )
                                          ) : (
                                            <JSONPretty
                                              data={log.input_schema}
                                            />
                                          )}
                                        </>
                                      )}
                                    </div>
                                    <div className="automation_logs_collapse_open_content">
                                      <div className="automation_logs_collapse_open_content_scroll">
                                        <h5
                                          onClick={() => {
                                            showOutputs
                                              ? makeOutputHidden()
                                              : makeOutputVisible();
                                          }}
                                        >
                                          Output received
                                          <span
                                            className={
                                              showOutputs
                                                ? 'dialog_context_tree_head_right_svg'
                                                : 'dialog_context_tree_head_right_svg collapsed'
                                            }
                                            onClick={() => {
                                              showOutputs
                                                ? makeOutputHidden()
                                                : makeOutputVisible();
                                            }}
                                          >
                                            <NextDetailICon />
                                          </span>
                                        </h5>
                                        {showOutputs && (
                                          <div className="trigger_tree_wrapper">
                                            <LogsOutputTree
                                              executionSuccess={
                                                log.node_execution_status ===
                                                'success'
                                              }
                                              output={log.output_schema}
                                              developerView={
                                                view === 'developerView'
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>{' '}
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  )}
                </div>
              </div>
            </PopupContainer>
            <ButtonContainer className="footer_button_fixed">
              <Button
                className="automation_condition_button_btn cancel_btn"
                onClick={() => goBack()}
              >
                Cancel
              </Button>
              <Button
                className={
                  mappingArray.every(
                    (s) => s.value.value && s.value.value.length > 0
                  ) && !disableTest()
                    ? 'automation_condition_button_btn'
                    : 'automation_condition_button_btn editor_btn_disabled'
                }
                onClick={
                  logs.output &&
                  logs.output.schemaId &&
                  logs.output.schemaId.length > 0 &&
                  R.equals(savedInputs, config) &&
                  log.node_execution_status == 'success'
                    ? saveOutput
                    : testDynamicOutput
                }
              >
                {logs.output &&
                logs.output.schemaId &&
                logs.output.schemaId.length > 0 &&
                R.equals(savedInputs, config) &&
                log.node_execution_status == 'success'
                  ? 'Save'
                  : 'Test'}
              </Button>
            </ButtonContainer>
          </PopupWrapper>
        </ActionPanelPopup>
      </Wrapper>
    </>
  );
}
