import { AxiosResponse } from 'axios'
import { EditorState, RawDraftEntityRange, convertFromRaw, convertToRaw } from 'draft-js'
import produce from 'immer'
/*eslint-disable*/
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import { useJourney } from '@/Journey/JourneyContext'
import { VideoLink } from '@/Videos/Videos'
import { DropdownWithCircle, EyeCloseIcon, EyeOpenIcon, InfoIcon } from '@/common/Icons/Icons'
import { LoadingSvgImage } from '@/common/Icons/LoadingSvgImage'
import {
    ErrorHandlingSuccessIcon,
    GreenTickIcon,
    NoTriggerVariable,
    WarningAlertIcon
} from '@/common/Icons/Workflow.Icons'
import { Error } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
import { useGenericCachedRun } from '@/common/hooks/useCachedQuery'
import {
    ActionPanelPopup,
    Wrapper as ActionWrapper,
    Button,
    ButtonContainer,
    EmptyDiv,
    Header,
    Input,
    InputContainer,
    InputContent,
    InputWrapper,
    Paragraph,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper,
    CancelButton
} from '@/common/styled/Workflow.Dumb'

import {
    AutomationOnPremApi,
    automationAPIURLWithWorkspace,
    getJson,
    postJson,
    putJson,
    wrapperGetJson,
    logEvent
} from '@/common/utils/api-utils'

import { getProductId } from '@/common/utils/utils'
import { onPremConnectionListResponseT, onPremListResponseT } from '../onPremTypes'
import { SidePanelContext } from './Canvas'
import { CanvasRightPanel } from './CanvasRightPanel'
import { CommonInputs } from './CommonInputs'
import { ContextPopup, configApiCall } from './ContextPopup'
import { decorator } from './InputandVariable'
import { fetchAutomations, wrapPromise } from './nonCanvasActions'
import {
    ActionConnectionsT,
    ActionInitialState,
    ActionInputsListT,
    ActionsResponseT,
    ApiTokenValue,
    ApitokenT,
    AppTriggerResponseT,
    AppsListT,
    AuthType,
    BasicT,
    BasicValue,
    ConnectionTypeT,
    ContextPopupStateT,
    CustomAuthT,
    CustomAuthValue,
    CustomOAuthT,
    CustomOAuthValue,
    DefaultSelectedConnection,
    InputFieldT,
    NewConnection,
    NewConnectionT,
    NewConnectionTypeT,
    NodeAddT,
    NodeEditT,
    NodeMode,
    NodeModes,
    NodeT,
    NodeView,
    OatuhTokenValue,
    OauthtokenT,
    OldConnectionT,
    OnPremAuthT,
    OnpremAuthValue,
    PopupError,
    ResponseOutput,
    RightPanelStateContext,
    Tree,
    TreeT,
    addErrorHandlingNodes,
    dictionaryFieldT,
    emptyInputSchema,
    oldConnection,
    updateNode,
    // PrivateOAuthT
} from './types'
import {
    InsertContext,
    configKinds,
    converToEditorFormat,
    convertActionObjectToArray,
    convertAsaSingleString,
    convertToServerFormat2,
    emptyEditorState,
    grouping,
    sortAnArrayAlphabetically
} from './utils'
import { WorkflowOutputTest } from './ConnectPopup'

const ramda = require('ramda')

// import { useIsFetching } from 'react-query'

export const CancelButtonInput = styled.div`
    width: 16px;
    height: 16px;
    display: flex;
    right: 12px;
    left: inherit;
    position: absolute;
    top: 16px;
    transform: ${props => (props.rotate ? 'rotate(90deg)' : 'inherit')};
    svg {
        width: 16px;
        height: 16px;
        color: #555555;
        fill: #555555;
    }
`

export const OnPremCancelButtonInput = styled.div`
    width: 16px;
    height: 16px;
    display: flex;
    right: 12px;
    left: inherit;
    position: absolute;
    transform: ${props => (props.rotate ? 'rotate(90deg)' : 'inherit')};
    margin-top: 15px;
    cursor: pointer;
    svg {
        width: 16px;
        height: 16px;
        color: #555555;
        fill: #555555;
    }
`

export type ActionPanelStateT = {
    show: boolean
    parentNode: TreeT<NodeT>
    mode: NodeModes
    currentNode: ActionsResponseT
}

export type ActionPanelStateContextT = {
    actionPanelState: ActionPanelStateT
    setActionPanelState: (object: any) => void
    setContextPopupValues: (object: any) => void
    contextPopupValues: ContextPopupStateT
    userSwitchedApp: boolean
    setUserSwitchedApp: (switched: boolean) => void
}

export const ActionPanelStateContext = createContext<ActionPanelStateContextT>({
    actionPanelState: {
        show: false,
        mode: NodeAddT,
        parentNode: { value: Tree, children: [] },
        currentNode: ActionInitialState
    },
    setActionPanelState: (object: any) => undefined,
    setContextPopupValues: (object: any) => undefined,
    contextPopupValues: {
        show: false,
        inputWithValues: [],
        currentInput: '',
        isMultiple: { path: '', parentKey: '' },
        collectionPicker: {
            isCollectionPicker: false,
            collectionType: emptyInputSchema,
            selectedCollection: {
                name: '',
                value: {
                    $id: '',
                    type: 'collection',
                    item: {
                        type: 'object',
                        properties: {}
                    },
                    title: '',
                    properties: {}
                }
            },
            app: ResponseOutput
        }
    },
    userSwitchedApp: false,
    setUserSwitchedApp: (switched: boolean) => undefined
})

export const ActionPanel = (props: any) => {
    const { rightPanelState } = useContext(SidePanelContext)

    const [actionPanelState, setActionPanelState] = useState({
        ...rightPanelState,
        currentNode: rightPanelState.currentNode as ActionsResponseT
    })

    const [contextPopupValues, setContextPopupValues] = useState<ContextPopupStateT>({
        show: false,
        inputWithValues: [],
        currentInput: '',
        isMultiple: { path: '', parentKey: '' },
        collectionPicker: {
            isCollectionPicker: false,
            collectionType: emptyInputSchema,
            selectedCollection: {
                name: '',
                value: {
                    $id: '',
                    type: 'collection',
                    item: {
                        type: 'object',
                        properties: {}
                    },
                    title: '',
                    properties: {}
                }
            },
            app: ResponseOutput
        }
    })

    const [userSwitchedApp, setUserSwitchedApp] = useState(false)

    return (
        <ActionPanelStateContext.Provider
            value={{
                actionPanelState,
                setActionPanelState,
                contextPopupValues,
                setContextPopupValues,
                userSwitchedApp,
                setUserSwitchedApp
            }}
        >
            <ActionActualComponent {...props} />
        </ActionPanelStateContext.Provider>
    )
}

const ActionActualComponent = (props: any) => {
    // for comparing props
    const ActionElement = useRef<any>(null)
    const journeyData = useJourney()

    const workspaceName = props.match.params.workspacename

    // const [unfiltertedAppListCache, isFetching] = useActionsCached(props.match.params.workspacename)

    const actionFetcher = useGenericCachedRun<AppsListT>('actions', () =>
        getJson(automationAPIURLWithWorkspace(workspaceName, '/actions'))
    )

    const {
        rightPanelState,
        setRightPanelState,
        saveTree,
        treeData,
        setEditTreeData,
        editTreeData,
        active_automation,
        hasLinkedDialogs,
        automationMeta
    } = useContext(SidePanelContext)

    const {
        actionPanelState,
        setActionPanelState,
        contextPopupValues,
        setContextPopupValues,
        userSwitchedApp,
        setUserSwitchedApp
    } = useContext(ActionPanelStateContext)

    const isAssitant = getProductId() === 'ASSISTANT'

    const currentNode = actionPanelState.currentNode as ActionsResponseT

    const [loading, setLoading] = useState(false)

    const [showOutputTest, setShowOutputTest] = useState(false)

    const [customAuthState, setCustomAuthState] = useState<{ loading: boolean; error: false | string }>({
        loading: false,
        error: false
    })

    const [previousMode, setPreviousMode] = useState<NodeModes>(NodeView)

    const [backupTreeForErrorHandling, setBackupTreeForErrorHandling] = useState<{ tree: undefined | TreeT<NodeT> }>({
        tree: undefined
    })

    useEffect(() => {
        const currentMode = rightPanelState.mode

        // compare props and update changes based on following condition
        if (
            !ActionElement.current ||
            (ActionElement.current &&
                (rightPanelState.mode.mode !== ActionElement.current.mode.mode ||
                    (currentMode.mode === NodeMode.Edit &&
                        currentMode.nodeEditInput.id !== ActionElement.current.mode.nodeEditInput.id)))
        ) {
            if (currentMode.mode === NodeMode.Add || currentMode.mode === NodeMode.Edit) {
                // next time to compare with previous props
                ActionElement.current = rightPanelState

                //show loader while changing modes (such as edit->add or add->edit)
                setLoading(true)

                // update current action panel context state for further child usage
                setActionPanelState({
                    ...rightPanelState,
                    currentNode: rightPanelState.currentNode as ActionsResponseT
                })

                // call initial API everytime mode changed
                callApplistAPI({
                    ...rightPanelState,
                    currentNode:
                        mode.mode == NodeMode.Edit
                            ? ({
                                  ...rightPanelState.currentNode,
                                  subflowMetaData:
                                      mode.nodeEditInput.meta_data && mode.nodeEditInput.meta_data.subflow
                                          ? { subflow: mode.nodeEditInput.meta_data.subflow }
                                          : { subflow: { hasSubFlow: false } }
                              } as ActionsResponseT)
                            : (rightPanelState.currentNode as ActionsResponseT)
                })

                // to set switching app false when changing edit nodes, otherwise it will leads to some issue wherever userSwitchedApp value is used
                setUserSwitchedApp(false)
            } else {
                ActionElement.current = rightPanelState
                currentMode.mode === NodeMode.Loading ? setLoading(true) : setLoading(false)

                setActionPanelState({
                    ...rightPanelState,
                    currentNode: rightPanelState.currentNode as ActionsResponseT
                })
            }
        } else if (
            rightPanelState.mode.mode === NodeMode.Edit &&
            ActionElement.current.mode.mode === NodeMode.Edit &&
            rightPanelState.mode.nodeEditInput.id === ActionElement.current.mode.nodeEditInput.id
        ) {
            setActionPanelState({
                ...actionPanelState,
                show: true
            })
        }

        // to use this when clicking error ok button
        setPreviousMode(rightPanelState.mode)
    }, [rightPanelState.mode])

    // to change ActionElement.current values while updating state in each step
    useEffect(() => {
        ActionElement.current = actionPanelState

        // to see the details in left side panel while adding or editing
        setRightPanelState({ ...rightPanelState, currentNode: actionPanelState.currentNode })
    }, [actionPanelState.currentNode])

    // called when the popup mode is add and after action selected
    useEffect(() => {
        ;(actionPanelState.mode.mode !== NodeMode.Edit || userSwitchedApp) &&
            currentNode.selectedAction.id &&
            currentNode.selectedAction.id.length > 0 &&
            getConnectionsList(currentNode.selectedApp.id, actionPanelState)
    }, [currentNode.selectedAction.id])

    // called when the popup mode is add and after connection selected
    useEffect(() => {
        ;(actionPanelState.mode.mode !== NodeMode.Edit || userSwitchedApp) &&
            currentNode.selectedConnection.type === ConnectionTypeT.old &&
            currentNode.selectedConnection.id.length > 0 &&
            getConfigDetails(currentNode.selectedAction.id, actionPanelState)
    }, [currentNode.selectedConnection.id])

    // called when the popup mode is add and after app has selected
    useEffect(() => {
        ;(actionPanelState.mode.mode !== NodeMode.Edit || userSwitchedApp) &&
            currentNode.selectedApp.id.length > 0 &&
            (currentNode.selectedApp.type == AuthType.Custom || currentNode.selectedApp.type == AuthType.CustomOauth || currentNode.selectedApp.type == AuthType.PrivateOauth) &&
            getCustomAuthenticationType(currentNode.selectedApp.id, actionPanelState)
    }, [currentNode.selectedApp.id])

    // -------------- Api call --------------
    async function callApplistAPI(state: ActionPanelStateT) {
        // const workspaceName = props.match.params.workspacename

        // await getJson(automationAPIURLWithWorkspace(workspaceName, '/actions'))
        // .then((unfiltertedAppList: AppsListT)
        // console.log("unfiltertedAppListCache",unfiltertedAppListCache)

        await actionFetcher()
            .then((unfiltertedAppList: AppsListT) => {
                console.log('got actions')

                const actions = unfiltertedAppList.output
                const actionsLens = ramda.lensProp('output')
                const data = ramda.set(actionsLens, actions, unfiltertedAppList) as AppsListT

                if (state.mode.mode === NodeMode.Edit) {
                    const currentNode = state.currentNode as ActionsResponseT

                    const mode = state.mode as NodeEditT

                    const selectedAction = data.output.filter(x => x.id === mode.nodeEditInput.meta_data.action_id)

                    const nextState = produce(currentNode, draftState => {
                        draftState.appsList = data
                        draftState.selectedAction = {
                            name: selectedAction[0].action,
                            id: selectedAction[0].id,
                            description: mode.nodeEditInput.description
                        }
                        draftState.selectedApp = {
                            name: selectedAction[0].appName,
                            id: selectedAction[0].appId,
                            type: selectedAction[0].authentication_type,
                            icon: selectedAction[0].icon,
                            version: selectedAction[0].version
                        }

                        draftState.errorHandling = {
                            errorHandling: mode.nodeEditInput.meta_data.continueIfError && true,
                            errorHandlingParent: mode.nodeEditInput,
                            errors: [
                                ...(mode.nodeEditInput.meta_data && mode.nodeEditInput.meta_data.errors
                                    ? mode.nodeEditInput.meta_data.errors
                                    : ActionInitialState.errorHandling.errors)
                            ],
                            errorHandlingChildrenForAddMode: []
                        }
                    })

                    setActionPanelState({
                        ...state,
                        currentNode: nextState
                    })
                    getConnectionsList(selectedAction[0].appId, {
                        ...state,
                        currentNode: nextState
                    })
                } else {
                    const currentNode = state.currentNode as ActionsResponseT

                    const nextState = produce(currentNode, draftState => {
                        draftState.appsList = {
                            ...data
                        }
                    })

                    setActionPanelState({
                        ...state,
                        currentNode: nextState
                    })

                    setLoading(false)
                }
            })
            .catch((error: any) => {
                let err = error as any
                setLoading(false)

                setActionPanelState({
                    ...state,
                    mode: {
                        ...PopupError,
                        error: err.response
                            ? err.response.data.error
                            : 'Something went wrong!<br /> Please try again afer sometime.'
                    }
                })
            })
    }

    // -------------- Api call --------------
    async function getConnectionsList(appId: string, state: ActionPanelStateT) {
        const workspaceName = props.match.params.workspacename

        if (state.currentNode.selectedApp.type == 'on_prem') {
            wrapperGetJson(automationAPIURLWithWorkspace(workspaceName, `${AutomationOnPremApi.GetAll}`))
                .then(response => response.data)
                .then((response: { type: string; output: onPremListResponseT[] }) => {
                    if (!userSwitchedApp && state.mode.mode === NodeMode.Edit) {
                        const currentNode = state.currentNode as ActionsResponseT

                        const mode = state.mode as NodeEditT

                        const filteredAgent = response.output.filter(
                            agent => agent.id == mode.nodeEditInput.meta_data.agent_id
                        )[0]

                        if (!filteredAgent || (filteredAgent && filteredAgent.connections.length == 0)) {
                            const nextState = produce(currentNode, draftState => {
                                let connection = currentNode.selectedConnection

                                const connDetails = OnpremAuthValue as OnPremAuthT

                                connection.type = ConnectionTypeT.new

                                connection.connectionsDetails = {
                                    ...connDetails,
                                    type: AuthType.OnPrem,
                                    selectedAgent: filteredAgent ? filteredAgent.name : 'Select',
                                    value: {
                                        ...connDetails.value,
                                        type: AuthType.OnPrem,
                                        meta_data: { agent: filteredAgent ? filteredAgent.id : '', connection: '' }
                                    },
                                    agents: response.output,
                                    connections: []
                                }

                                connection = {
                                    ...connection,
                                    hasError: null,
                                    selectedConnection: 'Add a new Account',
                                    id: '',
                                    valid: null
                                }

                                draftState.selectedConnection = connection
                            })

                            setActionPanelState({
                                ...state,
                                currentNode: nextState
                            })

                            getConfigDetails(state.currentNode.selectedAction.id, {
                                ...state,
                                currentNode: nextState
                            })
                        } else {
                            getAgentConnectionsList(mode.nodeEditInput.meta_data.agent_id, workspaceName)
                                .then(
                                    (agentConnections: {
                                        output: onPremConnectionListResponseT[]
                                        type: 'success'
                                    }) => {
                                        const nextState = produce(currentNode, draftState => {
                                            let connection = currentNode.selectedConnection

                                            if (mode.nodeEditInput.meta_data.authentication_id.length > 0) {
                                                const connDetails = OnpremAuthValue as OnPremAuthT

                                                const filteredConnection = agentConnections.output.filter(
                                                    connection =>
                                                        connection.id == mode.nodeEditInput.meta_data.authentication_id
                                                )[0]

                                                connection.valid = true
                                                connection.type = ConnectionTypeT.old
                                                connection.id = mode.nodeEditInput.meta_data.authentication_id
                                                connection.selectedConnection = filteredConnection
                                                    ? filteredConnection.name
                                                    : ''

                                                connection.connectionsDetails = {
                                                    ...connDetails,
                                                    type: AuthType.OnPrem,
                                                    selectedAgent: filteredAgent ? filteredAgent.name : '',
                                                    value: {
                                                        appId: currentNode.selectedApp.id,
                                                        name:  filteredConnection? filteredConnection.name : '',
                                                        type: AuthType.OnPrem,
                                                        workspace: workspaceName,
                                                        meta_data: {
                                                            agent: filteredAgent.id,
                                                            connection: filteredConnection ? filteredConnection.id : ""
                                                        }
                                                    },
                                                    agents: response.output,
                                                    connections: agentConnections.output
                                                }

                                                connection = {
                                                    ...connection,
                                                    hasError: null,
                                                    valid: filteredConnection ? connection.valid : null,
                                                    id:filteredConnection ? connection.id : ""
                                                }
                                                draftState.selectedConnection = connection
                                            } else {
                                                const currentNode = state.currentNode as ActionsResponseT

                                                const nextState = produce(currentNode, draftState => {
                                                    let connection = currentNode.selectedConnection

                                                    const connDetails = OnpremAuthValue as OnPremAuthT

                                                    connection.connectionsDetails = {
                                                        ...connDetails,
                                                        agents: response.output
                                                    }

                                                    connection = {
                                                        ...connection,
                                                        hasError: null,
                                                        valid: null
                                                    }

                                                    draftState.selectedConnection = connection
                                                })

                                                setActionPanelState({
                                                    ...state,
                                                    currentNode: nextState
                                                })
                                            }
                                        })

                                        setActionPanelState({
                                            ...state,
                                            currentNode: nextState
                                        })

                                        getConfigDetails(state.currentNode.selectedAction.id, {
                                            ...state,
                                            currentNode: nextState
                                        })
                                    }
                                )
                                .catch(err => {
                                    setLoading(false)

                                    setActionPanelState({
                                        ...state,
                                        mode: {
                                            ...PopupError,
                                            error: err.response
                                                ? err.response.data.error
                                                : 'Something went wrong!<br /> Please try again afer sometime.'
                                        }
                                    })
                                })
                        }
                    } else {
                        const currentNode = state.currentNode as ActionsResponseT

                        const nextState = produce(currentNode, draftState => {
                            let connection = currentNode.selectedConnection

                            const connDetails = OnpremAuthValue as OnPremAuthT

                            connection.connectionsDetails = { ...connDetails, agents: response.output }

                            connection = {
                                ...connection,
                                hasError: null,
                                valid: null
                            }

                            draftState.selectedConnection = connection
                        })

                        setActionPanelState({
                            ...state,
                            currentNode: nextState
                        })
                    }
                })
                .catch(err => {
                    setLoading(false)

                    setActionPanelState({
                        ...state,
                        mode: {
                            ...PopupError,
                            error: err.response
                                ? err.response.data.error
                                : 'Something went wrong!<br /> Please try again afer sometime.'
                        }
                    })
                })
        } else {
            await postJson(automationAPIURLWithWorkspace(workspaceName, `/connection/fetch`))({
                appId,
                auth_type: state.currentNode.selectedApp.type
            })
                .then(resp => {
                    const response = resp.data as ActionConnectionsT
                    if (state.mode.mode === NodeMode.Edit) {
                        const mode = state.mode as NodeEditT

                        const currentNode = state.currentNode as ActionsResponseT

                        const selectedConnection = response.output.filter(
                            x => x.authStoreMap === mode.nodeEditInput.meta_data.authentication_id
                        )

                        let nextState: ActionsResponseT

                        if (!userSwitchedApp) {
                            nextState = produce(currentNode, draftState => {
                                draftState.connections = response
                                if (selectedConnection.length > 0) {
                                    draftState.selectedConnection = {
                                        type: ConnectionTypeT.old,
                                        id: selectedConnection[0].authStoreMap,
                                        selectedConnection: selectedConnection[0].name,
                                        connectionsDetails: {
                                            type: currentNode.selectedApp.type as any,
                                            value: {
                                                ...getInitialConnectionValue(currentNode.selectedApp.type).value,
                                                meta_data: selectedConnection[0]
                                            }
                                        },
                                        valid: true,
                                        loading: false,
                                        hasError: null
                                    }
                                } else {
                                    let connection = ActionInitialState.selectedConnection

                                    connection.connectionsDetails = getInitialConnectionValue(
                                        currentNode.appsList.output.filter(
                                            x => x.appId === currentNode.selectedApp.id && x.latest
                                        )[0].authentication_type
                                    )

                                    connection = {
                                        ...connection,
                                        selectedConnection: 'Add a new Account',
                                        id: '',
                                        hasError: null,
                                        valid: null
                                    }

                                    draftState.selectedConnection = connection
                                }
                            })
                        } else {
                            nextState = produce(currentNode, draftState => {
                                draftState.connections = response

                                let connection = ActionInitialState.selectedConnection

                                connection.connectionsDetails = getInitialConnectionValue(
                                    currentNode.appsList.output.filter(
                                        x => x.appId === currentNode.selectedApp.id && x.latest
                                    )[0].authentication_type
                                )

                                connection = {
                                    ...connection,
                                    selectedConnection: 'Add a new Account',
                                    id: '',
                                    hasError: null,
                                    valid: null
                                }

                                draftState.selectedConnection = connection
                            })
                        }

                        setActionPanelState({
                            ...state,
                            currentNode: nextState
                        })

                        // call this to get custom authentication fields
                        state.currentNode.selectedApp.type == AuthType.Custom ||
                        state.currentNode.selectedApp.type == AuthType.CustomOauth || state.currentNode.selectedApp.type == AuthType.PrivateOauth
                            ? getCustomAuthenticationType(state.currentNode.selectedApp.id, {
                                  ...state,
                                  currentNode: nextState
                              })
                            : getConfigDetails(state.currentNode.selectedAction.id, {
                                  ...state,
                                  currentNode: nextState
                              })
                    } else {
                        const currentNode = state.currentNode as ActionsResponseT

                        const nextState = produce(currentNode, draftState => {
                            draftState.connections = response
                        })

                        setActionPanelState({
                            ...state,
                            currentNode: nextState
                        })
                    }
                })
                .catch(err => {
                    setLoading(false)

                    setActionPanelState({
                        ...state,
                        mode: {
                            ...PopupError,
                            error: err.response
                                ? err.response.data.error
                                : 'Something went wrong!<br /> Please try again afer sometime.'
                        }
                    })
                })
        }
    }

    async function getCustomAuthenticationType(appId: string, state: ActionPanelStateT) {
        const workspaceName = props.match.params.workspacename

        await postJson(automationAPIURLWithWorkspace(workspaceName, `/actions/auth/config`))({
            appId: appId,
            version: state.currentNode.selectedApp.version
        })
            .then(resp => {
                setCustomAuthState({
                    ...customAuthState,
                    error: false
                })

                const response = resp.data as {
                    type: 'success'
                    output: {
                        id: string
                        input: InputFieldT[]
                    }
                }
                if (state.mode.mode === NodeMode.Edit) {
                    const currentNode = state.currentNode as ActionsResponseT
                    const mode = state.mode as NodeEditT

                    const nextState = produce(currentNode, draftState => {
                        let connectionsDetails = draftState.selectedConnection.connectionsDetails as
                            | CustomAuthT
                            | CustomOAuthT
                        connectionsDetails.customInputs = {
                            id: response.output.id,
                            inputs:
                                userSwitchedApp || mode.nodeEditInput.meta_data.authentication_id.length == 0
                                    ? response.output.input.map(inp => {
                                          return { ...inp, value: '' }
                                      })
                                    : response.output.input
                        }
                    })

                    setActionPanelState({
                        ...state,
                        currentNode: nextState
                    })

                    if (!userSwitchedApp) {
                        getConfigDetails(state.currentNode.selectedAction.id, {
                            ...state,
                            currentNode: nextState
                        })
                    }
                } else {
                    const currentNode = state.currentNode as ActionsResponseT

                    const nextState = produce(currentNode, draftState => {
                        let connectionsDetails = draftState.selectedConnection.connectionsDetails as
                            | CustomAuthT
                            | CustomOAuthT
                        connectionsDetails.customInputs = { id: response.output.id, inputs: response.output.input }
                    })

                    setActionPanelState({
                        ...state,
                        currentNode: nextState
                    })
                }
            })
            .catch(err => {
                setLoading(false)

                setCustomAuthState({
                    ...customAuthState,
                    error: err.response
                        ? err.response.data.error
                        : 'Something went wrong!<br /> Please try again afer sometime.'
                })

                setActionPanelState({
                    ...state
                })
            })
    }

    // -------------- Api call --------------
    async function getConfigDetails(actionId: string, state: ActionPanelStateT) {
        const workspaceName = props.match.params.workspacename

        await getJson(automationAPIURLWithWorkspace(workspaceName, `/actions/${actionId}/config`))
            .then((responseData: ActionInputsListT) => {
                if (state.mode.mode === NodeMode.Edit) {
                    // getting context popup values
                    if (
                        !userSwitchedApp &&
                        actionPanelState.parentNode.value.path &&
                        actionPanelState.parentNode.value.path.length > 0
                    ) {
                        const mode = state.mode as NodeEditT

                        const input = mode.nodeEditInput.input as InputFieldT[]

                        const triggerNodeOutput = treeData.tree.value

                        const parentNode =
                            triggerNodeOutput.kind === 'AppTrigger' || triggerNodeOutput.kind === 'EventTrigger'
                                ? ({
                                      ...state.parentNode,
                                      value: {
                                          ...state.parentNode.value,
                                          path: state.parentNode.value.path
                                              ? [
                                                    {
                                                        actionId: triggerNodeOutput.meta_data.action_id,
                                                        nodeId: triggerNodeOutput.id
                                                    }
                                                ].concat(state.parentNode.value.path)
                                              : [
                                                    {
                                                        actionId: triggerNodeOutput.meta_data.action_id,
                                                        nodeId: triggerNodeOutput.id
                                                    }
                                                ]
                                      }
                                  } as TreeT<NodeT>)
                                : state.parentNode

                        configApiCall(
                            state.currentNode.appsList,
                            parentNode,
                            rightPanelState.mode.mode === NodeMode.Edit,
                            workspaceName,
                            currentNode.addingInsideForEach
                        ).then((configResponse: ActionInputsListT[]) => {
                            const mode = state.mode as NodeEditT

                            let combiningResponseWithTriggerOutput: any = []

                            if (triggerNodeOutput.kind !== 'AppTrigger' && triggerNodeOutput.kind !== 'EventTrigger') {
                                const triggerOutputResponse: ActionInputsListT[] = [
                                    {
                                        type: 'success',
                                        output: {
                                            name: triggerNodeOutput.name,
                                            input: [],
                                            app: {
                                                appName: triggerNodeOutput.name,
                                                action: triggerNodeOutput.kind,
                                                pathId: {
                                                    nodeId: triggerNodeOutput.id,
                                                    actionId: ''
                                                },
                                                appId: '',
                                                createdDate: '',
                                                type: 'trigger',
                                                file: '',
                                                authentication_type: AuthType.None,
                                                id: '',
                                                scope: '',
                                                icon: NoTriggerVariable,
                                                latest: false,
                                                version: ''
                                            },
                                            output: triggerNodeOutput.output
                                        }
                                    }
                                ]
                                combiningResponseWithTriggerOutput = triggerOutputResponse.concat(configResponse)
                            } else {
                                combiningResponseWithTriggerOutput = configResponse
                            }

                            const addedContextValue = produce(state, draft => {
                                mode.nodeOutputConfig = combiningResponseWithTriggerOutput
                                draft.mode = mode
                            })

                            const panelState = produce(addedContextValue, draft => {
                                draft.currentNode.actionInputsList.output.input = input.map((x: InputFieldT) => {
                                    const { value } = x

                                    if (
                                        x.config.kind == 'date' ||
                                        x.config.kind == 'enum' ||
                                        x.config.kind == 'lookup-enum' ||
                                        x.config.kind == 'switch' ||
                                        x.config.kind == 'multiselect-enum' ||
                                        x.config.kind == 'multiselect-lookup-enum'
                                    ) {
                                        return x
                                    } else if (value && typeof value != 'object') {
                                        return {
                                            ...x,
                                            value: converToEditorFormat(
                                                value as string,
                                                combiningResponseWithTriggerOutput,
                                                triggerNodeOutput,
                                                x,
                                                {
                                                    inputs: draft.currentNode.actionInputsList.output
                                                        .input as InputFieldT[],
                                                    contextPopupValues,
                                                    setContextPopupValues
                                                }
                                            ) as EditorState,
                                            inputHasValue: true
                                        }
                                    } else if (value && typeof value == 'object') {
                                        const { value, ...rest } = x

                                        return {
                                            ...rest,
                                            config: {
                                                ...x.config,
                                                fields: Object.entries(value).map(obj => {
                                                    return {
                                                        key: obj[0],
                                                        value:
                                                            !obj[1] || /^\s*$/.test(obj[1])
                                                                ? emptyEditorState
                                                                : (converToEditorFormat(
                                                                      obj[1] as string,
                                                                      combiningResponseWithTriggerOutput,
                                                                      triggerNodeOutput,
                                                                      x,
                                                                      {
                                                                          inputs: draft.currentNode.actionInputsList
                                                                              .output.input as InputFieldT[],
                                                                          contextPopupValues,
                                                                          setContextPopupValues
                                                                      }
                                                                  ) as EditorState)
                                                    }
                                                })
                                            },
                                            inputHasValue:
                                                Object.entries(value).length ==
                                                Object.entries(value).filter(
                                                    obj => obj[0].length > 0 && obj[1] && obj[1].length > 0
                                                ).length
                                        }
                                    } else {
                                        return { ...x, value: emptyEditorState as any }
                                    }
                                }) as InputFieldT[],

                                draft.currentNode.workflowInputsListForTest = {
                                    type:"success",
                                    output: {
                                        ...draft.currentNode.actionInputsList.output,
                                        input: input.map(x => {
                                            if (x.config.kind != 'dictionary') {
                                                return {
                                                    ...x,
                                                    value: ""
                                                }
                                            } else {
                                                return {
                                                    ...x,
                                                    config: {
                                                        ...x.config,
                                                        fields: [{ key: '', value: "" as any }]
                                                    }
                                                }
                                            }
                                        })
                                    }
                                }
                                
                            })

                            setActionPanelState(panelState)
                        })

                        setLoading(false)
                    } else {
                        const mode = state.mode as NodeEditT

                        const addedContextValue = produce(state, draft => {
                            mode.nodeOutputConfig = []
                            draft.mode = mode
                            draft.currentNode.actionInputsList = {
                                ...responseData,
                                output: {
                                    ...responseData.output,
                                    input: responseData.output.input.map(x => {
                                        if (x.config.kind != 'dictionary') {
                                            return {
                                                ...x,
                                                value: emptyEditorState as any
                                            }
                                        } else {
                                            return {
                                                ...x,
                                                config: {
                                                    ...x.config,
                                                    fields: [{ key: '', value: emptyEditorState as any }]
                                                }
                                            }
                                        }
                                    })
                                }
                            }
                            draft.currentNode.workflowInputsListForTest = {
                                type:"success",
                                output: {
                                    ...responseData.output,
                                    input: responseData.output.input.map(x => {
                                        if (x.config.kind != 'dictionary') {
                                            return {
                                                ...x,
                                                value: ""
                                            }
                                        } else {
                                            return {
                                                ...x,
                                                config: {
                                                    ...x.config,
                                                    fields: [{ key: '', value: "" as any }]
                                                }
                                            }
                                        }
                                    })
                                }
                             }
                        })

                        setContextPopupValues({ ...contextPopupValues, inputWithValues: [] })

                        setActionPanelState(addedContextValue)

                        setLoading(false)
                    }
                } else {
                    const response: ActionInputsListT = {
                        type: responseData.type,
                        output: responseData.output
                    }

                    const nextState = produce(currentNode, draftState => {
                        draftState.actionInputsList = {
                            ...response,
                            output: {
                                ...response.output,
                                input: response.output.input.map(x => {
                                    if (x.config.kind != 'dictionary') {
                                        return {
                                            ...x,
                                            value: emptyEditorState as any
                                        }
                                    } else {
                                        return {
                                            ...x,
                                            config: {
                                                ...x.config,
                                                fields: [{ key: '', value: emptyEditorState as any }]
                                            }
                                        }
                                    }
                                })
                            }
                        }

                        draftState.workflowInputsListForTest = {
                            type:"success",
                            output: {
                                ...responseData.output,
                                input: responseData.output.input.map(x => {
                                    if (x.config.kind != 'dictionary') {
                                        return {
                                            ...x,
                                            value: ""
                                        }
                                    } else {
                                        return {
                                            ...x,
                                            config: {
                                                ...x.config,
                                                fields: [{ key: '', value: "" as any }]
                                            }
                                        }
                                    }
                                })
                            }
                         }
                    })

                    setActionPanelState({
                        ...actionPanelState,
                        currentNode: nextState
                    })
                }
            })
            .catch(err => {
                setLoading(false)

                setActionPanelState({
                    ...rightPanelState,
                    mode: {
                        ...PopupError,
                        error: err.response
                            ? err.response.data.error
                            : 'Something went wrong!<br /> Please try again afer sometime.'
                    }
                })
            })
    }

    // disable or enable save button
    function disableSave() {
        function hasValue(currentValue: any) {
            // to check whether if any context has been selected for this input then enable save
            if (
                currentValue.config.kind == 'hidden' ||
                (currentValue.config.kind == 'switch' && currentValue.config.id == 'allow_numbering')
            ) {
                return true
            } else if (configKinds.some(value => value === currentValue.config.kind)) {
                return currentValue.value.length > 0
            }
            return (currentValue.config.kind == 'dictionary' &&
                currentValue.config.fields.filter(
                    (f: any) =>
                        // (f.key.length == 0 && convertToRaw(f.value.getCurrentContent()).blocks[0].text.length == 0) ||
                        (f.key.length > 0 &&
                            convertToRaw(f.value.getCurrentContent()).blocks[0].text.length > 0 &&
                            convertToRaw(f.value.getCurrentContent()).blocks[0].text.replace(/\s/g, '').length)
                ).length == currentValue.config.fields.length) ||
                (currentValue.value !== undefined &&
                    convertToRaw(currentValue.value.getCurrentContent()).blocks[0].text.length > 0 &&
                    convertToRaw(currentValue.value.getCurrentContent()).blocks[0].text.replace(/\s/g, '').length)
                ? true
                : false
        }

        if (currentNode.actionInputsList && currentNode.actionInputsList.output) {
            const AllrequiredFieldsHasValue = !seperateOptionalFields(
                currentNode.actionInputsList.output.input ? currentNode.actionInputsList.output.input : [],
                true
            ).every(hasValue)

            return !currentNode.selectedConnection.valid || AllrequiredFieldsHasValue
        }

        return true
    }


    function isInputhaveDifferentValues(){
        function hasValue(currentValue: any,i:any) {
            if (
                currentValue.config.kind == 'hidden' ||
                (currentValue.config.kind == 'switch' && currentValue.config.id == 'allow_numbering')
            ) {
                return false
            } else if (configKinds.some(value => value === currentValue.config.kind)) {
                return currentValue.value != ((mode as NodeEditT).nodeEditInput.input[i] as InputFieldT).value
            }
            const value = currentValue.value
            const convertedValue = value
                        ? (typeof value == 'string' || Array.isArray(value))
                            ? value
                            : convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks)
                        : ''
                    if (!value || convertedValue.length === 0) {
                        return false
                    }

                    const convertedBlocks =
                        (typeof value == 'string' || Array.isArray(value))
                            ? value
                            : convertToRaw(value.getCurrentContent())
                                  .blocks.map(b =>
                                      convertToServerFormat2(convertToRaw(value.getCurrentContent()).entityMap, b)
                                  )
                                  .reduce((acc, ele, i) => (i == 0 ? (acc += ele) : (acc += '\n' + ele)), '')
            return currentValue.value?convertedBlocks != ((mode as NodeEditT).nodeEditInput.input[i] as InputFieldT).value:false
        }
        if(actionPanelState.mode.mode===NodeMode.Edit){

            if (currentNode.actionInputsList && currentNode.actionInputsList.output) {
                const AllrequiredFieldsHasValue = (currentNode.actionInputsList.output.input ? currentNode.actionInputsList.output.input : []).some(hasValue)

                return AllrequiredFieldsHasValue
            }

        return false
        }else{
            return true
        }
    }

    function updateInputValuesWithContext(props: {
        path: string
        value: string
        valuePath: string[]
        icon: string
        examples: string
    }) {
        const { path, value, valuePath, icon, examples } = props

        const textInputvalue = currentNode.actionInputsList.output.input.filter(
            x => x.key === contextPopupValues.currentInput
        )[0]

        const config = textInputvalue.config

        const editorState =
            config.kind != 'dictionary'
                ? (textInputvalue.value as any)
                : config.fields && config.fields.filter((s, i) => i == contextPopupValues.currentInputIndex)[0].value

        const nextValue = produce(currentNode, draftState => {
            draftState.actionInputsList.output.input = draftState.actionInputsList.output.input.map(
                (inputs: InputFieldT) => {
                    if (inputs.key === contextPopupValues.currentInput) {
                        return config.kind == 'dictionary'
                            ? {
                                  ...inputs,
                                  config: {
                                      ...config,
                                      fields:
                                          config.fields &&
                                          config.fields.map((f, i) =>
                                              i == contextPopupValues.currentInputIndex
                                                  ? {
                                                        ...f,
                                                        value: InsertContext(
                                                            value,
                                                            {
                                                                dataType: 'string',
                                                                path: valuePath,
                                                                icon: icon,
                                                                variable: value,
                                                                id: path,
                                                                examples
                                                            },
                                                            editorState
                                                        ) as any
                                                    }
                                                  : f
                                          )
                                  },
                                  inputHasValue: true
                              }
                            : {
                                  ...inputs,
                                  inputHasValue: true,
                                  value: InsertContext(
                                      value,
                                      {
                                          dataType: 'string',
                                          path: valuePath,
                                          icon: icon,
                                          variable: value,
                                          id: path,
                                          examples
                                      },
                                      inputs.config.kind == 'file' ? emptyEditorState : editorState
                                  ) as any
                              }
                    }
                    return inputs
                }
            )
        })
        console.log(nextValue, 'nextValue')
        setActionPanelState({
            ...actionPanelState,
            currentNode: nextValue
        })
    }

    function saveNode(currentNode:ActionsResponseT) {
        const updatedNodeValue = produce(currentNode, draftState => {
            draftState.actionInputsList.output.input = currentNode.actionInputsList.output.input.map(y => {
                if (y.config.kind != 'dictionary') {
                    const { inputHasValue, value, ...rest } = y

                    const convertedValue = value
                        ? (typeof value == 'string' || Array.isArray(value))
                            ? value
                            : convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks)
                        : ''
                    if (!value || convertedValue.length === 0) {
                        return {
                            ...rest
                        } as InputFieldT
                    }

                    const convertedBlocks =
                        (typeof value == 'string' || Array.isArray(value))
                            ? value
                            : convertToRaw(value.getCurrentContent())
                                  .blocks.map(b =>
                                      convertToServerFormat2(convertToRaw(value.getCurrentContent()).entityMap, b)
                                  )
                                  .reduce((acc, ele, i) => (i == 0 ? (acc += ele) : (acc += '\n' + ele)), '')

                    return {
                        ...rest,
                        // value: convertToServerFormat(value as any)
                        value: convertedBlocks
                    } as InputFieldT
                } else {
                    const { inputHasValue, value, ...rest } = y

                    const fields =
                        y.config.fields &&
                        y.config.fields.filter(f => {
                            const convertedValue =
                                typeof f.value == 'string'
                                    ? f.value
                                    : convertAsaSingleString(convertToRaw(f.value.getCurrentContent()).blocks)

                            return f.key.length > 0 && typeof f.value == 'string'
                                ? convertedValue.length > 0
                                : convertedValue.replace(/\s/g, '').length
                        })

                    return {
                        ...rest,
                        config: { kind: 'dictionary' },
                        value:
                            fields &&
                            fields.reduce((acc: any, val: dictionaryFieldT) => {
                                const valu = val.value

                                const convertedBlocks =
                                    typeof valu == 'string'
                                        ? valu
                                        : convertToRaw(valu.getCurrentContent())
                                              .blocks.map(b =>
                                                  convertToServerFormat2(
                                                      convertToRaw(valu.getCurrentContent()).entityMap,
                                                      b
                                                  )
                                              )
                                              .reduce(
                                                  (acc, ele, i) => (i == 0 ? (acc += ele) : (acc += '\n' + ele)),
                                                  ''
                                              )
                                return {
                                    ...acc,
                                    [val.key]: convertedBlocks
                                }
                            }, {})
                    } as InputFieldT
                }
            })
        })

        saveTree(updatedNodeValue)
    }

    function showSaveButton() {
        const mode = actionPanelState.mode

        if (mode.mode == NodeMode.Edit && hasLinkedDialogs) {
            const eqValues = (a1: any[], a2: any[]) => ramda.equals(new Set(a1), new Set(a2))

            const meta_data = mode.nodeEditInput.meta_data

            return (
                currentNode.selectedApp.id == meta_data.app.appId &&
                currentNode.selectedAction.id == meta_data.action_id &&
                meta_data.errors &&
                eqValues(currentNode.errorHandling.errors, meta_data.errors)
            )
        }

        return true
    }

    const ActionTip = () => (
        <>
        {getProductId() === "ASSISTANT" ? (
            <>
            <p>
                Use{' '}
                <a
                    className="sidepanel_href_color"
                    target="_blank"
                    href="https://help.workativ.com/knowledgebase/creating-an-automation/"
                >
                    Action
                </a>{' '}
                to perform a task or action in your app. You can create single or multiple actions in a workflow. The
                actions will be executed one by one provided previous action is successfully executed. Workflow will
                stop continuing to next step if an action fails.
            </p>
            <p>
                Tip: You can enable error handling if you want the workflow to execute next step even though the action
                is failed. Not recommended, if your bot depends on the result of the workflow.
            </p>
            </>
         ) : ( 
            <>
            <p>
                Use{' '}
                <a
                    className="sidepanel_href_color"
                    target="_blank"
                    href="https://help.workativ.com/knowledgebase/action/"
                >
                    Action
                </a>{' '}
                to perform a task or action in your app. You can create single or multiple actions in a workflow. The
                actions will be executed one by one provided previous action is successfully executed. Workflow will
                stop continuing to next step if an action fails.
            </p>
            <p>
                Tip: You can enable error handling if you want the workflow to execute next step even though the action
                is failed.
            </p>
            </>
         ) }
         </>
        
    )

    const replaceBetween = (str: string, start: number, end: number, what: string, isLast: boolean) => {
        const replaced = isLast ? str.substring(0, start) + what : str.substring(0, start) + what + str.substring(end)
        // console.log('replace between', str, start, end, isLast)
        // console.log('replaced', replaced)
        return replaced
    }
    const replaceBetween2 = (str: string, start: number, end: number, what: string, isLast: boolean) => {
        const replaced = isLast
            ? str.substring(0, start) + what + str.substring(end)
            : str.substring(0, start) + what + str.substring(end)
        // console.log('replace between', str, start, end, isLast, str.substring(0, start), 'what', str.substring(end))
        // console.log('replaced', replaced)
        return replaced
    }

    async function updateVariableFromInput(text: any, old: any, cb: Function) {
        const inputHasValues = currentNode.actionInputsList.output.input
            .map((inp, i) => {
                const value = inp.value as EditorState

                if (inp.config.kind == 'dictionary') {
                    const fields = inp.config.fields as dictionaryFieldT[]
                    return fields
                        .map((f, i) => {
                            const fValue = f.value as EditorState
                            return fValue &&
                                typeof f.value != 'string' &&
                                convertAsaSingleString(convertToRaw(fValue.getCurrentContent()).blocks).includes(
                                    old.title
                                )
                                ? i
                                : null
                        })
                        .filter(inp => inp != null).length > 0
                        ? i
                        : null
                }
                return value &&
                    typeof value != 'string' &&
                    convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks).includes(old.title)
                    ? i
                    : null
            })
            .filter(inp => inp != null)
        if (inputHasValues.length > 0) {
            await cb('edit')

            const newValue = produce(currentNode, draft => {
                draft.actionInputsList.output.input = draft.actionInputsList.output.input.map(inp => {
                    if (inp.config.kind == 'lookup-enum') {
                        return inp
                    }

                    if (inp.config.kind == 'dictionary') {
                        const fields = inp.config.fields as dictionaryFieldT[]
                        const hasValue =
                            fields
                                .map((f, i) => {
                                    const fValue = f.value as EditorState
                                    return fValue &&
                                        typeof f.value != 'string' &&
                                        convertAsaSingleString(
                                            convertToRaw(fValue.getCurrentContent()).blocks
                                        ).includes(old.title)
                                        ? true
                                        : null
                                })
                                .filter(inp => inp != null).length > 0

                        if (hasValue) {
                            const newFields = fields.map((f, i) => {
                                const hasValue =
                                    f.value &&
                                    typeof f.value != 'string' &&
                                    convertAsaSingleString(convertToRaw(f.value.getCurrentContent()).blocks).includes(
                                        old.title
                                    )
                                        ? true
                                        : false
                                if (hasValue) {
                                    const value = f.value as EditorState
                                    const inpValue = convertToRaw(value.getCurrentContent())
                                    let omittedMap = {}
                                    // items not needed to edit in omitted map
                                    const newMap = Object.entries(inpValue.entityMap).reduce((acc: any, curr: any) => {
                                        // console.log(curr, 'curr')
                                        if (
                                            (curr[1].data.variable ? curr[1].data.variable : curr[1].data.value) !=
                                            old.title
                                        ) {
                                            const { [`${curr[0]}`]: omit, ...rest } = acc
                                            omittedMap[`${curr[0]}`] = omit
                                            return rest
                                        }
                                        curr[1].data.path[curr[1].data.path.length - 1] = text.title

                                        acc[`${curr[0]}`] = curr[1].data.variable
                                            ? {
                                                  ...curr[1],
                                                  data: { ...curr[1].data, variable: text.title }
                                              }
                                            : {
                                                  ...curr[1],
                                                  data: { ...curr[1].data, value: text.title, visible: text.title }
                                              }
                                        return acc
                                    }, inpValue.entityMap)

                                    const minus = text.title.length < old.title.length

                                    const newLength = minus
                                        ? old.title.length - text.title.length
                                        : text.title.length - old.title.length
                                    let itemsProcessed = 0
                                    const newBlocks = inpValue.blocks.map(b => {
                                        const filtered = b.entityRanges.filter(e => {
                                            return Object.keys(newMap).indexOf(e.key.toString()) != -1
                                        })

                                        return {
                                            ...b,
                                            text: filtered.reduce((acc, curr, i) => {
                                                if (i == 0) {
                                                    const start =
                                                        curr.offset - (old.title.length - text.title.length) * i
                                                    return replaceBetween2(
                                                        acc,
                                                        curr.offset,
                                                        start + old.title.length,
                                                        text.title,
                                                        filtered.length == i + 1
                                                    )
                                                } else {
                                                    const start =
                                                        curr.offset - (old.title.length - text.title.length) * i
                                                    return replaceBetween2(
                                                        acc,
                                                        start,
                                                        start + old.title.length,
                                                        text.title,
                                                        filtered.length == i + 1
                                                    )
                                                }
                                            }, b.text),
                                            entityRanges: b.entityRanges
                                                .reduce((acc: any, curr: any, i: number) => {
                                                    if (
                                                        Object.keys(newMap)
                                                            .map(x => parseInt(x))
                                                            .some(x => curr.key >= x)
                                                    ) {
                                                        if (Object.keys(newMap).indexOf(curr.key.toString()) != -1) {
                                                            const newOffsetDifference =
                                                                itemsProcessed *
                                                                (Object.keys(newMap).length > 0
                                                                    ? old.title.length - text.title.length
                                                                    : 0)
                                                            itemsProcessed = itemsProcessed + 1
                                                            return acc.concat({
                                                                ...curr,
                                                                offset:
                                                                    i == 0
                                                                        ? curr.offset
                                                                        : curr.offset - newOffsetDifference,
                                                                length:
                                                                    curr.length - (old.title.length - text.title.length)
                                                            })
                                                        } else {
                                                            const newOffsetDifference =
                                                                itemsProcessed *
                                                                (Object.keys(newMap).length > 0
                                                                    ? old.title.length - text.title.length
                                                                    : 0)
                                                            return acc.concat({
                                                                ...curr,
                                                                offset:
                                                                    i == 0
                                                                        ? curr.offset
                                                                        : curr.offset - newOffsetDifference,
                                                                length: curr.length
                                                            })
                                                        }
                                                    }

                                                    return acc.concat(curr)
                                                }, [])
                                                .filter((x: any) => x.length > 0)
                                        }
                                    })
                                    return {
                                        ...f,
                                        value: EditorState.createWithContent(
                                            convertFromRaw({
                                                blocks: newBlocks,
                                                entityMap: { ...omittedMap, ...newMap }
                                            }),
                                            decorator
                                        )
                                    } as any
                                }
                                return f
                            })

                            return { ...inp, config: { ...inp.config, fields: newFields } }
                        }
                        return inp as any
                    }
                    const value = inp.value as EditorState
                    const hasValue = convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks).includes(
                        old.title
                    )
                    if (
                        typeof value != 'string' &&
                        Object.keys(convertToRaw(value.getCurrentContent()).entityMap).length > 0 &&
                        hasValue
                    ) {
                        const inpValue = convertToRaw(value.getCurrentContent())
                        let omittedMap = {}
                        // items not needed to edit in omitted map
                        const newMap = Object.entries(inpValue.entityMap).reduce((acc: any, curr: any) => {
                            // console.log(curr, 'curr')
                            if ((curr[1].data.variable ? curr[1].data.variable : curr[1].data.value) != old.title) {
                                const { [`${curr[0]}`]: omit, ...rest } = acc
                                omittedMap[`${curr[0]}`] = omit
                                return rest
                            }
                            curr[1].data.path[curr[1].data.path.length - 1] = text.title

                            acc[`${curr[0]}`] = curr[1].data.variable
                                ? {
                                      ...curr[1],
                                      data: { ...curr[1].data, variable: text.title }
                                  }
                                : {
                                      ...curr[1],
                                      data: { ...curr[1].data, value: text.title, visible: text.title }
                                  }
                            return acc
                        }, inpValue.entityMap)

                        const minus = text.title.length < old.title.length

                        const newLength = minus
                            ? old.title.length - text.title.length
                            : text.title.length - old.title.length
                        let itemsProcessed = 0
                        const newBlocks = inpValue.blocks.map(b => {
                            const filtered = b.entityRanges.filter(e => {
                                return Object.keys(newMap).indexOf(e.key.toString()) != -1
                            })

                            // console.log(filtered, 'filtered', newMap, 'b.entityRanges', b.entityRanges, value, text, old)
                            return {
                                ...b,
                                text: filtered.reduce((acc, curr, i) => {
                                    if (i == 0) {
                                        // console.log(
                                        //     acc,
                                        //     curr.offset,
                                        //     old.title.length,
                                        //     text.title,
                                        //     'itemsProcessed :: 1',
                                        //     replaceBetween2(acc, curr.offset, old.title.length, text.title, filtered.length == i + 1)
                                        // )
                                        const start = curr.offset - (old.title.length - text.title.length) * i
                                        return replaceBetween2(
                                            acc,
                                            curr.offset,
                                            start + old.title.length,
                                            text.title,
                                            filtered.length == i + 1
                                        )
                                    } else {
                                        // console.log(
                                        //     replaceBetween2(
                                        //         acc,
                                        //         minus ? curr.offset - newLength : curr.offset + newLength,
                                        //         old.title.length,
                                        //         text.title,
                                        //         filtered.length == i + 1
                                        //     ),
                                        //     'itemsProcessed :: 2'
                                        // )
                                        const start = curr.offset - (old.title.length - text.title.length) * i
                                        return replaceBetween2(
                                            acc,
                                            start,
                                            start + old.title.length,
                                            text.title,
                                            filtered.length == i + 1
                                        )

                                        // return acc.substr(curr.offset - text.title.length, text.title.length)
                                    }
                                }, b.text),
                                entityRanges: b.entityRanges
                                    .reduce((acc: any, curr: any, i: number) => {
                                        // if (Object.keys(newMap).indexOf(curr.key.toString()) != -1) {
                                        //     console.log('update', curr, b.entityRanges)
                                        //     return acc.concat(curr)
                                        // }
                                        // console.log(
                                        //     acc,
                                        //     Object.keys(newMap)
                                        //         .map(x => parseInt(x))
                                        //         .filter(x => x >= curr.key),
                                        //     curr,
                                        //     'acc'
                                        // )
                                        if (
                                            Object.keys(newMap)
                                                .map(x => parseInt(x))
                                                .some(x => curr.key >= x)
                                        ) {
                                            if (Object.keys(newMap).indexOf(curr.key.toString()) != -1) {
                                                const newOffsetDifference =
                                                    itemsProcessed *
                                                    (Object.keys(newMap).length > 0
                                                        ? old.title.length - text.title.length
                                                        : 0)
                                                // console.log( "entity itemsProcessed is not::" ,itemsProcessed,newOffsetDifference,i == 0 ? curr.offset : curr.offset - newOffsetDifference)
                                                itemsProcessed = itemsProcessed + 1
                                                return acc.concat({
                                                    ...curr,
                                                    offset: i == 0 ? curr.offset : curr.offset - newOffsetDifference,
                                                    length: curr.length - (old.title.length - text.title.length)
                                                })
                                            } else {
                                                const newOffsetDifference =
                                                    itemsProcessed *
                                                    (Object.keys(newMap).length > 0
                                                        ? old.title.length - text.title.length
                                                        : 0)
                                                // console.log( "entity itemsProcessed is::" ,itemsProcessed,newOffsetDifference,i == 0 ? curr.offset : curr.offset - newOffsetDifference)
                                                return acc.concat({
                                                    ...curr,
                                                    offset: i == 0 ? curr.offset : curr.offset - newOffsetDifference,
                                                    length: curr.length
                                                })
                                            }
                                        }

                                        return acc.concat(curr)
                                    }, [])
                                    .filter((x: any) => x.length > 0)
                                // .filter(e => Object.keys(newMap).indexOf(e.key.toString()) != -1)
                                // .map((r, i) => ({
                                //     ...r,
                                //     offset: i == 0 ? r.offset : minus ? r.offset - newLength : r.offset + newLength,
                                //     length: minus ? r.length - newLength : r.length + newLength
                                // }))
                            }
                        })
                        // console.log(
                        //     inpValue,
                        //     'inpValue :::',
                        //     {
                        //         blocks: newBlocks,
                        //         entityMap: { ...omittedMap, ...newMap }
                        //     },
                        //     inpValue
                        // )

                        // console.log(inpValue, 'entity :::', { ...omittedMap, ...newMap }, inpValue.entityMap)

                        return {
                            ...inp,
                            value: EditorState.createWithContent(
                                convertFromRaw({
                                    blocks: newBlocks,
                                    entityMap: { ...omittedMap, ...newMap }
                                }),
                                decorator
                            )
                        } as any
                    }
                    return inp as any
                })
            })

            setActionPanelState({ ...actionPanelState, currentNode: newValue })
        } else {
            await cb('no-edit')
        }
    }

    async function removeVariableFromInput(text: any, cb: Function) {
        console.log('replace text', text)
        const inputHasValues = currentNode.actionInputsList.output.input
            .map((inp, i) => {
                const value = inp.value as EditorState
                if (inp.config.kind == 'dictionary') {
                    const fields = inp.config.fields as dictionaryFieldT[]
                    return fields
                        .map((f, i) => {
                            const fValue = f.value as EditorState
                            return fValue &&
                                typeof f.value != 'string' &&
                                convertAsaSingleString(convertToRaw(fValue.getCurrentContent()).blocks).includes(
                                    text.title
                                )
                                ? i
                                : null
                        })
                        .filter(inp => inp != null).length > 0
                        ? i
                        : null
                }
                return value &&
                    typeof value != 'string' &&
                    convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks).includes(text.title)
                    ? i
                    : null
            })
            .filter(inp => inp != null)
        if (inputHasValues.length > 0) {
            await cb('delete')
            const newValue = produce(currentNode, draft => {
                draft.actionInputsList.output.input = draft.actionInputsList.output.input.map(inp => {
                    if (inp.config.kind == 'lookup-enum') {
                        return inp
                    }

                    if (inp.config.kind == 'dictionary') {
                        const fields = inp.config.fields as dictionaryFieldT[]

                        const hasValue =
                            fields
                                .map((f, i) => {
                                    const fValue = f.value as EditorState
                                    return fValue &&
                                        typeof f.value != 'string' &&
                                        convertAsaSingleString(
                                            convertToRaw(fValue.getCurrentContent()).blocks
                                        ).includes(text.title)
                                        ? true
                                        : null
                                })
                                .filter(inp => inp != null).length > 0

                        if (hasValue) {
                            const newFields = fields.map((f, i) => {
                                const hasValue =
                                    f.value &&
                                    typeof f.value != 'string' &&
                                    convertAsaSingleString(convertToRaw(f.value.getCurrentContent()).blocks).includes(
                                        text.title
                                    )
                                        ? true
                                        : false
                                if (hasValue) {
                                    const value = f.value as EditorState

                                    const inpValue = convertToRaw(value.getCurrentContent())

                                    const newMap = Object.entries(inpValue.entityMap).reduce((acc: any, curr: any) => {
                                        if (
                                            (curr[1].data.variable ? curr[1].data.variable : curr[1].data.value) ==
                                            text.title
                                        ) {
                                            const { [`${curr[0]}`]: omit, ...rest } = acc
                                            return rest
                                        }
                                        return acc
                                    }, inpValue.entityMap)

                                    const newBlocks = inpValue.blocks.map(b => {
                                        const filtered = b.entityRanges.filter(e => {
                                            return Object.keys(newMap).indexOf(e.key.toString()) == -1
                                        })

                                        return {
                                            ...b,
                                            text: b.entityRanges
                                                .filter(e => {
                                                    return Object.keys(newMap).indexOf(e.key.toString()) == -1
                                                })
                                                .reduce((acc, curr, i) => {
                                                    if (i == 0) {
                                                        return replaceBetween2(
                                                            acc,
                                                            curr.offset,
                                                            curr.offset + text.title.length,
                                                            '',
                                                            curr.offset + text.title.length >= acc
                                                        )
                                                    } else {
                                                        const start = curr.offset - text.title.length * i
                                                        return replaceBetween2(
                                                            acc,
                                                            start,
                                                            start + text.title.length,
                                                            '',
                                                            filtered.length == i + 1
                                                        )

                                                        // return acc.substr(curr.offset - text.title.length, text.title.length)
                                                    }
                                                }, b.text),
                                            entityRanges: b.entityRanges
                                                .reduce((acc, current, i: number) => {
                                                    if (Object.keys(newMap).indexOf(current.key.toString()) != -1) {
                                                        const newOffsetDifference =
                                                            acc.filter(x => x.length === 0).length *
                                                            (Object.keys(newMap).length > 0 ? text.title.length : 0)
                                                        // console.log('newOffsetDifference', newOffsetDifference, newMap.length, newMap)
                                                        return [
                                                            ...acc,
                                                            {
                                                                ...current,
                                                                offset:
                                                                    i == 0
                                                                        ? current.offset
                                                                        : current.offset - newOffsetDifference
                                                            }
                                                        ]
                                                    } else {
                                                        return [
                                                            ...acc,
                                                            {
                                                                ...current,
                                                                // offset: i == 0 ? current.offset : acc[i - 1].offset,
                                                                length: 0
                                                            }
                                                        ]
                                                    }
                                                    return [...acc, current]
                                                }, [] as RawDraftEntityRange[])

                                                .filter(e => e.length !== 0)
                                            // .map((r, i: number) => {
                                            //     return { ...r, offset: i == 0 ? r.offset : r.offset - text.title.length }
                                            // })
                                        }
                                    })

                                    return {
                                        ...f,
                                        value: EditorState.createWithContent(
                                            convertFromRaw({
                                                blocks: newBlocks,
                                                entityMap: newMap
                                            }),
                                            decorator
                                        )
                                    } as any
                                }
                                return f
                            })

                            return { ...inp, config: { ...inp.config, fields: newFields } }
                        }
                        return inp as any
                    }

                    const value = inp.value as EditorState
                    const hasValue = convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks).includes(
                        text.title
                    )
                    if (
                        typeof value != 'string' &&
                        Object.keys(convertToRaw(value.getCurrentContent()).entityMap).length > 0 &&
                        hasValue
                    ) {
                        const inpValue = convertToRaw(value.getCurrentContent())

                        const newMap = Object.entries(inpValue.entityMap).reduce((acc: any, curr: any) => {
                            if ((curr[1].data.variable ? curr[1].data.variable : curr[1].data.value) == text.title) {
                                const { [`${curr[0]}`]: omit, ...rest } = acc
                                return rest
                            }
                            return acc
                        }, inpValue.entityMap)

                        const newBlocks = inpValue.blocks.map(b => {
                            const filtered = b.entityRanges.filter(e => {
                                return Object.keys(newMap).indexOf(e.key.toString()) == -1
                            })

                            return {
                                ...b,
                                text: b.entityRanges
                                    .filter(e => {
                                        return Object.keys(newMap).indexOf(e.key.toString()) == -1
                                    })
                                    .reduce((acc, curr, i) => {
                                        if (i == 0) {
                                            return replaceBetween2(
                                                acc,
                                                curr.offset,
                                                curr.offset + text.title.length,
                                                '',
                                                curr.offset + text.title.length >= acc
                                            )
                                        } else {
                                            const start = curr.offset - text.title.length * i
                                            return replaceBetween2(
                                                acc,
                                                start,
                                                start + text.title.length,
                                                '',
                                                filtered.length == i + 1
                                            )

                                            // return acc.substr(curr.offset - text.title.length, text.title.length)
                                        }
                                    }, b.text),
                                entityRanges: b.entityRanges
                                    .reduce((acc, current, i: number) => {
                                        if (Object.keys(newMap).indexOf(current.key.toString()) != -1) {
                                            const newOffsetDifference =
                                                acc.filter(x => x.length === 0).length *
                                                (Object.keys(newMap).length > 0 ? text.title.length : 0)
                                            // console.log('newOffsetDifference', newOffsetDifference, newMap.length, newMap)
                                            return [
                                                ...acc,
                                                {
                                                    ...current,
                                                    offset:
                                                        i == 0 ? current.offset : current.offset - newOffsetDifference
                                                }
                                            ]
                                        } else {
                                            return [
                                                ...acc,
                                                {
                                                    ...current,
                                                    // offset: i == 0 ? current.offset : acc[i - 1].offset,
                                                    length: 0
                                                }
                                            ]
                                        }
                                        return [...acc, current]
                                    }, [] as RawDraftEntityRange[])

                                    .filter(e => e.length !== 0)
                                // .map((r, i: number) => {
                                //     return { ...r, offset: i == 0 ? r.offset : r.offset - text.title.length }
                                // })
                            }
                        })

                        return {
                            ...inp,
                            value: EditorState.createWithContent(
                                convertFromRaw({
                                    blocks: newBlocks,
                                    entityMap: newMap
                                }),
                                decorator
                            )
                        } as any
                    }
                    return inp as any
                })
            })

            setActionPanelState({ ...actionPanelState, currentNode: newValue })
        } else {
            await cb('delete')
        }
    }

    const SidePanelContextValue = useContext(SidePanelContext)

    // to show loading whenever mode changed
    if (loading) {
        return (
            <div className="automation_canvas_right">
                <Wrapper>
                    <div className="popup_inside_loader">
                        <Loader.PopupLoader show={true} />
                    </div>
                </Wrapper>
            </div>
        )
    } else if (actionPanelState.mode.mode === NodeMode.Error || customAuthState.error) {
        return (
            <div className="automation_canvas_right">
                <Wrapper>
                    <div className="flow_canvas_popup_error">
                        <Error.AddorEdit
                            info={
                                actionPanelState.mode.mode === NodeMode.Error
                                    ? actionPanelState.mode.error
                                    : customAuthState.error
                            }
                            onClick={() => {
                                customAuthState.error
                                    ? getCustomAuthenticationType(currentNode.selectedApp.id, actionPanelState)
                                    : cancelCurrentPopup({
                                          actionPanelState,
                                          SidePanelContext: SidePanelContextValue,
                                          backupTreeForErrorHandling
                                      })
                            }}
                            buttonName={customAuthState.error ? 'Retry' : 'Okay'}
                        />
                    </div>
                </Wrapper>
            </div>
        )
    }

    const mode = actionPanelState.mode

    const { state, ...rest } = props

    return (
        <React.Fragment>
            <Wrapper>
            {  getProductId() === "ASSISTANT" ? 
                    <CanvasRightPanel.StepHeader
                        title={actionPanelState.mode.mode === NodeMode.Add ? 'Action' : 'Edit Action'}
                        description={
                            actionPanelState.mode.mode === NodeMode.Add
                                ? 'Setup action in your workflow.'
                                : 'Edit workflow action fields'
                        }
                        infocontent={<ActionTip />}
                        helpcontent="To help documention of action"
                        helplink="https://help.workativ.com/knowledgebase/add-action/"
                    >
                        {currentNode.selectedApp.type == 'on_prem' ? (
                            <VideoLink id={'SECURELY_CONNECT_AN_ON_PREM_APP_IN_A_WORKFLOW'} text={'How to'} />
                        ) : (
                            <VideoLink id={'CREATE_AN_ACTION_IN_APP_WORKFLOW'} text={'How to'} />
                        )}
                     </CanvasRightPanel.StepHeader> 
                
                    : 
                    
                    <CanvasRightPanel.StepHeader
                        title={actionPanelState.mode.mode === NodeMode.Add ? 'Start your app workflow' : 'Edit your app workflow'}
                        description={
                            actionPanelState.mode.mode === NodeMode.Add
                                ? 'Set an action'
                                : 'Edit an action'
                        }
                        infocontent={<ActionTip />}
                        helpcontent="Help document"
                        helplink="https://help.workativ.com/knowledgebase/action/"
                     >
                        {currentNode.selectedApp.type == 'on_prem' ? (
                            <VideoLink id={'SECURELY_CONNECT_AN_ON_PREM_APP_IN_A_WORKFLOW'} text={'How to edit action'} />
                        ) : (
                            <VideoLink id={'CREATE_AN_ACTION_IN_APP_WORKFLOW'} text={'How to set action'} />
                        )}
                    </CanvasRightPanel.StepHeader> 
                }
                
                <>
                    <PopupScrollHeight>
                        <EmptyDiv>
                            <div className="automation_canvas_right action_popup automate_apptrigger_popup">
                                <ActionPanel.SelectApplication />

                                {currentNode.selectedAction.id && currentNode.selectedAction.id.length > 0 && (
                                    <ActionPanel.ConnectAccount {...props} />
                                )}

                                {currentNode.selectedConnection.type == ConnectionTypeT.old &&
                                    currentNode.selectedConnection.valid && (
                                        <React.Fragment>
                                            <CommonInputs
                                                state={actionPanelState}
                                                setContextPopupValues={setContextPopupValues}
                                                contextPopupValues={contextPopupValues}
                                                setPanelState={setActionPanelState}
                                                title={'Action Inputs'}
                                                description="Select or fill required inputs to execute this action."
                                                inputsList={
                                                    currentNode.actionInputsList && currentNode.actionInputsList.output
                                                        ? currentNode.actionInputsList.output.input
                                                        : []
                                                }
                                                workspacename={props.match.params.workspacename}
                                                {...rest}
                                            />
                                            <ActionPanel.ErrorHandling
                                                backupTreeForErrorHandling={backupTreeForErrorHandling}
                                                setBackupTreeForErrorHandling={setBackupTreeForErrorHandling}
                                            />
                                            <ActionPanel.ActionInfo />
                                        </React.Fragment>
                                    )}
                            </div>
                        </EmptyDiv>
                        <ButtonContainer className="footer_button_fixed">
                            <div className="popup_footer_button_container">
                            <Button
                                primary
                                className="automation_condition_button_btn"
                                onClick={() => {
                                    cancelCurrentPopup({
                                        actionPanelState,
                                        SidePanelContext: SidePanelContextValue,
                                        backupTreeForErrorHandling
                                    })
                                }}
                            >
                                <label>Cancel</label>
                            </Button>
                            {((isAssitant ? automationMeta.type =='no_trigger' ?!hasLinkedDialogs : !active_automation: !active_automation) ||
                                (mode.mode == NodeMode.Edit &&
                                    ((currentNode.selectedConnection.id !==
                                        mode.nodeEditInput.meta_data.authentication_id &&
                                    currentNode.selectedConnection.valid) || isInputhaveDifferentValues()) &&
                                    showSaveButton())) && (
                                <Button
                                    className={
                                        disableSave()
                                            ? 'automation_condition_button_btn editor_btn_disabled'
                                            : 'automation_condition_button_btn'
                                    }
                                    onClick={() => setShowOutputTest(true)}
                                    disabled={disableSave()}
                                >
                                    <label>Test</label>
                                </Button>
                            )}
                            </div>
                        </ButtonContainer>
                    </PopupScrollHeight>
                </>
            </Wrapper>

            {contextPopupValues.show && (
                <ContextPopup
                    {...props}
                    tree={treeData.tree}
                    mode={rightPanelState.mode}
                    parentNode={rightPanelState.parentNode}
                    actionAppsList={currentNode.appsList}
                    triggerNode={treeData.tree.value}
                    isFilePicker={contextPopupValues.isFilePicker}
                    addingFlowInsideForEach={currentNode.addingInsideForEach}
                    updateInputValuesWithContext={updateInputValuesWithContext}
                    removeVariableFromInput={(text: any, cb: Function) => removeVariableFromInput(text, cb)}
                    updateVariableFromInput={(newText: any, oldText: any, cb: Function) =>
                        updateVariableFromInput(newText, oldText, cb)
                    }
                    closeContextPopup={() =>
                        setContextPopupValues({
                            ...contextPopupValues,
                            show: false,
                            currentInput: '',
                            isFilePicker: false
                        })
                    }
                />
            )}

            {showOutputTest && 
                <WorkflowOutputTest
                    {...props} 
                    saveNode={saveNode}
                    flowtype={
                        treeData.tree.value.kind == 'AppTrigger' ||
                        treeData.tree.value.kind == 'EventTrigger'
                            ? 'trigger'
                            : 'notrigger'
                    }
                    automationId = {props.match.params.id}
                    workspaceName = {props.match.params.workspacename}
                    actionPanelState={actionPanelState}
                    tree={treeData.tree}
                    webhookTrigger={
                        treeData.tree.value.kind == "WebhookTrigger"
                    }
                    goBack={()=>setShowOutputTest(false)}
                    setActionPanelState={setActionPanelState}
                    eventTrigger={
                        treeData.tree.value.kind == "EventTrigger"
                    }
                    treeData={treeData.tree}
                    fromAction
                />
            }
        </React.Fragment>
    )
}

function cancelCurrentPopup(props: {
    actionPanelState: ActionPanelStateT
    SidePanelContext: RightPanelStateContext
    backupTreeForErrorHandling: { tree: undefined | TreeT<NodeT> }
}) {
    const { actionPanelState, SidePanelContext, backupTreeForErrorHandling } = props

    const { rightPanelState, setRightPanelState, treeData, setEditTreeData, editTreeData } = SidePanelContext

    const currentNode = actionPanelState.currentNode

    const tree =
        actionPanelState.parentNode.value.kind == 'ForEach' &&
        actionPanelState.currentNode.addingInsideForEach &&
        actionPanelState.parentNode.value.subflow
            ? actionPanelState.parentNode.value.subflow.children.filter(x => x.value.kind === 'ParallelPath')
            : actionPanelState.parentNode.children.filter(x => x.value.kind === 'ParallelPath')

    const cancellingNode = currentNode.parallelPathNode.currentParallelPathNode

    if (tree.length < 2) {
        setRightPanelState({
            ...rightPanelState,
            show: false,
            mode: NodeView,
            parentNode: { value: Tree, children: [] },
            currentNode: {
                ...currentNode,
                parallelPathNode: {
                    ...currentNode.parallelPathNode,
                    currentParallelPathNode: null
                }
            }
        })

        const newTree = cancellingNode
            ? updateNode(
                  { ...cancellingNode, name: '' },
                  treeData.tree,
                  cancellingNode.meta_data && cancellingNode.meta_data.subflow
                      ? {
                            addingInsideForEach: currentNode.addingInsideForEach,
                            forEachNodeId: cancellingNode.meta_data.subflow.forEachNodeId,
                            parentNode: cancellingNode.meta_data.subflow.parentId
                        }
                      : undefined
              )
            : treeData.tree

        setEditTreeData({ ...treeData, tree: newTree })
    } else {
        const node = cancellingNode as NodeT
        const addingFlowInsideForEach = {
            addingInsideForEach: node.meta_data.subflow.hasSubFlow as boolean,
            forEachNodeId: node.meta_data.subflow.forEachNodeId as string,
            parentNode: rightPanelState.parentNode.value
        }
        // for while adding new parallel path
        setRightPanelState({
            ...rightPanelState,
            show: false,
            currentNode: {
                ...currentNode,
                parallelPathNode: {
                    ...currentNode.parallelPathNode,
                    currentParallelPathNode: null
                }
            }
        })

        const newTree = cancellingNode
            ? updateNode(
                  { ...cancellingNode, name: '' },
                  // to handle cancelleling action while adding new parallelpaths
                  currentNode.errorHandling.errorHandling ? backupTreeForErrorHandling.tree : editTreeData.tree,
                  addingFlowInsideForEach
              )
            : editTreeData.tree
        setEditTreeData({ ...editTreeData, tree: newTree })
    }
}

const Wrapper = (props: any) => (
    <ActionWrapper>
        <ActionPanelPopup>
            <PopupWrapper>
                <PopupContainer>
                    <div className="automation_canvas_right_popup automate_product">
                        <div className="automation_canvas_right_popup_form">
                            <div className="automation_rightside_form automation_rightside_step_two edit_action_height">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </PopupContainer>
            </PopupWrapper>
        </ActionPanelPopup>
    </ActionWrapper>
)

function filterAction(array: any[], selectedAppId: string) {
    if (array.length === 0) return []
    return array.filter((x: any) => x.appId === selectedAppId)[0].value
}

ActionPanel.SelectApplication = (props: any) => {
    const { actionPanelState, setActionPanelState, setUserSwitchedApp } = useContext(ActionPanelStateContext)

    const { active_automation, hasLinkedDialogs, automationMeta } = useContext(SidePanelContext)

    const currentNode = actionPanelState.currentNode as ActionsResponseT

    const filteredResponse: any = grouping(
        currentNode.appsList.output.filter(action => action.type == 'action' && action.latest),
        'appName'
    )

    const [search, setSearch] = useState({
        searching: false,
        key: '',
        inFocus: false
    })

    const apps_ref = useRef(null)

    const isAssitant = getProductId() == 'ASSISTANT'

    return (
        <React.Fragment>
            <InputWrapper className="autoamtion_connection">
                <div className="select_application">
                    <div className="">
                        <CanvasRightPanel.AppsDropDown
                            inputs={{
                                inputs: [
                                    // DefaultSelectedValue,
                                    ...(search.searching
                                        ? sortAnArrayAlphabetically(filteredResponse, 'name').filter(
                                              (app: any) =>
                                                  app.name.toLowerCase().indexOf(search.key.toLowerCase()) > -1
                                          )
                                        : sortAnArrayAlphabetically(filteredResponse, 'name'))
                                ],
                                key: 'name',
                                idKey: 'appId',
                                showIcon: true
                            }}
                            addDropDown
                            showOnPrem
                            closeCallBack={() => {
                                !search.inFocus && setSearch({ ...search, searching: false })
                            }}
                            updateSelection={(selectedNewApp: string, id: string) => {
                                if (NodeMode.Add === actionPanelState.mode.mode) {
                                    const nextState = produce(currentNode, draftState => {
                                        draftState.selectedApp = {
                                            name: selectedNewApp,
                                            id: id,
                                            type: id
                                                ? currentNode.appsList.output.filter(x => x.appId === id && x.latest)[0]
                                                      .authentication_type
                                                : AuthType.None,
                                            icon: id
                                                ? currentNode.appsList.output.filter(x => x.appId === id)[0].icon
                                                : '',
                                            version: id
                                                ? currentNode.appsList.output.filter(x => x.appId === id && x.latest)[0]
                                                      .version
                                                : ''
                                        }

                                        const auth_type = currentNode.appsList.output.filter(
                                            x => x.appId === id && x.latest
                                        )[0].authentication_type

                                        draftState.selectedAction = ActionInitialState.selectedAction

                                        if (auth_type !== 'on_prem') {
                                            let connection = ActionInitialState.selectedConnection as NewConnectionT

                                            connection.connectionsDetails = getInitialConnectionValue(auth_type)

                                            connection = {
                                                ...connection,
                                                selectedConnection: 'Add a new Account',
                                                id: '',
                                                hasError: null,
                                                valid: null,
                                                connectionEditing: false
                                            }
                                            draftState.selectedConnection = connection
                                        } else {
                                            let connection = {
                                                ...currentNode.selectedConnection,
                                                connectionsDetails: OnpremAuthValue
                                            } as NewConnectionT

                                            connection = {
                                                ...connection,
                                                id: '',
                                                hasError: null,
                                                valid: null,
                                                connectionEditing: false
                                            }
                                            draftState.selectedConnection = connection
                                        }

                                        draftState.actionInputsList = ActionInitialState.actionInputsList
                                    })
                                    setActionPanelState({
                                        ...actionPanelState,
                                        currentNode: nextState
                                    })
                                } else if (selectedNewApp !== 'Select' && id !== currentNode.selectedApp.id) {
                                    const nextState = produce(ActionInitialState, draftState => {
                                        draftState.appsList.output = currentNode.appsList.output
                                        draftState.selectedApp = {
                                            name: selectedNewApp,
                                            id: id,
                                            type: id
                                                ? currentNode.appsList.output.filter(x => x.appId === id && x.latest)[0]
                                                      .authentication_type
                                                : AuthType.None,
                                            icon: id
                                                ? currentNode.appsList.output.filter(x => x.appId === id)[0].icon
                                                : '',
                                            version: id
                                                ? currentNode.appsList.output.filter(x => x.appId === id && x.latest)[0]
                                                      .version
                                                : ''
                                        }
                                        draftState.subflowMetaData = currentNode.subflowMetaData

                                        draftState.selectedAction = ActionInitialState.selectedAction

                                        let connection = ActionInitialState.selectedConnection as NewConnectionT

                                        connection.connectionsDetails = getInitialConnectionValue(
                                            currentNode.appsList.output.filter(x => x.appId === id && x.latest)[0]
                                                .authentication_type
                                        )

                                        connection = {
                                            ...connection,
                                            selectedConnection: 'Add a new Account',
                                            id: '',
                                            hasError: null,
                                            valid: null,
                                            connectionEditing: false
                                        }

                                        draftState.selectedConnection = connection

                                        draftState.actionInputsList = ActionInitialState.actionInputsList
                                    })

                                    setActionPanelState({
                                        ...actionPanelState,
                                        currentNode: nextState
                                    })

                                    setUserSwitchedApp(true)
                                }
                            }}
                            render={(makeDropDownVisible: () => void) => (
                                <React.Fragment>
                                    <InputContainer style={{ marginBottom: 0 }}>
                                        <Input
                                            type="text"
                                            className={`${(isAssitant ? automationMeta.type =='no_trigger' ?hasLinkedDialogs : active_automation: active_automation) ? 'disabled_with_blue' : ''}`}
                                            value={
                                                search.searching
                                                    ? search.key
                                                    : currentNode.selectedApp.name +
                                                      (currentNode.selectedApp.type == 'on_prem' ? ' (On-Prem)' : '')
                                            }
                                            onFocus={() => {
                                                setSearch({
                                                    inFocus: true,
                                                    searching: true,
                                                    key:
                                                        NodeMode.Edit == actionPanelState.mode.mode ||
                                                        (currentNode.selectedApp.name.length > 0 &&
                                                            currentNode.selectedApp.name.toLowerCase() !== 'select')
                                                            ? currentNode.selectedApp.name
                                                            : ''
                                                })
                                            }}
                                            onBlur={() => {
                                                setSearch({
                                                    ...search,
                                                    inFocus: false
                                                })
                                            }}
                                            onClick={() => makeDropDownVisible()}
                                            onChange={(e: any) => {
                                                setSearch({ ...search, key: e.target.value })
                                            }}
                                            style={{ paddingLeft: currentNode.selectedApp.name === 'Select' ? 10 : 35 }}
                                            ref={apps_ref}
                                        />
                                        <InputContent>Select application</InputContent>
                                        <CancelButtonInput
                                            onClick={() => {
                                                const ref = apps_ref.current

                                                if (ref) {
                                                    setSearch({
                                                        inFocus: true,
                                                        searching: true,
                                                        key:
                                                            NodeMode.Edit == actionPanelState.mode.mode ||
                                                            (currentNode.selectedApp.name.length > 0 &&
                                                                currentNode.selectedApp.name.toLowerCase() !== 'select')
                                                                ? currentNode.selectedApp.name
                                                                : ''
                                                    })

                                                    const iRef = ref as HTMLInputElement
                                                    iRef.focus()
                                                    makeDropDownVisible()
                                                }
                                            }}
                                        >
                                            <DropdownWithCircle />
                                        </CancelButtonInput>
                                    </InputContainer>

                                    {currentNode.selectedApp.name !== 'Select' && (
                                        <div className="input_img_left">
                                            <img src={currentNode.selectedApp.icon} />
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        />
                        {/* <p>Select the application to create an action.</p> */}
                    </div>
                </div>

                {currentNode.selectedApp.name !== 'Select' && (
                    <div className={getProductId() === "ASSISTANT"?"automation_global_form action_popup" : "automation_global_form test" }>
                        <div className="select_work_form">
                            <CanvasRightPanel.AppsDropDown
                                inputs={{
                                    inputs: [
                                        // { action: 'Select' },
                                        ...sortAnArrayAlphabetically(
                                            filterAction(filteredResponse, currentNode.selectedApp.id),
                                            'action'
                                        )
                                    ],
                                    key: 'action',
                                    idKey: 'id'
                                }}
                                addDropDown
                                showVersion
                                updateSelection={(selectedNewApp: string, id: string) => {
                                    if (NodeMode.Add === actionPanelState.mode.mode) {
                                        const nextState = produce(currentNode, draftState => {
                                            draftState.selectedAction = {
                                                ...draftState.selectedAction,
                                                name: selectedNewApp,
                                                id: id,
                                                description: selectedNewApp + ' in ' + currentNode.selectedApp.name
                                            }

                                            if (currentNode.selectedApp.type !== 'on_prem') {
                                                let connection = ActionInitialState.selectedConnection as NewConnectionT

                                                // to change connection type based on app type
                                                // no need to change custom auth state while changing action cuz hitting api only changing application
                                                connection.connectionsDetails = getInitialConnectionValue(
                                                    currentNode.selectedApp.type,
                                                    { fromAction: true, currentNode: actionPanelState.currentNode }
                                                )

                                                connection = {
                                                    ...connection,
                                                    selectedConnection: 'Add a new Account',
                                                    id: '',
                                                    hasError: null,
                                                    valid: null,
                                                    connectionEditing: false
                                                }
                                                draftState.selectedConnection = connection
                                            } else {
                                                let connection = {
                                                    ...currentNode.selectedConnection,
                                                    connectionsDetails: OnpremAuthValue
                                                } as NewConnectionT

                                                connection = {
                                                    ...connection,
                                                    id: '',
                                                    hasError: null,
                                                    valid: null,
                                                    connectionEditing: false
                                                }
                                                draftState.selectedConnection = connection
                                            }

                                            draftState.actionInputsList = ActionInitialState.actionInputsList
                                        })

                                        setActionPanelState({
                                            ...actionPanelState,
                                            currentNode: nextState
                                        })
                                    } else if (selectedNewApp !== 'Select' && id !== currentNode.selectedAction.id) {
                                        const nextState = produce(ActionInitialState, draftState => {
                                            draftState.appsList.output = currentNode.appsList.output

                                            draftState.subflowMetaData = currentNode.subflowMetaData

                                            // draftState.selectedApp = currentNode.selectedApp;

                                            draftState.selectedApp = {
                                                ...currentNode.selectedApp,
                                                type: id
                                                    ? currentNode.appsList.output.filter(
                                                          x => x.appId === currentNode.selectedApp.id && x.latest
                                                      )[0].authentication_type
                                                    : AuthType.None,
                                                version: id
                                                    ? currentNode.appsList.output.filter(
                                                          x => x.appId === currentNode.selectedApp.id && x.latest
                                                      )[0].version
                                                    : ''
                                            }

                                            draftState.actionInputsList.output.input = currentNode.actionInputsList.output.input.map(
                                                x => {
                                                    const value = x.value
                                                    if (
                                                        value &&
                                                        ((typeof value == 'string' || Array.isArray(value))
                                                            ? value.length > 0
                                                            : convertAsaSingleString(
                                                                  convertToRaw(value.getCurrentContent()).blocks
                                                              ).replace(/\s/g, '').length)
                                                    ) {
                                                        return {
                                                            ...x,
                                                            value: ''
                                                        }
                                                    }
                                                    return x
                                                }
                                            )
                                            draftState.connections = currentNode.connections
                                            draftState.selectedAction = {
                                                ...draftState.selectedAction,
                                                name: selectedNewApp,
                                                id: id,
                                                description: selectedNewApp + ' in ' + currentNode.selectedApp.name
                                            }

                                            let connection = ActionInitialState.selectedConnection as NewConnectionT

                                            // to change connection type based on app type
                                            // no need to change custom auth state while changing action cuz hitting api only changing application

                                            const currentAuthType = currentNode.appsList.output.filter(
                                                x => x.appId === currentNode.selectedApp.id && x.latest
                                            )[0].authentication_type

                                            currentNode.selectedApp.type === currentAuthType
                                                ? (connection.connectionsDetails = currentNode.selectedApp.type == 'on_prem'
                                                ? currentNode.selectedConnection.connectionsDetails : getInitialConnectionValue(
                                                      currentNode.selectedApp.type,
                                                      { fromAction: true, currentNode: actionPanelState.currentNode }
                                                  ))
                                                : (connection.connectionsDetails = getInitialConnectionValue(
                                                      currentNode.appsList.output.filter(
                                                          x => x.appId === currentNode.selectedApp.id && x.latest
                                                      )[0].authentication_type
                                                      // { fromAction: true, currentNode: actionPanelState.currentNode }
                                                  ))

                                            connection = {
                                                ...connection,
                                                selectedConnection: 'Add a new Account',
                                                id: '',
                                                hasError: null,
                                                valid: null,
                                                connectionEditing: false
                                            }

                                            draftState.selectedConnection = connection
                                            draftState.actionInputsList = ActionInitialState.actionInputsList

                                            if (
                                                NodeMode.Edit == actionPanelState.mode.mode &&
                                                currentNode.selectedApp.id ==
                                                    actionPanelState.mode.nodeEditInput.meta_data.app.appId
                                            ) {
                                                const mode = actionPanelState.mode as NodeEditT
                                                draftState.errorHandling =
                                                    mode.nodeEditInput.meta_data &&
                                                    mode.nodeEditInput.meta_data.continueIfError
                                                        ? {
                                                              ...draftState.errorHandling,
                                                              errorHandling:
                                                                  mode.nodeEditInput.meta_data.continueIfError,
                                                              errors: mode.nodeEditInput.meta_data.errors
                                                          }
                                                        : { ...draftState.errorHandling }
                                            }
                                        })

                                        setActionPanelState({
                                            ...actionPanelState,
                                            currentNode: nextState
                                        })
                                        setUserSwitchedApp(true)
                                    }
                                }}
                                render={(makeDropDownVisible: () => void) => (
                                    <React.Fragment>
                                        <InputContainer style={{ margin: '20px 0px 0px' }}>
                                            <Input
                                                type="text"
                                                className={`without_caret_input ${
                                                    (isAssitant ? automationMeta.type =='no_trigger' ?hasLinkedDialogs : active_automation: active_automation) ? 'disabled_with_blue' : ''
                                                }`}
                                                value={currentNode.selectedAction.name}
                                                onClick={makeDropDownVisible}
                                                onChange={() => {}}
                                                style={{color: 'transparent', textShadow: '0 0 0 #000'}}
                                            />
                                            {currentNode.selectedAction.name != 'Select' && (
                                                <span className="application_input_version">
                                                    Version :{' '}
                                                    {parseInt(currentNode.selectedApp.version.split('v')[1]) < 10
                                                        ? '0' + currentNode.selectedApp.version.split('v')[1]
                                                        : currentNode.selectedApp.version.split('v')[1]}
                                                </span>
                                            )}
                                            <InputContent>Select action</InputContent>
                                            <CancelButtonInput
                                                onClick={() => {
                                                    makeDropDownVisible()
                                                }}
                                            >
                                                <DropdownWithCircle />
                                            </CancelButtonInput>
                                        </InputContainer>
                                    </React.Fragment>
                                )}
                            />

                            {/* <p>Select an action for the application.</p> */}
                        </div>
                    </div>
                )}
            </InputWrapper>
        </React.Fragment>
    )
}

export function getInitialConnectionValue(
    type: AuthType,
    state?: { fromAction: boolean; currentNode: ActionsResponseT | AppTriggerResponseT }
) {
    return type === AuthType.Apitoken
        ? {
              ...ApiTokenValue,
              value: {
                  ...ApiTokenValue.value,
                  name: '',
                  meta_data: { api_token: '' }
              }
          }
        : type === AuthType.Basic
        ? {
              ...BasicValue,
              value: {
                  ...BasicValue.value,
                  name: '',
                  meta_data: { username: '', password: '' }
              }
          }
        : type === AuthType.Oauth
        ? {
              ...OatuhTokenValue,
              value: {
                  ...OatuhTokenValue.value,
                  name: '',
                  meta_data: { authStoreMap: '' }
              }
          }
        : type === AuthType.Custom
        ? state && state.fromAction
            ? ((() => {
                  const connectionsDetails = state.currentNode.selectedConnection.connectionsDetails as CustomAuthT

                  return produce(connectionsDetails, draft => {
                      draft.value.name = ''
                      draft.customInputs.inputs = draft.customInputs.inputs.map(inp => {
                          return { ...inp, value: '' }
                      })
                  })
              })() as CustomAuthT)
            : {
                  ...CustomAuthValue,
                  customInputs: { id: '', inputs: [] },
                  value: {
                      ...CustomAuthValue.value,
                      name: '',
                      meta_data: {}
                  }
              }
        : state && state.fromAction
        ? ((() => {
              const connectionsDetails = state.currentNode.selectedConnection.connectionsDetails as CustomOAuthT

              return produce(connectionsDetails, draft => {
                  draft.value.name = ''
                  draft.customInputs.inputs = draft.customInputs.inputs.map(inp => {
                      return { ...inp, value: '' }
                  })
              })
          })() as CustomOAuthT)
        : {
              ...CustomOAuthValue,
              customInputs: { id: '', inputs: [] },
              value: {
                  ...CustomOAuthValue.value,
                  name: '',
                  meta_data: {}
              }
          }
}

export function seperateOptionalFields(array: InputFieldT[], required: boolean) {
    return array.filter((input: InputFieldT) => {
        return required ? input.required : !input.required
    })
}

function getAgentConnectionsList(id: string, workspacename: string) {
    return wrapperGetJson(automationAPIURLWithWorkspace(workspacename, `${AutomationOnPremApi.Update}${id}/connection`))
        .then((response: any) => response.data)
        .then(data => {
            return data
        })
        .catch(err => err)
}

ActionPanel.ConnectAccount = (props: any) => {
    // console.log('COG1-7574 :: props', props)
    const { actionPanelState, setActionPanelState } = useContext(ActionPanelStateContext)
    const { automationMeta,active_automation, hasLinkedDialogs } = useContext(SidePanelContext)

    const [setToDeleteConnection, deleteConnection] = props.deleteConnection

    const currentNode = actionPanelState.currentNode as ActionsResponseT

    const workspaceName = props.match.params.workspacename

    const [state,setState] = useState<{[key:string]:boolean}>({})

    const isAssitant = getProductId() === 'ASSISTANT'

    function enableConnectButton() {
        const selectedConnection = currentNode.selectedConnection as NewConnectionT
        const connectionsDetails = selectedConnection.connectionsDetails.value.meta_data
        const convertedArray = convertActionObjectToArray(connectionsDetails, false)

        const enable = convertedArray.filter(meta => {
            return meta.value && meta.value.length > 0
        })

        let isCustomAuthValid: any = undefined

        if (currentNode.selectedApp.type == AuthType.Custom || currentNode.selectedApp.type == AuthType.CustomOauth || currentNode.selectedApp.type == AuthType.PrivateOauth) {
            const connections = selectedConnection.connectionsDetails as CustomAuthT

            isCustomAuthValid =
                connections.customInputs.inputs.filter(inp => {
                    const inpValue = inp.value
                    return (
                        inpValue &&
                        ((typeof inpValue == 'string' || Array.isArray(inpValue))
                            ? inpValue.length > 0
                            : convertAsaSingleString(convertToRaw(inpValue.getCurrentContent()).blocks).replace(
                                  /\s/g,
                                  ''
                              ).length)
                    )
                }).length == connections.customInputs.inputs.length
        }

        return (
            (enable.length === convertedArray.length ||
                selectedConnection.connectionsDetails.type === AuthType.Oauth) &&
            selectedConnection.connectionsDetails.value.name.length > 0 &&
            (isCustomAuthValid == undefined || isCustomAuthValid)
        )
    }

    const createConnection = (actionPanelStat: ActionPanelStateT) => {
        const selectedConnection = actionPanelStat.currentNode.selectedConnection as NewConnectionT

        setActionPanelState({
            ...actionPanelStat,
            currentNode: { ...currentNode, selectedConnection: { ...currentNode.selectedConnection, loading: true } }
        })

        let req = selectedConnection.connectionsDetails.value

        if (currentNode.selectedApp.type == AuthType.Custom) {
            const connection = currentNode.selectedConnection.connectionsDetails as CustomAuthT
            req = {
                ...req,
                meta_data: ramda.mergeAll(
                    connection.customInputs.inputs.map(function(o) {
                        return {
                            [o.key]: o.value
                        }
                    })
                )
            }
        }

        postJson(automationAPIURLWithWorkspace(workspaceName, '/connection/create'))(req)
            .then(response => {
                if (!response.data.error) {
                    testingConnectionWithAPI(actionPanelStat, response.data.output.authStoreMap).then(resp => {
                        if (
                            resp.response &&
                            resp.response.data &&
                            resp.response.data.error &&
                            !resp.response.data.credentials
                        ) {
                            const nextState = produce(actionPanelState.currentNode, draftState => {
                                let connection = draftState.selectedConnection

                                connection.selectedConnection = draftState.selectedConnection.selectedConnection
                                connection.valid = false
                                connection.loading = false
                                connection.hasError =
                                    resp.response && resp.response.data && resp.response.data.error
                                        ? resp.response.data.error
                                        : 'Something went wrong! please try again.'
                                connection.id = draftState.selectedConnection.id
                                draftState.selectedConnection = connection
                            })

                            setActionPanelState({
                                ...actionPanelState,
                                currentNode: nextState
                            })
                        } else {
                            if (resp.data) {
                                let nextState = produce(actionPanelStat.currentNode, draftState => {
                                    let connection = draftState.selectedConnection as OldConnectionT

                                    draftState.connections.output = draftState.connections.output.concat([
                                        {
                                            authStoreMap: response.data.output.authStoreMap,
                                            name: selectedConnection.connectionsDetails.value.name
                                        }
                                    ])

                                    draftState.selectedConnection.type = ConnectionTypeT.old

                                    connection.connectionsDetails = draftState.selectedConnection
                                        .connectionsDetails as NewConnectionTypeT

                                    connection = {
                                        ...connection,
                                        selectedConnection: selectedConnection.connectionsDetails.value.name,
                                        id: response.data.output.authStoreMap,
                                        valid: true,
                                        hasError: null,
                                        loading: false
                                    }

                                    draftState.selectedConnection = connection
                                })
                                setActionPanelState({
                                    ...actionPanelStat,
                                    currentNode: nextState
                                })
                            } else {
                                let nextState = produce(actionPanelStat.currentNode, draftState => {
                                    const connection = NewConnection as NewConnectionT

                                    draftState.connections.output = draftState.connections.output.concat([
                                        {
                                            authStoreMap: response.data.output.authStoreMap,
                                            name: selectedConnection.connectionsDetails.value.name
                                        }
                                    ])

                                    const isCustomAuth =
                                        currentNode.selectedApp.type == AuthType.Custom ||
                                        currentNode.selectedApp.type == AuthType.CustomOauth || currentNode.selectedApp.type == AuthType.PrivateOauth
                                            ? { fromAction: true, currentNode: actionPanelState.currentNode }
                                            : undefined

                                    connection.connectionsDetails = getInitialConnectionValue(
                                        draftState.selectedApp.type,
                                        isCustomAuth
                                    )

                                    connection.selectedConnection = selectedConnection.connectionsDetails.value.name

                                    connection.valid = false
                                    connection.loading = false

                                    connection.connectionEditing = true

                                    connection.hasError = resp.response
                                        ? resp.response.data.error
                                        : resp.data
                                        ? resp.data.error
                                        : 'Something went wrong! please try again.'

                                    connection.id = response.data.output.authStoreMap

                                    if (!isCustomAuth) {
                                        connection.connectionsDetails.value = {
                                            name: selectedConnection.connectionsDetails.value.name,
                                            appId: draftState.selectedApp.id,
                                            type: draftState.selectedApp.type,
                                            workspace: workspaceName,
                                            meta_data: resp.response.data.credentials
                                        }
                                    } else {
                                        const customAuthConnectionDetatils = produce(
                                            connection.connectionsDetails as CustomAuthT,
                                            draft => {
                                                draft.customInputs.inputs = resp.response.data.credentials

                                                draft.value = {
                                                    name: selectedConnection.connectionsDetails.value.name,
                                                    appId: draftState.selectedApp.id,
                                                    type: draftState.selectedApp.type,
                                                    workspace: workspaceName,
                                                    meta_data: ramda.mergeAll(
                                                        resp.response.data.credentials.map(function(o: InputFieldT) {
                                                            return {
                                                                [o.key]: o.value
                                                            }
                                                        })
                                                    )
                                                }
                                            }
                                        )

                                        connection.connectionsDetails = customAuthConnectionDetatils
                                    }

                                    draftState.selectedConnection = connection
                                })

                                setActionPanelState({
                                    ...actionPanelStat,
                                    currentNode: nextState
                                })
                            }
                        }
                    })
                } else {
                    setErrorOnConnectionAPIs(response, actionPanelStat)
                }
            })
            .catch(err => {
                setErrorOnConnectionAPIs(err, actionPanelStat, true)
            })
    }

    const updateConnection = (actionPanelStat: ActionPanelStateT, connectionId: string) => {
        const selectedConnection = actionPanelStat.currentNode.selectedConnection as NewConnectionT

        const { appId, ...rest } = selectedConnection.connectionsDetails.value

        setActionPanelState({
            ...actionPanelStat,
            currentNode: {
                ...actionPanelStat.currentNode,
                selectedConnection: { ...actionPanelStat.currentNode.selectedConnection, loading: true }
            }
        })

        let req = { ...rest, id: connectionId }

        if (currentNode.selectedApp.type == AuthType.Custom) {
            const connection = currentNode.selectedConnection.connectionsDetails as CustomAuthT
            req = {
                ...req,
                meta_data: ramda.mergeAll(
                    connection.customInputs.inputs.map(function(o) {
                        return {
                            [o.key]: o.value
                        }
                    })
                )
            }
        }

        putJson(automationAPIURLWithWorkspace(workspaceName, '/connection/update'))()(req)
            .then(response => {
                if (!response.data.error) {
                    let nextState = produce(currentNode, draftState => {
                        let connection = oldConnection as OldConnectionT

                        draftState.connections.output = draftState.connections.output.map(connect => {
                            if (connect.authStoreMap === connectionId) return { ...connect, name: rest.name }
                            return connect
                        })

                        connection.connectionsDetails = draftState.selectedConnection
                            .connectionsDetails as NewConnectionTypeT

                        connection = {
                            ...connection,
                            selectedConnection: rest.name,
                            id: connectionId,
                            valid: true,
                            loading: false,
                            hasError: null
                        }

                        draftState.selectedConnection = connection
                    })

                    testingConnectionWithAPI(
                        {
                            ...actionPanelStat,
                            currentNode: nextState
                        },
                        connectionId
                    ).then(resp => {
                        if (
                            resp.response &&
                            resp.response.data &&
                            resp.response.data.error &&
                            !resp.response.data.credentials
                        ) {
                            const nextState = produce(actionPanelState.currentNode, draftState => {
                                let connection = draftState.selectedConnection

                                connection.selectedConnection = draftState.selectedConnection.selectedConnection
                                connection.valid = false
                                connection.loading = false
                                connection.hasError =
                                    resp.response && resp.response.data && resp.response.data.error
                                        ? resp.response.data.error
                                        : 'Something went wrong! please try again.'
                                connection.id = draftState.selectedConnection.id
                                draftState.selectedConnection = connection
                            })

                            setActionPanelState({
                                ...actionPanelState,
                                currentNode: nextState
                            })
                        } else {
                            if (resp.data) {
                                setActionPanelState({
                                    ...actionPanelStat,
                                    currentNode: nextState
                                })
                            } else {
                                let nextState = produce(actionPanelStat.currentNode, draftState => {
                                    const connection = NewConnection as NewConnectionT

                                    connection.connectionsDetails = getInitialConnectionValue(
                                        draftState.selectedApp.type
                                    )

                                    connection.selectedConnection = rest.name

                                    connection.valid = false
                                    connection.loading = false

                                    connection.connectionEditing = true

                                    connection.hasError =
                                        resp.response || resp.data
                                            ? resp.response.data.error
                                            : resp.data.error
                                            ? resp.response.data.error
                                            : 'Something went wrong! please try again.'

                                    connection.id = connectionId

                                    const isCustomAuth =
                                        currentNode.selectedApp.type == AuthType.Custom ||
                                        currentNode.selectedApp.type == AuthType.CustomOauth || currentNode.selectedApp.type == AuthType.PrivateOauth
                                            ? { fromAction: true, currentNode: actionPanelState.currentNode }
                                            : undefined

                                    if (!isCustomAuth) {
                                        connection.connectionsDetails.value = {
                                            name: rest.name,
                                            appId: draftState.selectedApp.id,
                                            type: draftState.selectedApp.type,
                                            workspace: workspaceName,
                                            meta_data: resp.response.data.credentials
                                        }
                                    } else {
                                        const customAuthConnectionDetatils = connection.connectionsDetails as CustomAuthT

                                        customAuthConnectionDetatils.customInputs.inputs =
                                            resp.response.data.credentials

                                        customAuthConnectionDetatils.value = {
                                            name: rest.name,
                                            appId: draftState.selectedApp.id,
                                            type: draftState.selectedApp.type,
                                            workspace: workspaceName,
                                            meta_data: ramda.mergeAll(
                                                resp.response.data.credentials.map(function(o: InputFieldT) {
                                                    return {
                                                        [o.key]: o.value
                                                    }
                                                })
                                            )
                                        }

                                        connection.connectionsDetails = customAuthConnectionDetatils
                                    }

                                    draftState.selectedConnection = connection
                                })

                                setActionPanelState({
                                    ...actionPanelStat,
                                    currentNode: nextState
                                })
                            }
                        }
                    })
                } else {
                    setErrorOnConnectionAPIs(response, actionPanelStat)
                }
            })
            .catch(err => {
                setErrorOnConnectionAPIs(err, actionPanelStat)
            })
    }

    function setErrorOnConnectionAPIs(
        resp: any,
        actionPanelState: ActionPanelStateT,
        beforeConnectionCreateError?: boolean
    ) {
        let nextState = produce(actionPanelState.currentNode, draftState => {
            const connection = beforeConnectionCreateError
                ? (draftState.selectedConnection as NewConnectionT)
                : (Object.assign({}, NewConnection) as NewConnectionT)

            let isErrorPresent = false
            let errorMsg

            if (resp.response && resp.response.data && resp.response.data.error) {
                isErrorPresent = true
                errorMsg = resp.response.data.error
            }

            if (beforeConnectionCreateError && !isErrorPresent) {
                const isCustomAuth =
                    currentNode.selectedApp.type == AuthType.Custom ||
                    currentNode.selectedApp.type == AuthType.CustomOauth || currentNode.selectedApp.type == AuthType.PrivateOauth
                        ? { fromAction: true, currentNode: actionPanelState.currentNode }
                        : undefined

                connection.connectionsDetails = getInitialConnectionValue(draftState.selectedApp.type, isCustomAuth)

                connection.selectedConnection = draftState.selectedConnection.selectedConnection

                connection.connectionsDetails.value.name = draftState.selectedConnection.selectedConnection

                connection.id = draftState.selectedConnection.id
            }

            connection.valid = false

            connection.loading = false

            connection.hasError = errorMsg ? errorMsg : 'Something went wrong! please try again.'
            draftState.selectedConnection = connection
        })

        setActionPanelState({
            ...actionPanelState,
            currentNode: nextState
        })
    }

    function testingConnectionWithAPI(actionPanelStat: ActionPanelStateT, authStoreMap: string) {
        return postJson(automationAPIURLWithWorkspace(workspaceName, '/connection/connect'))({
            connectionId: authStoreMap,
            appId: actionPanelStat.currentNode.selectedApp.id,
            version: actionPanelStat.currentNode.selectedApp.version
        })
            .then(res => {
                return res
            })
            .catch(err => {
                return err
            })
    }

    function testConnection(authStoreMap: string) {
        setActionPanelState({
            ...actionPanelState,
            currentNode: {
                ...currentNode,
                selectedConnection: { ...currentNode.selectedConnection, loading: true }
            }
        })

        testingConnectionWithAPI(actionPanelState, authStoreMap)
            .then(resp => {
                logEvent(workspaceName, {
                    event:"EVENT::ON_PREM::ADD_CONNECTION_TO_WORKFLOW", event_meta:{
                        name:automationMeta.name,
                        description:automationMeta.description
                    }
                })

                if (
                    resp.response &&
                    resp.response.data &&
                    resp.response.data.error &&
                    !resp.response.data.credentials
                ) {
                    const nextState = produce(actionPanelState.currentNode, draftState => {
                        let connection = draftState.selectedConnection

                        connection.selectedConnection = draftState.selectedConnection.selectedConnection
                        connection.valid = false
                        connection.loading = false
                        connection.hasError =
                            resp.response && resp.response.data && resp.response.data.error
                                ? resp.response.data.error
                                : 'Something went wrong! please try again.'
                        connection.id = draftState.selectedConnection.id
                        draftState.selectedConnection = connection
                    })

                    setActionPanelState({
                        ...actionPanelState,
                        currentNode: nextState
                    })
                } else {
                    if (resp.data) {
                        const nextState = produce(currentNode, draftState => {
                            const connection = draftState.selectedConnection as OldConnectionT
                            connection.valid = true
                            connection.hasError = null
                            connection.loading = false
                            draftState.selectedConnection = connection

                            draftState.actionInputsList = {
                                ...draftState.actionInputsList,
                                output: {
                                    ...draftState.actionInputsList.output,
                                    input: draftState.actionInputsList.output.input.map(input => {
                                        if (input.config.kind === 'lookup-enum') {
                                            return { ...input, value: emptyEditorState as any }
                                        }
                                        return input
                                    })
                                }
                            }
                        })
                        setActionPanelState({
                            ...actionPanelState,
                            currentNode: nextState
                        })
                    } else {
                        const nextState = produce(actionPanelState.currentNode, draftState => {
                            let connection =
                                currentNode.selectedApp.type != 'on_prem'
                                    ? (NewConnection as NewConnectionT)
                                    : draftState.selectedConnection

                            const isCustomAuth =
                                currentNode.selectedApp.type == AuthType.Custom ||
                                currentNode.selectedApp.type == AuthType.CustomOauth || currentNode.selectedApp.type == AuthType.PrivateOauth
                                    ? { fromAction: true, currentNode: actionPanelState.currentNode }
                                    : undefined

                            connection.connectionsDetails =
                                currentNode.selectedApp.type == 'on_prem'
                                    ? currentNode.selectedConnection.connectionsDetails
                                    : getInitialConnectionValue(draftState.selectedApp.type, isCustomAuth)

                            connection.selectedConnection = draftState.selectedConnection.selectedConnection

                            connection.valid = false
                            connection.loading = false
                            connection.hasError =
                                resp.response && resp.response.data && resp.response.data.error
                                    ? resp.response.data.error
                                    : 'Something went wrong! please try again.'
                            connection.id = draftState.selectedConnection.id

                            if (currentNode.selectedApp.type != 'on_prem') {
                                const newConnection = connection as NewConnectionT

                                if (!isCustomAuth) {
                                    newConnection.connectionsDetails.value = {
                                        name: draftState.selectedConnection.selectedConnection,
                                        appId: draftState.selectedApp.id,
                                        type: draftState.selectedApp.type,
                                        workspace: workspaceName,
                                        meta_data: resp.response.data.credentials
                                    }
                                } else {
                                    newConnection.connectionsDetails = produce(
                                        connection.connectionsDetails as CustomAuthT,
                                        draft => {
                                            draft.customInputs.inputs = resp.response.data.credentials

                                            draft.value = {
                                                name: draftState.selectedConnection.selectedConnection,
                                                appId: draftState.selectedApp.id,
                                                type: draftState.selectedApp.type,
                                                workspace: workspaceName,
                                                meta_data: ramda.mergeAll(
                                                    resp.response.data.credentials.map(function(o: InputFieldT) {
                                                        return {
                                                            [o.key]: o.value
                                                        }
                                                    })
                                                )
                                            }
                                        }
                                    )
                                }

                                newConnection.connectionEditing = true
                                connection = newConnection
                            }
                            draftState.selectedConnection = connection
                        })

                        setActionPanelState({
                            ...actionPanelState,
                            currentNode: nextState
                        })
                    }
                }
            })
            .catch(err => console.log(err))
    }

    function populateCustomAuthUI() {
        const selectedConnection = currentNode.selectedConnection as NewConnectionT

        const connectionsDetails = selectedConnection.connectionsDetails as CustomAuthT

        return (
            <React.Fragment>
                <InputContainer className="select_work_form">
                    {selectedConnection.error.show && (
                        <p className="error_msg" style={{ color: 'orange', float: 'right' }}>
                            {selectedConnection.error.info}
                        </p>
                    )}

                    <Input
                        type="text"
                        onChange={(e: any) => {
                            const name = currentNode.connections.output.filter(
                                x => x.name.toLowerCase() === e.target.value.toLowerCase()
                            )

                            const nextState = produce(selectedConnection, draftState => {
                                draftState.connectionsDetails.value.name = e.target.value
                                draftState.connectionsDetails.value.type = currentNode.selectedApp.type
                                draftState.valid = name.length > 0 && !draftState.connectionEditing ? false : null
                                draftState.hasError =
                                    name.length > 0 && !draftState.connectionEditing
                                        ? 'Duplicate connection name'
                                        : null
                            })

                            setActionPanelState({
                                ...actionPanelState,
                                currentNode: {
                                    ...currentNode,
                                    selectedConnection: nextState
                                }
                            })
                        }}
                        value={currentNode.selectedConnection.connectionsDetails.value.name}
                    />
                    <InputContent>
                        Name for this Account{' '}
                        <Tooltip
                            className="target customTip _description"
                            zIndex={10000}
                            tagName="span"
                            content="Provide a new name for the connected account."
                            distance={15}
                            forceDirection={true}
                            direction="up-start"
                        >
                            <InfoIcon />
                        </Tooltip>
                    </InputContent>
                </InputContainer>
                {connectionsDetails.customInputs.inputs.map((inp, i) => {
                    return (
                        <InputContainer className="select_work_form" key={i}>
                            <Input
                                type={ state[`${inp.displayTitle+i}`]? "text" : inp.config.kind=='password'? inp.config.kind: "password"}
                                onChange={(e: any) => {
                                    const selectedConnection = currentNode.selectedConnection as NewConnectionT
                                    const connectionsDetails = currentNode.selectedConnection
                                        .connectionsDetails as CustomAuthT

                                    const name = currentNode.connections.output.filter(
                                        x => x.name.toLowerCase() === connectionsDetails.value.name.toLowerCase()
                                    )

                                    const nextState = produce(selectedConnection, draftState => {
                                        connectionsDetails.customInputs.inputs[i].value = e.target.value
                                        draftState.connectionsDetails = connectionsDetails
                                        draftState.connectionsDetails.value.appId = currentNode.selectedApp.id
                                        draftState.connectionsDetails.value.workspace = workspaceName
                                        draftState.valid =
                                            name.length > 0 && !draftState.connectionEditing ? false : null
                                        draftState.hasError =
                                            name.length > 0 && !draftState.connectionEditing
                                                ? 'Duplicate connection name'
                                                : null
                                    })

                                    setActionPanelState({
                                        ...actionPanelState,
                                        currentNode: {
                                            ...currentNode,
                                            selectedConnection: nextState
                                        }
                                    })
                                }}
                                value={inp.value ? (inp.value as string) : ''}
                            />
                            <InputContent>
                                {`Enter ${inp.displayTitle}`}{' '}
                                <Tooltip
                                    className="target customTip _description"
                                    zIndex={10000}
                                    tagName="span"
                                    content={inp.description}
                                    distance={15}
                                    forceDirection={true}
                                    direction="up-start"
                                >
                                    <InfoIcon />
                                </Tooltip>
                            </InputContent>
                            <CancelButton
                                        className="d-flex"
                                        onClick={() =>
                                            setState({
                                                ...state,
                                                [`${inp.displayTitle+i}`]: state[`${inp.displayTitle+i}`] ? false : true
                                            })
                                        }
                                        style={{ top:6,cursor:'pointer' }}
                                    >
                                        {state[`${inp.displayTitle+i}`] ? <EyeOpenIcon /> : <EyeCloseIcon />}
                            </CancelButton>
                        </InputContainer>
                    )
                })}
            </React.Fragment>
        )
    }

    const deleteConnectionCallback = async (connectionId: string) => {
        let nextState = produce(actionPanelState.currentNode, draftState => {
            currentNode.connections.output = currentNode.connections.output.filter(x => x.authStoreMap !== connectionId)
            let connection = ActionInitialState.selectedConnection as NewConnectionT
            connection.connectionsDetails = getInitialConnectionValue(currentNode.selectedApp.type, {
                fromAction: true,
                currentNode: actionPanelState.currentNode
            })

            connection = {
                ...connection,
                selectedConnection: 'Add a new Account',
                id: '',
                hasError: null,
                valid: null,
                connectionEditing: false
            }
            draftState.selectedConnection = connection
        })

        props.refetchWorkflowData()
        // const promise = ramda.clone(props.customJsonApi)

        // const response = await promise()

        // // to update tree nodes
        // !response.response && props.setResponseData({ ...props, state: { type: 'success', data: response } })

        setActionPanelState({
            ...actionPanelState,
            currentNode: nextState
        })
    }
    function enableRemoveButton() {
        return currentNode.selectedConnection.type !== ConnectionTypeT.new || currentNode.selectedConnection.hasError
    }

    function populateOnPremOrOtherConnection() {
        if (currentNode.selectedApp.type == 'on_prem') {
            const connectionsDetails = currentNode.selectedConnection.connectionsDetails as OnPremAuthT
            return (
                <>
                    <div className="select_work_form">
                        <h4>Select Agent</h4>
                        <CanvasRightPanel.AppsDropDown
                            inputs={{
                                inputs: [...(connectionsDetails.agents ? connectionsDetails.agents : [])],
                                key: 'name',
                                idKey: 'id'
                            }}
                            addDropDown
                            dataForNoData="No Agent found"
                            updateSelection={(selectedAgent: string, id: string) => {
                                getAgentConnectionsList(id, workspaceName)
                                    .then(response => {
                                        if (connectionsDetails.selectedAgent == selectedAgent) {
                                            const nextState = produce(currentNode, draftState => {
                                                let connection = draftState.selectedConnection as OldConnectionT
                                                const conDet = connection.connectionsDetails as OnPremAuthT

                                                connection.connectionsDetails = {
                                                    ...conDet,
                                                    connections: response.output,
                                                    selectedAgent: selectedAgent,
                                                    value: { ...conDet.value, meta_data: { agent: id, connection: '' } }
                                                }
                                                draftState.selectedConnection = connection
                                            })

                                            setActionPanelState({
                                                ...actionPanelState,
                                                currentNode: nextState
                                            })
                                        } else {
                                            const nextState = produce(currentNode, draftState => {
                                                let connection = {
                                                    ...ActionInitialState.selectedConnection,
                                                    selectedConnection: 'Add a new Account',
                                                    id: '',
                                                    hasError: null,
                                                    valid: null,
                                                    connectionsDetails: OnpremAuthValue
                                                } as OldConnectionT
                                                const conDet = connection.connectionsDetails as OnPremAuthT

                                                connection.connectionsDetails = {
                                                    ...conDet,
                                                    connections: response.output,
                                                    selectedAgent: selectedAgent,
                                                    agents: connectionsDetails.agents,
                                                    value: { ...conDet.value, meta_data: { agent: id, connection: '' } }
                                                }
                                                draftState.selectedConnection = connection
                                            })

                                            setActionPanelState({
                                                ...actionPanelState,
                                                currentNode: nextState
                                            })
                                        }
                                    })
                                    .catch(() => {
                                        const nextState = produce(currentNode, draftState => {
                                            let connection = draftState.selectedConnection as OldConnectionT
                                            const conDet = connection.connectionsDetails as OnPremAuthT
                                            connection.connectionsDetails = {
                                                ...conDet,
                                                selectedAgent: selectedAgent,
                                                value: { ...conDet.value, meta_data: { agent: id, connection: '' } }
                                            }
                                            draftState.selectedConnection = connection
                                        })

                                        setActionPanelState({
                                            ...actionPanelState,
                                            currentNode: nextState
                                        })
                                    })
                            }}
                            render={(makeDropDownVisible: () => void) => (
                                <>
                                    <InputContainer>
                                        <Input
                                            type="text"
                                            className="without_caret_input"
                                            value={connectionsDetails.selectedAgent}
                                            onChange={() => {}}
                                            onClick={makeDropDownVisible}
                                        />
                                        <InputContent>{connectionsDetails.selectedAgent}</InputContent>
                                    </InputContainer>
                                    <CancelButtonInput
                                        style={{ marginTop: 23, cursor: 'pointer' }}
                                        onClick={makeDropDownVisible}
                                    >
                                        <DropdownWithCircle />
                                    </CancelButtonInput>
                                </>
                            )}
                        />

                        {/* <p>Select an Agent from the list.</p> */}
                    </div>
                    <div className="select_work_form action_popup_connection">
                        <h4>Select Connection</h4>
                        <CanvasRightPanel.AppsDropDown
                            inputs={{
                                inputs: [
                                    ...(connectionsDetails.connections
                                        ? connectionsDetails.connections.filter(
                                              conn => conn.appId == currentNode.selectedApp.id
                                          )
                                        : [])
                                ],
                                key: 'name',
                                idKey: 'id'
                            }}
                            addDropDown
                            dataForNoData="No Connection found"
                            updateSelection={(selectedConnection: string, id: string) => {
                                const nextState = produce(currentNode, draftState => {
                                    let connection = draftState.selectedConnection as OldConnectionT
                                    connection.type = ConnectionTypeT.old
                                    const conDet = connection.connectionsDetails as OnPremAuthT
                                    connection.selectedConnection = selectedConnection
                                    connection.valid = null
                                    connection.id = id
                                    connection.connectionsDetails = {
                                        ...conDet,
                                        value: {
                                            ...conDet.value,
                                            name: selectedConnection,
                                            type: AuthType.OnPrem,
                                            appId: currentNode.selectedApp.id,
                                            workspace: workspaceName,
                                            meta_data: { ...conDet.value.meta_data, connection: id }
                                        }
                                    }
                                    draftState.selectedConnection = connection
                                })

                                setActionPanelState({
                                    ...actionPanelState,
                                    currentNode: nextState
                                })
                            }}
                            render={(makeDropDownVisible: () => void) => (
                                <>
                                    <InputContainer>
                                        <Input
                                            type="text"
                                            className="without_caret_input"
                                            value={
                                                currentNode.selectedConnection.id.length > 0
                                                    ? currentNode.selectedConnection.selectedConnection
                                                    : 'Select'
                                            }
                                            onChange={() => {}}
                                            onClick={makeDropDownVisible}
                                        />
                                        <InputContent>Select</InputContent>

                                        <OnPremCancelButtonInput onClick={makeDropDownVisible}>
                                            <DropdownWithCircle />
                                        </OnPremCancelButtonInput>
                                        <p>Select a connection to connect with the application.</p>
                                    </InputContainer>
                                </>
                            )}
                        />
                    </div>
                </>
            )
        } else {
            return (
                <div className="select_work_form autoamte_product_account_dropdown">
                    <Header>
                        Connect application
                        {/* <span className="exisitng_account">
                            {' '}
                            (You have {currentNode.connections.output.length} existing accounts)
                        </span> */}
                    </Header>
                    <CanvasRightPanel.AppsDropDown
                        inputs={{
                            inputs: [...currentNode.connections.output, DefaultSelectedConnection],
                            key: 'name',
                            idKey: 'authStoreMap'
                        }}
                        addDropDown
                        updateSelection={(selectedConnection: string, id: string) => {
                            if (currentNode.selectedConnection.id !== id) {
                                const nextState = produce(currentNode, draftState => {
                                    let connection = draftState.selectedConnection as NewConnectionT | OldConnectionT

                                    const isCustomAuth =
                                        currentNode.selectedApp.type == AuthType.Custom ||
                                        currentNode.selectedApp.type == AuthType.CustomOauth || currentNode.selectedApp.type == AuthType.PrivateOauth
                                            ? { fromAction: true, currentNode: actionPanelState.currentNode }
                                            : undefined

                                    if (!isCustomAuth) {
                                        connection =
                                            selectedConnection !== 'Add a new Account' ? oldConnection : NewConnection
                                    } else {
                                        connection =
                                            selectedConnection !== 'Add a new Account'
                                                ? ({
                                                      ...oldConnection,
                                                      connectionsDetails: {
                                                          ...draftState.selectedConnection.connectionsDetails
                                                      }
                                                  } as OldConnectionT)
                                                : NewConnection
                                    }

                                    connection.connectionsDetails =
                                        selectedConnection === 'Add a new Account'
                                            ? // to change connection type based on app type
                                              getInitialConnectionValue(currentNode.selectedApp.type, isCustomAuth)
                                            : connection.connectionsDetails

                                    connection.selectedConnection = selectedConnection
                                    connection.id = id ? id : ''
                                    connection.valid = null
                                    connection.hasError = null

                                    if (selectedConnection === 'Add a new Account') {
                                        const currentConnection = { ...connection,type: ConnectionTypeT.new } as NewConnectionT
                                        currentConnection.connectionEditing = false
                                        connection = currentConnection
                                    }

                                    draftState.selectedConnection = connection
                                })

                                setActionPanelState({
                                    ...actionPanelState,
                                    currentNode: nextState
                                })
                            }
                        }}
                        render={(makeDropDownVisible: () => void) => (
                            <>
                                <InputContainer>
                                    <Input
                                        type="text"
                                        onChange={() => {}}
                                        onClick={makeDropDownVisible}
                                        className={(isAssitant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?"without_caret_input":"without_caret_input editor_btn_disabled"}
                                        value={currentNode.selectedConnection.selectedConnection}
                                    />
                                    <span className="application_input_version">
                                        Accounts : {currentNode.connections.output.length}{' '}
                                    </span>
                                    <CancelButtonInput onClick={makeDropDownVisible}>
                                        <DropdownWithCircle />
                                    </CancelButtonInput>
                                    <InputContent>
                                        Connect your application account
                                        <Tooltip
                                            className="target customTip _description"
                                            zIndex={10000}
                                            tagName="span"
                                            content="Add a new app account or select from existing account."
                                            distance={15}
                                            forceDirection={true}
                                            direction="up-start"
                                        >
                                            <InfoIcon />
                                        </Tooltip>
                                    </InputContent>
                                </InputContainer>
                            </>
                        )}
                    />
                </div>
            )
        }
    }

    return (
        <InputWrapper className="connection_new_autoamtion">
            <div className="automation_global_form">
                {populateOnPremOrOtherConnection()}
                {currentNode.selectedConnection.type === ConnectionTypeT.new &&
                    (currentNode.selectedApp.type === AuthType.Custom ||
                        currentNode.selectedApp.type == AuthType.CustomOauth || currentNode.selectedApp.type == AuthType.PrivateOauth) &&
                    populateCustomAuthUI()}

                {currentNode.selectedConnection.type === ConnectionTypeT.new &&
                    currentNode.selectedApp.type === AuthType.Basic && (
                        <React.Fragment>
                            <InputContainer className="select_work_form">
                                {currentNode.selectedConnection.error.show && (
                                    <p className="error_msg" style={{ color: 'orange', float: 'right' }}>
                                        {currentNode.selectedConnection.error.info}
                                    </p>
                                )}
                                <Input
                                    type="text"
                                    onChange={(e: any) => {
                                        const selectedConnection = currentNode.selectedConnection as NewConnectionT

                                        const name = currentNode.connections.output.filter(
                                            x => x.name.toLowerCase() === e.target.value.toLowerCase()
                                        )

                                        const nextState = produce(selectedConnection, draftState => {
                                            draftState.connectionsDetails.value.name = e.target.value
                                            draftState.valid =
                                                name.length > 0 && !draftState.connectionEditing ? false : null
                                            draftState.hasError =
                                                name.length > 0 && !draftState.connectionEditing
                                                    ? 'Duplicate connection name'
                                                    : null
                                        })

                                        setActionPanelState({
                                            ...actionPanelState,
                                            currentNode: {
                                                ...currentNode,
                                                selectedConnection: nextState
                                            }
                                        })
                                    }}
                                    value={currentNode.selectedConnection.connectionsDetails.value.name}
                                />
                                <InputContent>
                                    Name for this Account{' '}
                                    <Tooltip
                                        className="target customTip _description"
                                        zIndex={10000}
                                        tagName="span"
                                        content="Provide a new name for the connected account."
                                        distance={15}
                                        forceDirection={true}
                                        direction="up-start"
                                    >
                                        <InfoIcon />
                                    </Tooltip>
                                </InputContent>
                            </InputContainer>

                            <div className="select_work_form">
                                <Header>Username</Header>
                                <InputContainer>
                                    <Input
                                        type="text"
                                        onChange={(e: any) => {
                                            const selectedConnection = currentNode.selectedConnection as NewConnectionT
                                            const connectionsDetails = currentNode.selectedConnection
                                                .connectionsDetails as BasicT

                                            const name = currentNode.connections.output.filter(
                                                x =>
                                                    x.name.toLowerCase() === connectionsDetails.value.name.toLowerCase()
                                            )

                                            const nextState = produce(selectedConnection, draftState => {
                                                connectionsDetails.value.meta_data.username = e.target.value
                                                draftState.connectionsDetails = connectionsDetails
                                                draftState.connectionsDetails.value.appId = currentNode.selectedApp.id
                                                draftState.connectionsDetails.value.workspace = workspaceName
                                                draftState.valid =
                                                    name.length > 0 && !draftState.connectionEditing ? false : null
                                                draftState.hasError =
                                                    name.length > 0 && !draftState.connectionEditing
                                                        ? 'Duplicate connection name'
                                                        : null
                                            })

                                            setActionPanelState({
                                                ...actionPanelState,
                                                currentNode: {
                                                    ...currentNode,
                                                    selectedConnection: nextState
                                                }
                                            })
                                        }}
                                        value={
                                            currentNode.selectedConnection.connectionsDetails.type === AuthType.Basic
                                                ? currentNode.selectedConnection.connectionsDetails.value.meta_data
                                                      .username
                                                : ''
                                        }
                                    />
                                    <InputContent>Enter user name</InputContent>
                                </InputContainer>
                            </div>
                            <div className="select_work_form">
                                <Header>Password</Header>
                                <InputContainer>
                                    <Input
                                        type={state["basicPasswordEyeOpen"] ? "text" : "password"}
                                        onChange={(e: any) => {
                                            const selectedConnection = currentNode.selectedConnection as NewConnectionT
                                            const connectionsDetails = currentNode.selectedConnection
                                                .connectionsDetails as BasicT

                                            const name = currentNode.connections.output.filter(
                                                x =>
                                                    x.name.toLowerCase() === connectionsDetails.value.name.toLowerCase()
                                            )

                                            const nextState = produce(selectedConnection, draftState => {
                                                connectionsDetails.value.meta_data.password = e.target.value
                                                draftState.connectionsDetails = connectionsDetails
                                                draftState.connectionsDetails.value.appId = currentNode.selectedApp.id
                                                draftState.connectionsDetails.value.workspace = workspaceName
                                                draftState.valid =
                                                    name.length > 0 && !draftState.connectionEditing ? false : null
                                                draftState.hasError =
                                                    name.length > 0 && !draftState.connectionEditing
                                                        ? 'Duplicate connection name'
                                                        : null
                                            })

                                            setActionPanelState({
                                                ...actionPanelState,
                                                currentNode: {
                                                    ...currentNode,
                                                    selectedConnection: nextState
                                                }
                                            })
                                        }}
                                        value={
                                            currentNode.selectedConnection.connectionsDetails.type === AuthType.Basic
                                                ? currentNode.selectedConnection.connectionsDetails.value.meta_data
                                                      .password
                                                : ''
                                        }
                                    />
                                    <InputContent>Enter password</InputContent>
                                    <CancelButton
                                        className="d-flex"
                                        onClick={() =>
                                            setState({
                                                basicPasswordEyeOpen: state["basicPasswordEyeOpen"] ? false : true
                                            })
                                        }
                                        style={{ top:15,cursor:'pointer' }}
                                        >
                                        {state["basicPasswordEyeOpen"] ? <EyeOpenIcon /> : <EyeCloseIcon />}
                                    </CancelButton>
                                </InputContainer>
                            </div>
                        </React.Fragment>
                    )}

                {currentNode.selectedConnection.type === ConnectionTypeT.new &&
                    currentNode.selectedApp.type === AuthType.Apitoken && (
                        <React.Fragment>
                            <div className="select_work_form">
                                <Header>Name for this Account</Header>
                                {currentNode.selectedConnection.error.show && (
                                    <p className="error_msg" style={{ color: 'orange', float: 'right' }}>
                                        {currentNode.selectedConnection.error.info}
                                    </p>
                                )}
                                <InputContainer>
                                    <Input
                                        type="text"
                                        onChange={(e: any) => {
                                            const selectedConnection = currentNode.selectedConnection as NewConnectionT

                                            const name = currentNode.connections.output.filter(
                                                x => x.name.toLowerCase() === e.target.value.toLowerCase()
                                            )

                                            const nextState = produce(selectedConnection, draftState => {
                                                draftState.connectionsDetails.value.name = e.target.value
                                                draftState.valid =
                                                    name.length > 0 && !draftState.connectionEditing ? false : null
                                                draftState.hasError =
                                                    name.length > 0 && !draftState.connectionEditing
                                                        ? 'Duplicate connection name'
                                                        : null
                                            })

                                            setActionPanelState({
                                                ...actionPanelState,
                                                currentNode: {
                                                    ...currentNode,
                                                    selectedConnection: nextState
                                                }
                                            })
                                        }}
                                        value={currentNode.selectedConnection.connectionsDetails.value.name}
                                    />
                                    <InputContent>
                                        Enter a name for this Account
                                        <Tooltip
                                            className="target customTip _description"
                                            zIndex={10000}
                                            tagName="span"
                                            content="Provide a new name for the connected account"
                                            distance={15}
                                            forceDirection={true}
                                            direction="up-start"
                                        >
                                            <InfoIcon />
                                        </Tooltip>
                                    </InputContent>
                                </InputContainer>
                            </div>

                            <div className="select_work_form">
                                <Header>API token</Header>
                                <InputContainer>
                                    <Input
                                        type={state["apiTokenEyeOpen"] ? "text" : "password"}
                                        onChange={(e: any) => {
                                            const selectedConnection = currentNode.selectedConnection as NewConnectionT
                                            const connectionsDetails = currentNode.selectedConnection
                                                .connectionsDetails as ApitokenT

                                            const name = currentNode.connections.output.filter(
                                                x =>
                                                    x.name.toLowerCase() === connectionsDetails.value.name.toLowerCase()
                                            )

                                            const nextState = produce(selectedConnection, draftState => {
                                                connectionsDetails.value.meta_data.api_token = e.target.value
                                                draftState.connectionsDetails = connectionsDetails
                                                draftState.connectionsDetails.value.appId = currentNode.selectedApp.id
                                                draftState.connectionsDetails.value.workspace = workspaceName
                                                draftState.valid =
                                                    name.length > 0 && !draftState.connectionEditing ? false : null
                                                draftState.hasError =
                                                    name.length > 0 && !draftState.connectionEditing
                                                        ? 'Duplicate connection name'
                                                        : null
                                            })

                                            setActionPanelState({
                                                ...actionPanelState,
                                                currentNode: {
                                                    ...currentNode,
                                                    selectedConnection: nextState
                                                }
                                            })
                                        }}
                                        value={
                                            currentNode.selectedConnection.connectionsDetails.type ===
                                                AuthType.Apitoken &&
                                            currentNode.selectedConnection.connectionsDetails.value.meta_data
                                                ? currentNode.selectedConnection.connectionsDetails.value.meta_data
                                                      .api_token
                                                : ''
                                        }
                                    />
                                    <InputContent>Enter API token</InputContent>
                                    <CancelButton
                                        className="d-flex"
                                        onClick={() =>
                                            setState({
                                                ...state,
                                                apiTokenEyeOpen: state["apiTokenEyeOpen"] ? false : true
                                            })
                                        }
                                        style={{ top:15,cursor:'pointer' }}
                                    >
                                        {state["apiTokenEyeOpen"] ? <EyeOpenIcon /> : <EyeCloseIcon />}
                                    </CancelButton>
                                </InputContainer>
                            </div>
                        </React.Fragment>
                    )}

                {currentNode.selectedConnection.type === ConnectionTypeT.new &&
                    currentNode.selectedApp.type === AuthType.Oauth && (
                        <React.Fragment>
                            <div className="select_work_form">
                                <Header>Name for this Account</Header>
                                {currentNode.selectedConnection.error.show && (
                                    <p className="error_msg" style={{ color: 'orange', float: 'right' }}>
                                        {currentNode.selectedConnection.error.info}
                                    </p>
                                )}
                                <InputContainer>
                                    <Input
                                        type="text"
                                        onChange={(e: any) => {
                                            const selectedConnection = currentNode.selectedConnection as NewConnectionT

                                            const name = currentNode.connections.output.filter(
                                                x => x.name.toLowerCase() === e.target.value.toLowerCase()
                                            )

                                            const nextState = produce(selectedConnection, draftState => {
                                                draftState.connectionsDetails.value.name = e.target.value
                                                draftState.valid =
                                                    name.length > 0 && !draftState.connectionEditing ? false : null
                                                draftState.hasError =
                                                    name.length > 0 && !draftState.connectionEditing
                                                        ? 'Duplicate connection name'
                                                        : null
                                            })

                                            setActionPanelState({
                                                ...actionPanelState,
                                                currentNode: {
                                                    ...currentNode,
                                                    selectedConnection: nextState
                                                }
                                            })
                                        }}
                                        value={currentNode.selectedConnection.connectionsDetails.value.name}
                                    />
                                    <InputContent>
                                        Enter a name for this Account
                                        <Tooltip
                                            className="target customTip _description"
                                            zIndex={10000}
                                            tagName="span"
                                            content="Provide a new name for the connected account."
                                            distance={15}
                                            forceDirection={true}
                                            direction="up-start"
                                        >
                                            <InfoIcon />
                                        </Tooltip>
                                    </InputContent>
                                    <p></p>
                                </InputContainer>
                            </div>
                        </React.Fragment>
                    )}

                <ButtonContainer className="connection_new_autoamtion_button action_popup">
                    {currentNode.selectedConnection.type === ConnectionTypeT.old && (
                        <React.Fragment>
                            <Button
                                className={
                                    !currentNode.selectedConnection.loading &&
                                    (currentNode.selectedConnection.hasError ||
                                        !currentNode.selectedConnection.valid) &&
                                    currentNode.selectedConnection.id.length > 0 && (isAssitant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)
                                        ? ' '
                                        : 'editor_btn_disabled'
                                }
                                onClick={() => {
                                    testConnection(currentNode.selectedConnection.id)
                                }}
                                disabled={
                                    currentNode.selectedConnection.loading ||
                                    currentNode.selectedConnection.id.length == 0 ||
                                    currentNode.selectedConnection.valid === true
                                }
                            >
                                <label>
                                    {currentNode.selectedConnection.valid && !currentNode.selectedConnection.hasError
                                        ? 'Connected'
                                        : 'Test Connection'}
                                </label>
                            </Button>

                            {currentNode.selectedConnection.loading && (
                                <span className="connect_account_loading">
                                    <img src={LoadingSvgImage} />
                                </span>
                            )}

                            {currentNode.selectedConnection.valid && !currentNode.selectedConnection.hasError ? (
                                <span className="connect_account_success">
                                    <GreenTickIcon />
                                </span>
                            ) : (
                                currentNode.selectedConnection.valid !== null && (
                                    <span className="connect_account_failed">
                                        <div className="error_input_show">
                                            <WarningAlertIcon />
                                            <span className="error_menu_hvr">
                                                {currentNode.selectedConnection.hasError}
                                            </span>
                                        </div>
                                    </span>
                                )
                            )}
                        </React.Fragment>
                    )}

                    {currentNode.selectedConnection.type === ConnectionTypeT.new && (
                        <React.Fragment>
                            <Button
                                className={
                                    currentNode.selectedConnection.loading ||
                                    !(enableConnectButton() && currentNode.selectedConnection.valid !== false) || (isAssitant ? automationMeta.type =='no_trigger' ?false : active_automation: active_automation)
                                        ? ' editor_btn_disabled'
                                        : ' '
                                }
                                onClick={() => {
                                    const selectedConnection = currentNode.selectedConnection as NewConnectionT

                                    const callback = !selectedConnection.connectionEditing
                                        ? createConnection
                                        : updateConnection
                                    if (
                                        currentNode.selectedApp.type === AuthType.Oauth ||
                                        currentNode.selectedApp.type === AuthType.CustomOauth || 
                                        currentNode.selectedApp.type == AuthType.PrivateOauth
                                    ) {
                                        listenForExternalToken(currentNode.selectedApp.id, callback, workspaceName, {
                                            actionPanelState,
                                            setActionPanelState
                                        })
                                    } else {
                                        if (!selectedConnection.connectionEditing) {
                                            createConnection(actionPanelState)
                                        } else {
                                            updateConnection(actionPanelState, currentNode.selectedConnection.id)
                                        }
                                    }
                                }}
                                disabled={
                                    !(enableConnectButton() && currentNode.selectedConnection.valid !== false) ||
                                    currentNode.selectedConnection.loading
                                }
                            >
                                <label>
                                    {currentNode.selectedConnection.connectionEditing ? 'Update ' : 'Connect '} Account
                                </label>
                            </Button>

                            {currentNode.selectedConnection.loading && (
                                <span className="connect_account_loading">
                                    <img src={LoadingSvgImage} />
                                </span>
                            )}

                            {currentNode.selectedConnection.valid && !currentNode.selectedConnection.hasError ? (
                                <span className="connect_account_success">
                                    <GreenTickIcon />
                                </span>
                            ) : (
                                currentNode.selectedConnection.valid !== null && (
                                    <span className="connect_account_failed">
                                        <div className="error_input_show">
                                            <WarningAlertIcon />
                                            <span className="error_menu_hvr">
                                                {currentNode.selectedConnection.hasError}
                                            </span>
                                        </div>
                                    </span>
                                )
                            )}
                        </React.Fragment>
                    )}
                    <Button
                        primary
                        className={enableRemoveButton() && (isAssitant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation) ? ' ' : 'editor_btn_disabled'}
                        onClick={() =>
                            setToDeleteConnection(
                                currentNode.selectedConnection.id,
                                currentNode.selectedConnection.connectionsDetails.type,
                                deleteConnectionCallback,
                                wrapPromise(fetchAutomations(workspaceName, currentNode.selectedConnection.id)) as any
                            )
                        }
                    >
                        <label>Remove Account</label>
                    </Button>
                </ButtonContainer>
            </div>
        </InputWrapper>
    )
}

function listenForExternalToken(appId: string, callback: any, workspace: string, actionState: any) {
    try {
        const { actionPanelState, setActionPanelState } = actionState

        const currentNode = actionPanelState.currentNode as ActionsResponseT
        const selectedConnection = currentNode.selectedConnection as NewConnectionT

        var childWindow: any

        const receiveMessage = (event: any) => {
            if (event.origin === 'http://localhost:3000') return

            const nextState = produce(selectedConnection, draftState => {
                const connectionsDetails = draftState.connectionsDetails as OauthtokenT | CustomOAuthT
                connectionsDetails.value = {
                    ...connectionsDetails.value,
                    appId,
                    workspace,

                    meta_data: {
                        authStoreMap: event.data
                    }
                }
                draftState.connectionsDetails = connectionsDetails
            })
            setActionPanelState({
                ...actionPanelState,
                currentNode: {
                    ...currentNode,
                    selectedConnection: nextState
                }
            })

            if (!selectedConnection.connectionEditing) {
                callback({
                    ...actionPanelState,
                    currentNode: {
                        ...currentNode,
                        selectedConnection: nextState
                    }
                })
            } else {
                callback(
                    {
                        ...actionPanelState,
                        currentNode: {
                            ...currentNode,
                            selectedConnection: nextState
                        }
                    },
                    selectedConnection.id
                )
            }

            window.removeEventListener('message', receiveMessage, false)
            childWindow.close()
        }

        const openChildWindow = (data: AxiosResponse<any>) => {
            childWindow = window.open(data.data.output.authUrl, 'authwind', 'width=550,height=500,0,status=0,')
        }

        if (currentNode.selectedApp.type == AuthType.CustomOauth || currentNode.selectedApp.type == AuthType.PrivateOauth) {
            const connection = currentNode.selectedConnection.connectionsDetails as CustomOAuthT

            if (selectedConnection.connectionEditing) {
                let req ={};
                if(currentNode.selectedApp.type === AuthType.PrivateOauth){
                    req = {
                        connection_id: currentNode.selectedConnection.id,
                        type: "private_oauth",
                        meta_data: ramda.mergeAll(
                            connection.customInputs.inputs.map(function(o) {
                                return {
                                    [o.key]: o.value
                                }
                            })
                        )
                    }
                }else{
                     req = {
                        uuid: appId,
                        id: currentNode.selectedConnection.id,
                        meta_data: ramda.mergeAll(
                            connection.customInputs.inputs.map(function(o) {
                                return {
                                    [o.key]: o.value
                                }
                            })
                        )
                    }
                }

                putJson(automationAPIURLWithWorkspace(workspace, currentNode.selectedApp.type === AuthType.PrivateOauth? `/connection/oauthredirect/privateoauth`: `/connection/oauthredirect/custom`))()(req)
                    .then(openChildWindow)
                    .catch((error: any) => console.error(error))
            } else {
                let req ={};
                if(currentNode.selectedApp.type === AuthType.PrivateOauth){
                    req = {
                        name: currentNode.selectedConnection.connectionsDetails.value.name,
                        type: "private_oauth",
                        meta_data: {
                            ...ramda.mergeAll(
                            connection.customInputs.inputs.map(function(o) {
                                return {
                                    [o.key]: o.value
                                }
                                })
                            ),
                            appId: currentNode.selectedApp.id,
                            version: currentNode.selectedApp.version
                        }
                    }
                }else{
                    req = {
                        uuid: appId,
                        id: currentNode.selectedConnection.id,
                        meta_data: ramda.mergeAll(
                            connection.customInputs.inputs.map(function(o) {
                                return {
                                    [o.key]: o.value
                                }
                            })
                        )
                    }
                }

                postJson(automationAPIURLWithWorkspace(workspace, currentNode.selectedApp.type === AuthType.PrivateOauth? `/connection/oauthredirect/privateoauth` : `/connection/oauthredirect/custom`))(req)
                    .then(openChildWindow)
                    .catch((error: any) => console.error(error))
            }
        } else {
            if (selectedConnection.connectionEditing) {
                putJson(automationAPIURLWithWorkspace(workspace, `/connection/oauthredirect`))()({
                    uuid: appId,
                    id: currentNode.selectedConnection.id
                })
                    .then(openChildWindow)
                    .catch((error: any) => console.error(error))
            } else {
                postJson(automationAPIURLWithWorkspace(workspace, `/connection/oauthredirect`))({
                    uuid: appId
                })
                    .then(openChildWindow)
                    .catch((error: any) => console.error(error))
            }
        }

        window.addEventListener('message', receiveMessage, false)
    } catch (err) {}
}

ActionPanel.ErrorHandling = (props: {
    backupTreeForErrorHandling: { tree: undefined | TreeT<NodeT> }
    setBackupTreeForErrorHandling: (object: { tree: TreeT<NodeT> | undefined }) => void
}) => {
    const { actionPanelState, setActionPanelState } = useContext(ActionPanelStateContext)

    const { editTreeData, setEditTreeData, treeData, hasLinkedDialogs, automationMeta, active_automation } = useContext(SidePanelContext)

    const currentNode = actionPanelState.currentNode as ActionsResponseT

    const showErrorHandling = currentNode.errorHandling.errorHandling

    const currentParentNode = actionPanelState.parentNode

    const [errors, setErrorValue] = useState<{ name: string; enabled: boolean }[]>([])

    const [parallelPathLimitError, setParallelPathLimitError] = useState(currentParentNode.children.length > 4)

    const { backupTreeForErrorHandling, setBackupTreeForErrorHandling } = props

    useEffect(() => {
        if (actionPanelState.mode.mode == NodeMode.Add && !backupTreeForErrorHandling.tree) {
            setBackupTreeForErrorHandling({ tree: editTreeData.tree })
        }
    }, [actionPanelState.mode.mode])

    useEffect(() => {
        setErrorValue(currentNode.errorHandling.errors)
    }, [currentNode.errorHandling.errors])

    const node = currentNode
    const id = currentNode.errorHandling.errorHandlingParent.id

    const mode = actionPanelState.mode

    const newNode = {
        kind: 'ErrorHandlingCondition',
        id: '',
        name: '',
        description: '',
        meta_data: {
            authentication_id: ''
        },
        icon: ErrorHandlingSuccessIcon,
        input: [],
        output: {},
        path:
            currentParentNode.value.kind !== 'Setup'
                ? mode.mode == NodeMode.Edit
                    ? mode.nodeEditInput.path.map(path => {
                          if (path.nodeId === mode.nodeEditInput.id) {
                              return { ...path, actionId: node.selectedAction.id }
                          }

                          return path
                      })
                    : [
                          ...currentParentNode.value.path,
                          {
                              actionId: node.selectedAction.id,
                              nodeId: id
                          }
                      ]
                : [
                      {
                          actionId: node.selectedAction.id,
                          nodeId: id
                      }
                  ]
    } as NodeT

    function showErrorHandlingInTree() {
        if (parallelPathLimitError) {
            setParallelPathLimitError(false)
        }

        const mode = actionPanelState.mode

        if (errors.filter(err => err.enabled).length == 0 && !showErrorHandling) {
            const currentState = {
                ...actionPanelState,
                currentNode: {
                    ...currentNode,
                    errorHandling: {
                        ...currentNode.errorHandling,
                        errorHandling: true,
                        errors: errors.map(err => {
                            if (err.name == 'success' || err.name == 'failed') {
                                return { ...err, enabled: true }
                            }

                            return err
                        })
                    }
                }
            }

            setActionPanelState(currentState)

            const addMode = NodeMode.Add == actionPanelState.mode.mode

            const parentNode = actionPanelState.parentNode

            const nodeNotHasErrorHandling =
                (mode.mode == NodeMode.Edit &&
                    (parentNode.children.length == 0 ||
                        parentNode.children.filter(chil => currentNode.selectedApp.id == chil.value.meta_data.app_id)
                            .length == 0)) ||
                (errors.filter(err => err.enabled).length == 0 && addMode)

            const subflowMetaData = currentState.currentNode.subflowMetaData

            const newTree =
                !showErrorHandling && nodeNotHasErrorHandling
                    ? addErrorHandlingNodes(
                          newNode,
                          addMode ? currentNode.errorHandling.errorHandlingParent : currentParentNode.value,
                          addMode ? editTreeData.tree : treeData.tree,
                          currentNode,
                          {
                              multipleNodes: true,
                              name: '',
                              mode: actionPanelState.mode.mode,
                              actionState: currentState,
                              addingChildToActionState: setActionPanelState
                          },
                          {
                              addingInsideForEach: subflowMetaData.subflow.hasSubFlow,
                              forEachNodeId: subflowMetaData.subflow.hasSubFlow
                                  ? subflowMetaData.subflow.forEachNodeId
                                  : '',
                              parentNode: parentNode.value
                          }
                      )
                    : NodeMode.Add == actionPanelState.mode.mode
                    ? backupTreeForErrorHandling.tree
                    : treeData.tree

            setEditTreeData({ ...treeData, tree: newTree })
        } else if (!showErrorHandling) {
            // will satisfy when node has two error handling nodes and enabling error handling
            setActionPanelState({
                ...actionPanelState,
                currentNode: {
                    ...currentNode,
                    errorHandling: {
                        ...currentNode.errorHandling,
                        errorHandling: !showErrorHandling
                    }
                }
            })
        } else if (showErrorHandling) {
            const newTree = NodeMode.Add == actionPanelState.mode.mode ? backupTreeForErrorHandling.tree : treeData.tree

            setActionPanelState({
                ...actionPanelState,
                currentNode: {
                    ...currentNode,
                    errorHandling: {
                        ...currentNode.errorHandling,
                        errorHandling: false,
                        errors: errors.map(err => {
                            if (err.name == 'success' || err.name == 'failed') {
                                return { ...err, enabled: false }
                            }

                            return err
                        })
                    }
                }
            })

            setEditTreeData({ ...treeData, tree: newTree })
        }
    }

    function addSingleErrorNode(node: string) {
        if (parallelPathLimitError) {
            setParallelPathLimitError(false)
        }

        const parentNode = actionPanelState.parentNode

        const addMode = NodeMode.Add == actionPanelState.mode.mode
        const subflowMetaData = currentNode.subflowMetaData

        const newTree = addErrorHandlingNodes(
            newNode,
            addMode ? currentNode.errorHandling.errorHandlingParent : currentParentNode.value,
            editTreeData.tree,
            currentNode,
            {
                multipleNodes: false,
                name: node,
                mode: actionPanelState.mode.mode,
                actionState: actionPanelState,
                addingChildToActionState: setActionPanelState
            },
            {
                addingInsideForEach: subflowMetaData.subflow.hasSubFlow,
                forEachNodeId: subflowMetaData.subflow.hasSubFlow ? subflowMetaData.subflow.forEachNodeId : '',
                parentNode: parentNode.value
            }
        )

        setEditTreeData({ ...treeData, tree: newTree })
    }

    const isAssitant = getProductId() == 'ASSISTANT'

    return (
        <div className="error_handle_autoamtion_bg">
            <div className="error_handle_autoamtion">
                <Header>
                    Error handling
                    <span className="show_hide _auto_switch">
                        <div className={`${(isAssitant ? automationMeta.type =='no_trigger' ?hasLinkedDialogs : active_automation: active_automation) ? 'switch_auto disabled_with_blue' : 'switch_auto'}`}>
                            <input
                                type="checkbox"
                                className={`switch_1`}
                                checked={showErrorHandling}
                                onChange={() => {
                                    if ((isAssitant ? automationMeta.type =='no_trigger' ?hasLinkedDialogs : active_automation: active_automation)) return

                                    const hasTwoOrMoreChilds = actionPanelState.parentNode.children.filter(
                                        child =>
                                            child.value.kind == 'ErrorHandlingCondition' &&
                                            child.value.meta_data.parent_id == actionPanelState.parentNode.value.id
                                    )

                                    if (!showErrorHandling) {
                                        if (hasTwoOrMoreChilds.length > 1 || hasTwoOrMoreChilds.length == 0) {
                                            showErrorHandlingInTree()
                                        } else {
                                            const newErrors = errors.map(err => {
                                                if (
                                                    err.name.toLowerCase() !=
                                                    hasTwoOrMoreChilds[0].value.name.toLowerCase()
                                                ) {
                                                    return { ...err, enabled: false }
                                                }

                                                return { ...err, enabled: true }
                                            })

                                            const currentState = {
                                                ...actionPanelState,
                                                currentNode: {
                                                    ...currentNode,
                                                    errorHandling: {
                                                        ...currentNode.errorHandling,
                                                        errorHandling: true,
                                                        errors: newErrors
                                                    }
                                                }
                                            }

                                            setActionPanelState(currentState)
                                        }
                                    } else {
                                        showErrorHandlingInTree()
                                    }
                                }}
                            />
                        </div>
                    </span>
                </Header>
                <Paragraph>
                    Enabling error handling will let Workativ continue to the next step of the workflow if the action
                    failed.
                </Paragraph>
            </div>
            {showErrorHandling && (
                <div className="error_handle_checkbox">
                    {errors.map((err, i) => (
                        <div className="error-group" key={i}>
                            <input
                                type="checkbox"
                                id={err.name}
                                checked={err.enabled}
                                className={
                                    err.enabled || (parallelPathLimitError && err.name.toLowerCase() != 'success')
                                        ? 'editor_btn_disabled'
                                        : ''
                                }
                                disabled={
                                    err.enabled || (parallelPathLimitError && err.name.toLowerCase() != 'success')
                                }
                                onChange={e => {}}
                                onClick={() => {
                                    if (!parallelPathLimitError || err.name.toLowerCase() == 'success') {
                                        const nextState = produce(actionPanelState, draft => {
                                            draft.currentNode.errorHandling.errors = currentNode.errorHandling.errors.map(
                                                er => {
                                                    if (er.name == err.name) {
                                                        return { ...er, enabled: !err.enabled }
                                                    }
                                                    return er
                                                }
                                            )
                                        })
                                        setActionPanelState(nextState)

                                        addSingleErrorNode(err.name)
                                    }
                                }}
                            />
                            <label
                                htmlFor={err.name}
                                className={
                                    err.enabled || (parallelPathLimitError && err.name.toLowerCase() != 'success')
                                        ? 'editor_btn_disabled'
                                        : ''
                                }
                            >
                                {err.name}
                            </label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

ActionPanel.ActionInfo = (props: any) => {
    const { actionPanelState, setActionPanelState } = useContext(ActionPanelStateContext)
    const { hasLinkedDialogs,automationMeta,active_automation } = useContext(SidePanelContext)

    const currentNode = actionPanelState.currentNode as ActionsResponseT
    const isAssitant = getProductId() === 'ASSISTANT'
    return (
        <div className="action_information">
            <div className="automation_global_form">
                <div className="select_work_form">
                    <InputWrapper style={{ background: '#F0FFF9' }}>
                        <Header>Action summary</Header>
                        <InputContainer>
                            <Input
                                type="text"
                                className={`${(isAssitant ? automationMeta.type =='no_trigger' ?hasLinkedDialogs : active_automation: active_automation) ? 'disabled_with_blue' : ''}`}
                                value={currentNode.selectedAction.description}
                                onChange={(e: any) => {
                                    const nextState = produce(currentNode, draftState => {
                                        draftState.selectedAction.description = e.target.value
                                    })

                                    setActionPanelState({
                                        ...actionPanelState,
                                        currentNode: nextState
                                    })
                                }}
                            />

                            <InputContent>
                                Description{' '}
                                <Tooltip
                                    className="target customTip _description"
                                    zIndex={10000}
                                    tagName="span"
                                    content="This description will be displayed on your action info icon in the canvas page."
                                    distance={15}
                                    forceDirection={true}
                                    direction="up-start"
                                >
                                    <InfoIcon />
                                </Tooltip>
                            </InputContent>

                            {/* <p></p> */}
                        </InputContainer>
                    </InputWrapper>
                </div>
            </div>
        </div>
    )
}
